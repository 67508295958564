import decode from 'jwt-decode';
var config = require('../config/api');

export default class AuthService {
    // Initializing important variables
    constructor() {
        this.apiCall = this.apiCall.bind(this) // React binding stuff
        //this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
      // Saves user token to localStorage
		if (window.localStorage) {
			window.localStorage.setItem('id_token', idToken)
		}

    }

    setUser(user) {
		if (window.localStorage) {
			window.localStorage.setItem('displayName', user.displayName)
			window.localStorage.setItem('googleId', user.id)
			window.localStorage.setItem('email', user.email)
			window.localStorage.setItem('picture', user.picture)
		}
    }

    getToken() {
      // Retrieves the user token from window.localStorage
		if (window.localStorage) {
			return window.localStorage.getItem('id_token')
		}
		return null

    }

    getUser() {
      // Retrieves the user token from localStorage
      var user = {}

		if (window.localStorage) {
			user.displayName =  window.localStorage.getItem('displayName')
			user.email =  window.localStorage.getItem('email')
			user.googleId =  window.localStorage.getItem('googleId')
			user.picture =  window.localStorage.getItem('picture')
		}
      return user
    }

    logout() {
      // Clear user token and profile data from window.localStorage
		if (window.localStorage) {
			window.localStorage.removeItem('id_token');
			window.localStorage.removeItem('user');
			window.localStorage.removeItem('catembe_clientId');
			window.localStorage.removeItem('displayName')
			window.localStorage.removeItem('googleId')
			window.localStorage.removeItem('email')
			window.localStorage.removeItem('picture')
		}
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    apiCall(payload, endpoint, callback, errorCallback) {
        // performs api calls sending the required authentication headers
		  var payloadJson
		  try {
			   payloadJson = JSON.stringify(payload)
		  } catch(e) {
				console.log(e)
				console.log(payloadJson)
				return
		  }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(config.apiBaseUrl + endpoint, {
            method: 'post',
            headers,
            body: payloadJson,
        })
         .then( this._checkStatus )
         .then( response => response ? response.json() : null )
         .then( (data) => {
            if(data) {
               return callback(data)
            }
         })
			.catch( error => {
				if(errorCallback) { errorCallback(error) }
				else {
					console.log("Unhandled API error")
					console.log(error)
				}
			})
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            /*var error = new Error(response.statusText)
            error.response = response
            */
            console.log('Unauthorised')
            throw {}
        }
    }
}
