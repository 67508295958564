import React, { Component } from 'react'
import { ControlButton } from './control'
import { motion } from 'framer-motion'
import Countdown from 'react-countdown-now'
import '../css/Challenge.css'
import { Icon } from 'react-icons-kit'
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

function ChallengeAsyncPrompt(props) {
   /*constructor(props) { super(props) }*/
	const startAsyncChallenge = () => {
		props.sendAction('startAsyncChallenge')
	}
	return(
		<div className="asyncPrompt">
			<div className="_prompt">Ready to start the challenge?</div>
			{ props.description ? <div className="_desc">{props.description}</div> : <span/> }
			<motion.button type="button" 
				whileTap={{ scale: 0.9 }}
				transition={{ duration: 0.1 }}
				className="actionButton buttonGradient_blue" 
				onClick={startAsyncChallenge}>Start
			</motion.button>
		</div>
	)
}

class ChallengeHeader extends Component {
   /*constructor(props) { super(props) }*/
	render() {
		var cls = "challengeHeader"
		if (this.props.result) {
			cls += " completed"
		}

		const done = this.props.result

		const cs = new Date(this.props.challengeStarted)
		const timeLimit = this.props.timeLimit_secs || 120
		const dt = new Date(cs.setSeconds( cs.getSeconds() + timeLimit )) // When expiry happens
		const now = new Date()
		const renderer = function(p) {
			return p.minutes + ":" + String(p.seconds).padStart(2, '0')
		}
		const onTimeout = () => {
			if (this.props.onTimeout) {
				this.props.onTimeout()
			}
		}


		var points = ""
		if (parseInt(this.props.points) > 0) {
			points = <span className="_points">+{String(parseInt(this.props.points))} points.</span>
		}

		var ofTotal = ""
		if (this.props.numChallenges > 0) {
			ofTotal = <span className="_ofTotal"> (of {this.props.numChallenges})</span>
		}

		return(<div className={cls}>
					<b>Challenge {this.props.currentQn + 1}{ofTotal}: </b> {this.props.description}
					{ done ? 
							<motion.div className="_icon"
								animate={{scale: [1, 1.2, 1]}}
								transition={{ duration: 0.3, times: [0, 0.1, 1] }}>
								<Icon icon={ic_check_circle} size={42}/>
							</motion.div> : <span/> }
					{ (dt > now && !done && this.props.showTimeLeft !== false) ? 
						<div className="timeLeft"><b>Time remaining: </b> <Countdown renderer={renderer} date={dt} onComplete={onTimeout} /></div> : <span/> 
					}
					{ (done) ? <div className="timeLeft"><b>Correct! </b> {points} {this.props.correctDescription ? ReactHtmlParser(this.props.correctDescription) : <span/>}</div> : <span/> }
					{ (done === false && this.props.liveMode === true) ? <div className="timeLeft"><b>Incorrect :( </b> {this.props.incorrectDescription ? ReactHtmlParser(this.props.incorrectDescription) : <span/>}</div> : <span/> }
					{ (done && this.props.liveMode === false && this.props.lastQn) ? <div className="timeLeft">Congratulations, you finished the challenge!</div> : <span/> }
					{ (done && this.props.liveMode === false) ? 
							<div className="_next mt-3 text-right">
								<button className="actionButton buttonGradient_blue" onClick={() => this.props.sendAction('asyncNextQn')}>
									{ this.props.lastQn ? "Finish" : "Continue" }
								</button></div> : <span/> }

		</div>)
	}
}
class Challenge_DemoControlPanel extends Component {
	render() {
		return (
			[
				<div className="col" id="">
					<div className="bigNumber">{this.props.challengeActive ? "Yes" : "No"}</div>
					<div className="bigNumberSubtitle">Challenge active</div>
				</div>,
				<div className="col" id="">
					<div className="bigNumber">{parseInt(this.props.currentQn) + 1}</div>
					<div className="bigNumberSubtitle">Current Question</div>
				</div>,
				/*<div className="col" id="">
					{ this.props.challengeActive ? 
						<ControlButton onClick={() => { this.props.sendAction('disableChallenges') } }> End challenge </ControlButton> : 
						<ControlButton onClick={() => { this.props.sendAction('enableChallenges') } }> Start challenge </ControlButton> } 
				</div>,*/
			]
		)
	}
}
class Challenge_ControlPanel extends Component {
   /*constructor(props) { super(props) }*/
	render() {
		return (
			<div className="container">
            <div className="row">
					<div className="col">Challenges active: {this.props.challengeActive ? "Yes" : "No"}</div>
				</div>
            <div className="row">
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('enableChallenges') }}>Enable challenges</ControlButton>
               </div>
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('disableChallenges') }}>Disable challenges</ControlButton>
               </div>
            </div>
            <div className="row">
					<div className="col">Current Question: {parseInt(this.props.currentQn) + 1}</div>
            </div>
            <div className="row">
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('prevQn') }}>Previous question</ControlButton>
               </div>
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('nextQn') }}>Next question</ControlButton>
               </div>
            </div>
			</div>)
	}
}

export { ChallengeHeader, Challenge_ControlPanel, Challenge_DemoControlPanel, ChallengeAsyncPrompt }
