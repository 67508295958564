import React from 'react'
import {BasicControlView} from '../components/control.js'
import {Title, Description, StudentHeader, StudentAccessInstructions, StudentAccessHeader, ButtonFooter, } from '../components/basic.js'
import {IntroPage} from '../components/basic.js'

class FrontendAppSettings { // Common to all apps
	constructor(gameDetails) {
		this.gameDetails = gameDetails
		this.content = {}
		this.config = { // Common to all games of this type(?)
			updateIntervalInSecs: 2,
			studentHeader: true,
			studentContentContainer: true,
			studentWidescreenView: false,
			hideDisplayArrows: false, // Determines whether arrows should be shown on display view. Disable if it blocks content or is irrelevant. Required for DEMO view!
		}
		this.initialState = {
			localStage: 0,
			active: false,
			mainStartButtonEnabled: false,
		}
		this.appConfigs = { // Contents of matching appConfig gets thrown into app.state.content
		}
		this.headers = {
			STUDENT: function() {
				var points = 0
				if ( this.state && this.state.user && this.state.user.points !== undefined ) { points = this.state.user.points }

				var isPublicUser = false
				if ( !this.state.user || (this.state.user && this.state.user.publicUser === true) ) { // This only populated via auth, so we can use it to know whether this is a logged in user or a public 'user'
					isPublicUser = true
				}
				return (<StudentHeader 
					lastApiCall={this.state.lastApiCall} 
					lastApiError={this.state.lastApiError}
					apiErrorDetails={this.state.apiErrorDetails}
					title={this.gameDetails.title || this.content.title} 
					points={points} 
					headerActions={this.headerActions}
					isPublicUser={isPublicUser}
				></StudentHeader>)
			},    
			CONTROL: function() {
				return (<div>{this.gameDetails.title || this.content.title} - Control</div>)
			}      
		}
		this.lifecycle = {
			STUDENT: {
			},
			DISPLAY: {
			},
		}
		this.stages = {
			CONTROL: function() { },
			STUDENT: function() { },
			DISPLAY: function() { },
		}
		this.defaultStages = { // XXX Phase this out
			STUDENT: { // render() function for each stage
				'0': function(onStart) { 
					return ( <div>
						<IntroPage>
							<Title>{this.gameDetails.title || this.content.title}</Title>
							<Description>{this.gameDetails.description || this.content.description}</Description>
						</IntroPage>
						<ButtonFooter
							disabled={this.state.mainStartButtonEnabled ? false : true}
							onClick = {() => {
								this.nextStage() 
								this.sendInput({action: 'enrol'})
							}}
							buttonText = "Start"
						/>
					</div>)
				}
			},
			DISPLAY: {
				'0': function() { 
					return (<div className="relativeAnchor">
						<IntroPage>
							<Title>{this.gameDetails.title}</Title>
							<Description>{this.gameDetails.description}</Description>
							<StudentAccessInstructions app={this}/>
						</IntroPage>
					</div>)
				},
			},
		}
	}
	finalise() { // Run last, after everything has been specified
		// XXX Deprecated?

		/*var localStages = {}
		if (this.stages && this.stages.STUDENT) {
			for (var stage in this.stages) {
				if (this.stageNames && this.stageNames[parseInt(stage)]) {
					localStages[stage] = String(this.stageNames[parseInt(stage)])
				} else {
					localStages[stage] = String(stage)
				}
			}
		}
		this.gameDetails.localStages = localStages*/

	}
	getDefaultStage(route, stage) {
		if (this.defaultStages && this.defaultStages[route] && stage !== undefined && this.defaultStages[route][stage]) {
			return this.defaultStages[route][stage]
		}
		else if (this.defaultStages && this.defaultStages[route] && stage === undefined) {
			return this.defaultStages[route]
		}
		else {
			return (function() {})
		}
	}
	getAppRoute() {
		return this.gameDetails.appRoute
	}
	setInitialState(state) {
		this.mergeItem('initialState', state)
	}
	mergeItem(item, contents) { // Merge dictionaries
		if (this[item] === undefined) {
			this[item] = contents
			return
		}
		else {
			for (var i in contents) {
				this[item][i] = contents[i]
			}
		}
	}
}

export {FrontendAppSettings}
