import React, {Component} from 'react'
import { Icon } from 'react-icons-kit'
import {starFullOutline} from 'react-icons-kit/typicons/starFullOutline'
import {ic_feedback} from 'react-icons-kit/md/ic_feedback'
import {user} from 'react-icons-kit/fa/user'
import {ic_error} from 'react-icons-kit/md/ic_error'

import Rate from 'rc-rate'
import '../../node_modules/rc-rate/assets/index.css'
import { motion } from "framer-motion"
import QRCode from "react-qr-code"

import AnimatedNumber from 'animated-number-react'
import Sparkline from 'react-sparkline-svg'

import AuthService from '../utils/auth'
import googleAuthFns from '../utils/google'

import SyncLoader from "react-spinners/SyncLoader";

//import ReactDOM from 'react-dom';
//import ReactSwipe from 'react-swipe';

class BigNumber extends Component {
   //constructor(props) { }
   render() {
      return (
			<div>
				<div className="bigNumber">
					{ this.props.sparkLine ?
						<div className="sparkline">
							<Sparkline values={this.props.sparkLine}
								strokeWidth={1}
							/>
						</div> : <span/>}
					{ this.props.animate ?
						<AnimatedNumber
							key={this.props.name + "anim"}
							value={this.props.value}
							duration={100}
							formatValue={(x) => formatNumber(x, this.props.type)}
						/> : <span>{formatNumber(this.props.value, this.props.type)}</span>
					}
				</div>
				<div className="bigNumberSubtitle">
				{ this.props.changeIndicator ?
					<div className="changeCaretContainer">
						{ this.props.changeIndicator !== undefined && this.props.changeIndicator !== 0 ?
								( this.props.changeIndicator > 0 ? <div className="changeCaret goodChange up"/> : <div className="changeCaret badChange down"/>)
							: <span/> }
					</div> : <div className="changeCaretContainer"/>
				}
				{this.props.label}</div>
			</div>
		)
	}
}
class IntroPage extends Component {
   //constructor(props) { }
   render() {
      return (
      <div>
         <div className="container content pt-2">
            {this.props.children}
            {this.props.onNameChange ?
               (<div className="introForm">
						<div className="nameInputPrompt">Please enter your name</div>
						<div>
							<input className="textInput" value={this.props.nameClient} onChange={this.props.onNameChange}/>
						</div>
               </div>) : <span/>}
         </div>
      </div>)
   }
}

function formatNumber(value, type) {
	if (type === undefined) {
		return "?" + String(value) + "?"
	}
	var out = ""

	var neg = ""
	if (value < 0) { neg = "-" }
	if (type.substr(0, 6) === 'dollar') {
		var str
		var dec
		var val = Math.abs(value)
		var suffix = ""

		if (type === 'dollar_full') {
			//dec = val.toFixed(2).slice(-3)
			str = Math.round(val).toLocaleString()
		}
		else if (type === 'dollar_m') {
			//dec = val.toFixed(2).slice(-3)
			str = Math.round(val).toLocaleString() + 'm'
		}
		else if (val < 200 && type !== 'dollar_nodec') {
			var components = val.toFixed(2).split('.')
			str = components[0]
			dec = '.' + components[1]
		} else if (val < 10000) {
			str = val.toFixed(0)
		} else if (val < 100000) {
			suffix = "k"
			str = (val / 1000).toFixed(2)
		} else if (val < 1000000) {
			suffix = "k"
			str = (val / 1000).toFixed(0)
		} else if (val < 1000000000) {
			suffix = "m"
			str = (val / 1000000).toFixed(2)
		} else if (val < 1000000000000) {
			suffix = "b"
			str = (val / 1000000000).toFixed(0)
		} else if (val < 1000000000000000) {
			suffix = "t"
			str = (val / 1000000000000).toFixed(0)
		} else {
			suffix = ""
			str = Number(val).toExponential(3)
		}

		if (str === '0') { neg = '' } // Supress negative zero

		const valStr = <span>{str}<span className='_decimal'>{dec}</span><span className="_suffix">{suffix}</span></span>
		out = <span><span className="_sign">{neg}</span><span className="_dollarSign">$</span>{valStr}</span>
	} else if (type === 'count') {
		out = value.toFixed(0)
	} else if (type === 'percentage') {
		const valStr = (value * 100).toFixed(0)
		out = <span><span className="_sign">{neg}</span>{valStr}<span className="_dollarSign">%</span></span>
	}
	return out
}

class Title extends Component {
   //constructor(props) { }
   render() {
      return (<h1 className="display-4 mt-3 mb-3">{this.props.children}</h1>)
   }
}
class Description extends Component {
   //constructor(props) { }
   render() {
      return (<div className="lead mb-4">{this.props.children}</div>)
   }
}
class LoadingScreen extends Component {
	constructor(props) {
		super(props)
		this.state = {error: false}
	}
	componentDidMount() {
		this.timerObj = setTimeout( () => this.setState({error: true}), 5 * 1000 )
	}
	componentWillUnmount() {
		this.timerObj = null
	}

	render() {
		var message = "Loading..."
		if (this.props.message !== undefined) {
			message = this.props.message
		}
		return (<div className="container pageRedirectMsg">
			{ this.state.error ?
				(<div className="alert alert-danger">Error loading this content. Please check the URL or try again later.</div>) :
				(<div>
					<SyncLoader size={20} color={"var(--color-red)"} />
					<div className="mt-3">{message}</div>
				</div>)
			}
		</div>)
	}
}
class PageNotFound extends Component {
	render() {
		return (
			<div className="container pageRedirectMsg">
				<div>We couldn't find what you're looking for.</div>
				<div className="_small">Please check the URL and try again.</div>
			</div>
		)
	}

}
class PublicUserForm extends Component {
   constructor(props) {
		super(props)
		this.state = {nickname: ''}
		this.updateName = this.updateName.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	updateName(e) {
		this.setState({nickname: e.target.value})
	}
	handleSubmit(e) {
		e.preventDefault()
		this.props.onSubmit(this.state.nickname)
	}
   render () {
		//<h3 className="logo">catēmbe</h3>
		return (
			<div className="container">
				<div className="row publicUserForm introForm">
					<div className="col">
						<div className="nameInputPrompt">
							<label htmlFor="publicUserName">Please enter your initials</label>
							<div className="_finePrint">Maximum 3 characters</div>
						</div>
						<div className="_input">
							<input className="textInput initialInput" maxLength={3} onChange={this.updateName} value={this.state.nickname} type="text" id="publicUserName"/>
						</div>
					</div>
				</div>
				<ButtonFooter
					onClick = {this.handleSubmit}
					buttonText = "Continue"
					/>
			</div>
		)
	}

}

class FeedbackBox extends Component {
   constructor(props) {
		super(props)
		this.state = { qns: {} }
	}
	render() {
		const onChange = (item, feedback) => {
			this.setState((prevState) => {
				var newQns = prevState.qns
				newQns[item] = feedback
				return {qns: newQns}
			})
			this.props.onChange(item, feedback)
		}
		const noun = this.props.noun || "game"
		const questions = ["This "+noun+" was engaging", "This "+noun+" contributed to my understanding of the topic"]
		var qnsJsx = []

		var completed = false
		const completedQns = Object.keys(this.state.qns)
		if (completedQns && completedQns.length === questions.length) {
			completed = true
		}

		for (var qIdx in questions) {
			qnsJsx.push(
				<div key={"q"+qIdx}>
					<div className="_qn">{questions[qIdx]}</div>
					<div className="_rate">
						<Rate
							onChange={onChange.bind(null, questions[qIdx])}
							character={<Icon size={32} icon={starFullOutline}/>}
							allowClear={false}
						/>
					</div>
				</div>)
		}
		return (
			<div className="feedbackMsg">
				<div className="_heading">
					<div><Icon icon={ic_feedback} size={32}/></div><span>How was your experience today?</span>
				</div>
				{ !completed ? qnsJsx :
					<div className="_complete">
						Thank you for your feedback!
					</div>}
			</div>)
	}
}

const apiStatusDetails = (props) => {
	var apiStatusDot_State = ''
	const lastError = new Date() - new Date(props.lastApiError)
	const lastSeen = new Date() - new Date(props.lastApiCall)

	if (lastSeen < 5000) {
		apiStatusDot_State = 'ok'
	} else if (lastSeen < 15000) {
		apiStatusDot_State = 'warning'
	} else if (lastSeen >= 15000) {
		apiStatusDot_State = 'error'
	} else if (lastError < 5000) {
		apiStatusDot_State = 'error'
	}

	var apiErrorMsg = ""
	if (props.apiErrorDetails) {
		apiErrorMsg = props.apiErrorDetails.msg
		apiStatusDot_State = 'error'
	}

	return {
		state: apiStatusDot_State,
		jsx: [
			<div className={"apiStatusDot " + apiStatusDot_State} key="apiStatusDot1"/>,
			(props.apiErrorDetails ? <span className="apiError mr-2" data_msg={apiErrorMsg} key="apiStatusDotErr"><Icon size={12} style={{color: '#c0c0c0', verticalAlign: 'middle', marginTop: '-5px'}} icon={ic_error}/></span> : <span key="_"/>)
		]}
}

class LiteStudentHeader extends Component {
	render () {
		var apiStatus = apiStatusDetails(this.props)

		return (
			<div className="liteStudentHeader">
				{ apiStatus.state === 'error' ? apiStatus.jsx : <span/> }
			</div>
		)
	}
}

class StudentHeader extends Component {
   render () {
      return (
      <div className="row">
         <div className="col-sm-7 col-5 studentHeaderTitle">
			{ apiStatusDetails(this.props).jsx }
			{this.props.title}
		</div>
		{ !this.props.isPublicUser ?
			<div className="studentHeaderRhs col-sm-5 col-7 text-right" style={{lineHeight: '26px'}}>
				{ this.props.points !== undefined ?
					<div className="userPointsContainer"><span className="_lbl">Points</span> <span className="_pts">
						<AnimatedNumber
							key={this.props.name + "anim"}
							value={this.props.points}
							duration={150}
							formatValue={(x) => parseInt(x)}/>
					</span></div>
					: <span/> }
				{ this.props.headerActions.handleLogout ? <button className="btn logoutButton" onClick={this.props.headerActions.handleLogout}>Logout</button> : <span/> }
				<div className="userIcon">
					<a href="/user"><Icon icon={user} style={{color: 'var(--color-darkblue)'}}/></a>
				</div>
			</div> : <span/> }
      </div>)
   }
}
class ButtonFooter extends Component {
   render () {
      var subtitle = ""
      if (this.props.buttonSubtitle) {
         subtitle = <div className="buttonSubtitle">{this.props.buttonSubtitle}</div>
      }
      var buttonText = "Continue"
      if (this.props.buttonText) {
         buttonText = this.props.buttonText
      }
      var className = "continueButton"
      if (this.props.className) {
         className = this.props.className
      }
	  else {
		className = "buttonGradient_blue"
	  }
      return (
         <div className="footer">
            <div className="footerInner">
               <button
                  disabled={this.props.disabled}
                  type="button"
                  className={"btn actionButton " + className}
                  onClick={this.props.onClick}>{buttonText}</button>
               {subtitle}
            </div>
         </div>
      )
   }
}

class ContinueButton extends Component {
   render() {
      var subtitle = ""
      if (this.props.subtitle) {
         subtitle = <div className="buttonSubtitle">{this.props.subtitle}</div>
      }
      var buttonText = "Continue"
      if (this.props.buttonText) {
         buttonText = this.props.buttonText
      }
      var className = "continueButton"
      if (this.props.className) {
         className = this.props.className
      }
      return (
            <div>
               <button
                  disabled={this.props.disabled}
                  type="button"
                  className={"btn btn-primary " + className}
                  onClick={this.props.onClick}>{buttonText}</button>
               {subtitle}
            </div>
      )
   }

}

class ChoiceButton extends Component {
   render() {
      var disabled = false
      if (this.props.disabled) { disabled = true }

      var payoffs = []
      if (this.props.payoffs) {
         for (var pId in this.props.payoffs) {
            var payoff = this.props.payoffs[pId]
            payoffs.push(<div>{payoff.description}</div>)
         }
      }

      var buttonClass = "btn "
      if (this.props.active) { buttonClass += "btn-primary" }
      else { buttonClass += "btn-secondary" }
      return (
         <div className="col">
            <button
               disabled={disabled}
               onClick={this.props.onClick}
               className={buttonClass + " " + this.props.className}
               value={this.props.value}
               >{this.props.children}</button>
            {payoffs}
         </div>
      )

   }
}
class StudentAccessInstructions extends Component {
   render () {

		var pblc = false
		var pblcUrl = false

		var url = "catem.be"

		if (!this.props.app || !this.props.app.state || !this.props.app.gameDetails) {
			return <span/>
		}

		if (this.props.app.state.public === true || this.props.app.props.public === true) {
			pblc = true

			if (this.props.app.state.publicUrl) {
				pblcUrl = true
				url += "/" + this.props.app.state.publicUrl
			} else {
				url += "/CODE"
			}
		}

		if (pblc) {
			return (
			<div className="studentInstructions mt-5">
				<div className="row">
					<div className="col instructionsHeading text-center">
						 Access this activity on any smartphone, tablet, or laptop.
					</div>
				</div>
				<div className="row insRow">
					<div className="col-1">
						<div className="instructionNum">1</div>
					</div>
					<div className="col-5 instructionText">
						Open Google Chrome (or another browser) on your smartphone, tablet, or laptop
					</div>
					<div className="col-6" style={{'maxWidth': '300px'}}>
						<img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Browsers.png"/>
					</div>
				</div>
				<div className="row insRow">
					<div className="col-1">
						<div className="instructionNum">2</div>
					</div>
					{ pblcUrl ?
						<div className="col-5 instructionText">
							Type in the URL <b>{url}</b> and hit <b>go</b>.
						</div> :
						<div className="col-5 instructionText">
							Type in the following URL, replacing CODE with the code issued by your lecturer.
						</div> }
					<div className="col-6">
						<div className="browserUrlInput">{url}</div>
						<div className="qrCodeContainer">
							<QRCode value={url} size={128}/>
						</div>
					</div>
				</div>
			</div>)
		}
		else {

			return (
			<div className="studentInstructions mt-5">
				<div className="row">
					<div className="col instructionsHeading text-center">
						Follow these steps to access the activity on any smartphone, tablet, or laptop.
					</div>
				</div>
				<div className="row insRow">
					<div className="col-1">
						<div className="instructionNum">1</div>
					</div>
					<div className="col-7 instructionText">
						Open a browser and type in the URL <b>{url}</b>
					</div>
					<div className="col-4">
						<div className="browserUrlInput">{url}</div>
					</div>
				</div>
				<div className="row insRow">
					<div className="col-1">
						<div className="instructionNum">2</div>
					</div>
					<div className="col-7 instructionText">
						Log in using your student email account via Google (username@student.unimelb.edu.au)
						<div className="smallIns">Note: login may not work if your browser is in incognito/private mode</div>
					</div>
					<div className="col-4">
						<img src="https://s3-ap-southeast-2.amazonaws.com/catembe.public/GoogleLoginButton.png"/>
					</div>
				</div>
				<div className="row insRow">
					<div className="col-1">
						<div className="instructionNum">3</div>
					</div>
					<div className="col-7 instructionText">
						Click on the <b>{this.props.app.gameDetails.title}</b> activity, listed under <b>Available Apps</b>
					</div>
					<div className="col-4">
						<div className="appDeployment"><div className="dApp">{this.props.app.gameDetails.title}</div><div className="dSubject">SUBJECT NAME</div></div>
					</div>
				</div>
			</div> )
		}
   }
}

class StudentAccessHeader extends Component {
   render () {
      return (
      <div className="studentAccessInstructionHeader">
         To participate, login at <div className="_link"><span className="_grey">http://</span>catem.be</div> and select <div class="_appName">{this.props.appName}</div>
      </div> )
   }
}

const auth = new AuthService()
class CatembeNavBar extends Component {
   render() {
      return (
				<nav className="navbar navbar-expand-sm navbar-light catembe-navbar justify-content-between">
					<a className="" href="/" style={{borderBottom: 'none'}}>
						<div className='logoContainer'>
							<img src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/CatembeLogoSmall.png" className="imgLogo" alt="Catembe"/>
						</div>
					</a>

					<div className="_rhs" id="navbarSupportedContent">
						{ this.props.children || this.props.login === false ? // We have a custom section on the RHS
							<div className=''>{this.props.children ? this.props.children : <span/>}</div> :
							(!auth.loggedIn() ?
                <span/> :
								<button className="btn btn-sm btn-outline-secondary" type="button" onClick={() => window.location = '/user'}>User home</button>)
						}
					</div>
				</nav>

		)
	}
}

class BreakevenNavBar extends Component {
   render() {
      return (
				<nav className="navbar navbar-expand-sm navbar-light catembe-navbar justify-content-between">
					<a className="" href="/breakeven" style={{borderBottom: 'none'}}>
						<div className='logoContainer'>
							<img src="https://d3tx1wevfc318r.cloudfront.net/Breakeven/Breakeven_Logo_BlueGreen.png" className="imgLogo" alt="Catembe"/>
						</div>
					</a>

					<div className="_rhs" id="navbarSupportedContent">
					</div>
				</nav>

		)
	}
}

export const DonutChart = ({x, zeroData}) => {
	if (zeroData === undefined) {
		zeroData = false
	}
	return (
		<div className="donutChart">
			{ zeroData ? <span/> :
				<div className="_number">
					<BigNumber type = "percentage" value = {x} />
				</div> }
			<svg className="" viewBox="0 0 60 60">
				<path
				 fill="none"
				 strokeWidth="5"
				 stroke="#eaeaea"
				 strokeDasharray="0 1"
				 d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
				 style={{
					strokeDasharray: "125.6813735961914px 125.6813735961914px",
					transform: "translateX(5px) translateY(5px) scaleX(-1) rotate(90deg)"
				 }}
				/>
				{ zeroData ? <path/> :
					<motion.path
					 fill="none"
					 strokeWidth="5"
					 stroke="var(--color-red)"
					 animate={{ pathLength: x }}
					 strokeDasharray="0 1"
					 initial={{pathLength: 0}}
					 d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
					 style={{
						rotate: 90,
						translateX: 5,
						translateY: 5,
						scaleX: -1 // Reverse direction of line animation
					}}
					/> }
			</svg>
		</div>
	)
}

const WaitingForStart = (props) => {
	return (
		<div className="container">
			<div className="row">
				<div className="col text-center" style={{margin: '0 10%', marginTop: '35vh', fontSize: '25px', color: '#999'}}>
					Waiting for your lecturer...
				</div>
			</div>
		</div>
	)
}


class GameFinished extends Component {
	render() {
		const r = this.props.results
		if (r === undefined) {
			return <span/>
		}

		var resultsJsx = []
		for (var rIdx in r) {
			var resultItem = r[rIdx]

			resultsJsx.push(<div className="row mb-3">
					<div className="col _label text-left">{resultItem.label}</div>
					<div className="col _value text-right">{resultItem.value}</div>
				</div>)

		}

		return (
			<div className="gameFinished">
				<div className="container gameCompleteBox">
					<div className="row justify-content-center">
						<div className="col">
							<div className="smallCapsHeading text-center _gameOver mb-4">GAME OVER</div>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col">
							<div className="container">
								{resultsJsx}
							</div>
						</div>
					</div>
					{ this.props.playAgain ?
						<div className="row justify-content-center">
							<div className="col">
								<motion.button
									type="button"
									whileTap={{ scale: 0.9 }}
									transition={{ duration: 0.1 }}
									className="btn actionButton playAgainButton buttonGradient_redYellow"
									onClick={() => {this.props.onPlayAgain()}}>Play Again
								</motion.button>
							</div>
						</div> : <span/> }
					{ this.props.showFeedbackBox ? <FeedbackBox onChange={this.props.sendFeedback}/> : <span/> }
				</div>
			</div>

		)
	}
}


export { IntroPage, formatNumber, Title, Description, StudentHeader, ButtonFooter, ChoiceButton, StudentAccessInstructions, StudentAccessHeader, ContinueButton, PublicUserForm, LoadingScreen, PageNotFound, FeedbackBox, CatembeNavBar, BreakevenNavBar, BigNumber, WaitingForStart, GameFinished, LiteStudentHeader }
