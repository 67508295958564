import React, {Component} from 'react'

class BasicControlView extends Component {
	//constructor(props) { }
	render() {
		var appView = null
		if (this.props.appView) { appView = this.props.appView.toLowerCase() }
		return (
			<div className="container controlView">
				<MainControlPanel {...this.props}/>
				{ appView === 'control' ?  (
					<div>
						<ControlStatusTable clients={this.props.appState.clients} stages={this.props.stages}/>
						{this.props.children ? this.props.children : <span/>}
					</div>
					) : <span/> }
			</div>
		)
	}
}

class MainControlPanel extends Component {
   render() {
		/*<div className="col">
			<div className="bigNumber">{this.props.appState.stage !== undefined ? String(this.props.appState.stage + 1) : "-"}</div>
			<div className="bigNumberSubtitle">Current Slide</div>
			<a className="bigNumberAction" onClick={ () => this.props.sendAction('prevStage') }>{"Prev |"}</a>
			<a className="bigNumberAction" onClick={ () => this.props.sendAction('nextStage') }>{" Next"}</a>
		</div>*/

	   return ( 
		   <div className="row mt-4 text-center">
				<div className="col">
					<div className={"apiStatusDot " + ( this.props.appState && this.props.appState.active ? 'ok' : 'error' )}/>
					<div className="bigNumberSubtitle">Status</div>
					<a className="bigNumberAction" onClick={ () => {
							if (this.props.refreshSession) {
								this.props.refreshSession() // Assigns a new demoSessionId to refresh iFrames
							}
							this.props.sendAction('restartGame')
						}}>{ this.props.appState && this.props.appState.active ? "Reset" : "Start"}</a>
				</div>
				<div className="col">
					<div className="bigNumber">{this.props.appState && this.props.appState.numClients >= 0 ? this.props.appState.numClients : "1"}</div>
					<div className="bigNumberSubtitle">Active users</div>
				</div>
				{this.props.extraControlPanelItems ? this.props.extraControlPanelItems : <span/>}
			</div>)
   }
}

class ControlButton extends Component {
   render() {
		var className="btn btn-primary controlButton"
		if (this.props.dangerous) {
			className = "btn btn-danger controlButton"
		}
      return (
         <div className="controlButtonContainer">
            <button
               disabled={this.props.disabled} 
               onClick={this.props.onClick}
               type="button"
               className={className}
               >{this.props.children}</button>
         </div>
      )
   }
}
class ControlStatusTable extends Component {
   render() {
      var cells = []
      for (var stageNumber in this.props.clients) {
         var dots = []
         for (var cIdx in this.props.clients[stageNumber]) {
            const client = this.props.clients[stageNumber][cIdx]
            const lastProgressedAgo = Math.abs((((new Date() - new Date(client.lastStageChangeUTC)) / 1000) / 60))
            const lastSeenAgo = Math.abs((((new Date() - new Date(client.lastSeenUTC)) / 1000) / 60))

            const s = Math.max(0, 66 - (lastSeenAgo * 10))
            const l = Math.min(90, 50 + (lastSeenAgo * 5))
            const style = {backgroundColor: 'hsl(120, '+s+'%, '+l+'%)'}

            dots.push(
               <div className="controlStatusUser" key={"dot" + stageNumber + "_" + cIdx}>
						<div className="userDot" style={style}></div><div className="userDotMins">{lastProgressedAgo.toFixed(0)}m</div>
					</div>
            )
         }
         const userCount = this.props.clients[stageNumber].length

         var stageName = ""
         if (this.props.stages && this.props.stages[parseInt(stageNumber)]) {
            stageName = this.props.stages[parseInt(stageNumber)]
         }
         cells.push(<div className="col controlStatusCol" key={"col" + stageNumber}>
            <div className="stageHeading">#{stageNumber}: {stageName} <span className="stageUsersCount">{userCount > 0 ? userCount : ""}</span></div>
            {dots}
         </div>) 
      }
      return (
		<div className="row">
			<div className="col">
				<div className="smallCapsHeading">User Map</div>
				 <div className="controlStatusTable">
					<div className="row">
					   {cells}
					</div>
				 </div>
			</div>
		</div>
      )
   }
}
class DemoControlView extends Component {
   //constructor(props) { }
   render() {
		const restartGame = () => {
			if (this.props.refreshSession) {
				this.props.refreshSession() // Assigns a new demoSessionId to refresh iFrames
			}
			this.props.sendAction('restartGame')
		}
		return (
			<div className="container demoControlPanel">
				<MainControlPanel {...this.props} onRestart={restartGame} />
			</div>
		)
   }
}
export {BasicControlView, ControlButton, DemoControlView}
