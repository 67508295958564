import React from 'react'
import {Title, Description, StudentHeader, StudentAccessInstructions, StudentAccessHeader, WaitingForStart, GameFinished} from '../components/basic.js'
import {IntroPage} from '../components/basic.js'
import {BasicControlView, ControlButton, DemoControlView} from '../components/control.js'
import {SudokuPuzzle, SudokuDisplayView, SudokuTeamGameFinished} from '../components/sudoku.js'
import {ButtonFooter} from '../components/basic.js' 

import { FrontendAppSettings } from './FrontendAppSettings'

const gameDetails = {
	gameId: 'sudoku', // Unique identifier for this game
	appRoute: 'fss', // Determines url of app
	title: "Financial Statement Sudoku",
	description: "Puzzle-based game for learning about financial statements",

	discipline: "Accounting", // Shown on frontpage tile and product page
	veryShortTitle: "FS Sudoku", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Financial Statement Sudoku", // Used as interactive name on FrontPage
	shortDescription: "Puzzle-based game for learning about financial statements", // Description on FrontPage

	videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Sudoku_v2.mp4", // Video on frontPage and Product page
	//asyncDemoUrl: "/app/public/je/async_demo/je_demo/student",
	//liveMode: { },

	longDescription: [
		<p>A puzzle game for learning about GAAP financial statements and the relationships between line items and ratios. Puzzles are algorithmically generated, vary from easy to extremely hard, and can focus on a particular statement, relationship, or section.</p>,
		<p>Available in single-player or live/multi-player modes. In multiplayer mode, students and compete or collaborate to solve an entire set of statements in a scavenger-hunt-like activity.</p>
	],
	topicsCovered: ['Financial Statements'],
	liveMode: {
		longDescription: <p>In <span className="liveMode">live mode</span>, students collaborate or compete to solve puzzles, individually or in teams!</p>,
		variants: [
			{ 
				name: 'Race mode',
				videoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/Sudoku_LiveMode.mp4', 
				description: 'Students race to be the first to complete different parts of the puzzle. Clues can be pushed out to individual students to level the playing field and require them to collaborate to solve!'
			},
			{ 
				name: 'Teams mode', 
				videoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/Sudoku_LiveMode_Teams.mp4',
				description: 'Teams collaborate to be the first to complete the puzzle. Clues can be pushed out sequentally to teams who must figure out how each one relates to the financial statement puzzle. Works very well with teams discussing the challenge in breakout rooms!',
			},
		],
	},
}

var sudoku = new FrontendAppSettings(gameDetails)

sudoku.config.studentHeader = false
sudoku.config.studentWidescreenView = true
sudoku.config.updateIntervalInSecs = 2
sudoku.config.requirePublicNickname = false

sudoku.appConfigs = { // Contents of matching appConfig gets thrown into app.state.content
	haas_mba222_live: {
		config: { requirePublicNickname: true },
	},
}

sudoku.lifecycle = {
	STUDENT: {
		componentDidMount: function() { 
			this.onUpdate = (item, value) => {
				this.sendInput_debounced({action: 'submitInput', item: item, value: value})
			}
			this.onNext = () => {
				this.sendInput({action: 'nextQn'})
			}
			this.onBack = () => {
				this.sendInput({action: 'prevQn'})
			}
		},
		onAuthentication: function() {
		}
	}
}

sudoku.stages = {
	CONTROL: function() { // Control only has one 'stage' 
		var extraControlPanelItems = undefined
		if (this.state.liveMode) {
			extraControlPanelItems = [
				<div className="col">
					<ControlButton onClick={() => { if(window.confirm("This will end the game and show students a GAME OVER screen. Are you sure?")) { this.sendAction('endLiveGame') } 
						}} dangerous={true} disabled={!this.state.gameActive}> End live session </ControlButton>
				</div>]
			if (this.state.secretInfoIdx !== undefined) {
				extraControlPanelItems.push(
					<div className="col">
						<ControlButton onClick={() => { this.sendAction('deploySecretInfo_next') }} dangerous={false} disabled={!this.state.gameActive}>
							{ this.state.secretInfoIdx === null ? "Show first clue" : "Next clue" }
						</ControlButton>
						{ parseInt(this.state.secretInfoIdx) >= 0 ? <div className="bigNumberSubtitle">Clue {this.state.secretInfoIdx + 1} shown</div> : <span/> }
						{ parseInt(this.state.secretInfoIdx) >= 0 ? <a className="bigNumberAction" onClick={ () => {this.sendAction('deploySecretInfo_prev')}}>Back</a> : <span/> }
					</div>)
			}
		}

		return (
			<div>
				<BasicControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					extraControlPanelItems = {extraControlPanelItems}
					appView = {this.state.appView.toLowerCase()}
				/>
			</div>)
	},
	STUDENT: { // render() function for each stage
		'0': function() { 
			if (this.state.liveMode && !this.state.gameActive && !this.state.finished) {
				return (<WaitingForStart/>)
			}
			if (this.state.finished) {
				if (this.state.liveMode) {
					if (this.state.teamsMode) {
						return <SudokuTeamGameFinished {...this.state} sendFeedback={this.sendFeedback}/>
					}
					else {
						var results = []
						if (this.state.results && this.state.results.first > 0) { 
							results.push({label: 'Items answered first', value: this.state.results.first})
						}
						return ( <GameFinished results={results}
									showFeedbackBox={true}
									sendFeedback={this.sendFeedback} />)
					}
				} else {
					var q_completed = 0
					var avg_time = "0s"
					try {
						q_completed = Object.keys(this.state.times).length
						avg_time = (Object.values(this.state.times).reduce((a, b) => a + b) / q_completed).toFixed(2) + "s per question"
					} catch(e) {
						console.log("Error calculating results")
						console.log(e)
					}
					return ( <GameFinished
								results={[
									{label: 'Questions completed', value: q_completed},
									{label: 'Average completion time', value: avg_time},
								]}
								playAgain={true}
								onPlayAgain={() => this.sendAction('enrol') }/>)
				}
			}
			if (this.state.gameActive && this.state.teamsMode && !this.state.team) {
				const onInput = (e) => {
					this.setState({teamPassword: e.target.value})
					this.sendInput_debounced({action: 'validateTeam', teamPassword: e.target.value})
				}
				const onNewPassInput = (e) => {
					this.setState({newTeamPassword: e.target.value})
					this.sendInput_debounced({action: 'validatePassword', teamPassword: e.target.value})
				}

				if (this.state.registrationMode === true) {
					return (
						<div className="text-center">
							<div className="teamLogin">
								<div className="nameInputPrompt">Please choose a team password</div>
								<div className="_finePrint mt-0 mb-2">Note that only one person from each team should register here.</div>
								<div>
									<input className="textInput" value={this.state.newTeamPassword} onChange={onNewPassInput}/>
								</div>
								<div className="_finePrint mt-0 mb-4">Once you've registered, share this password with your team members.</div>
								<ButtonFooter
									disabled={this.state.validPassword === true ? false : true}
									onClick = {() => this.sendInput({action: 'registerNewTeam', teamPassword: this.state.newTeamPassword})}
									className = "buttonGradient_redYellow"
									buttonText = "Register new team"
									/>
								<div className="register">
									<a tabIndex="2" onClick={() => this.setState({'registrationMode': false})}>Sign in to existing team</a>
								</div>
							</div>
						</div>)
				}
				//<a tabIndex="2" onClick={() => this.setState({'registrationMode': true})}>Register new team</a>

				return(
					<div className="text-center">
						<div className="teamLogin">
							<div className="nameInputPrompt">Please enter your team password</div>
							<div>
								<input className="textInput" value={this.state.teamPassword} onChange={onInput}/>
							</div>
							<ButtonFooter
								disabled={this.state.validTeam === true ? false : true}
								onClick = {() => this.sendInput({action: 'registerOnTeam', teamPassword: this.state.teamPassword})}
								buttonText = "Continue"
								/>
							<div className="register">
							</div>
						</div>
					</div>
				)
			}

			return ( <SudokuPuzzle {...this.state} onUpdate={this.onUpdate} onNext={this.onNext} sendFeedback={this.sendFeedback} onBack={this.onBack}/>) 
		},
		'1': function() { // wtf
		},
	},
	DISPLAY: {
		'0': sudoku.getDefaultStage('DISPLAY', '0'),
		'1,': function() {
			return ( <SudokuDisplayView {...this.state} onUpdate={() => null} onGameStart={() => this.sendControlAction('gameStart_liveMode')} />) 
		},

	},
}

export { sudoku }
