import React, { Component } from 'react'
import '../css/Duality.css'
import {basicFunctions } from '../include/duality'

import { Icon } from 'react-icons-kit'
import {arrowLeftThin} from 'react-icons-kit/metrize/arrowLeftThin'
import {arrowRightThin} from 'react-icons-kit/metrize/arrowRightThin'
import { motion } from 'framer-motion'

import AnimatedNumber from 'animated-number-react'
import update from 'immutability-helper'

//import AnimatedNumber from 'react-animated-number'
import { Resizable } from 're-resizable'
var classNames = require('classnames')

const entryTypes = {dr: 'Debit', cr: 'Credit'}

// Global constants
const displayOrder = {lhs: ['a_neg', 'a'], rhs: ['le', 'le_neg']} // Need to arrange bars in this order (left to right)

const oppEntry = {dr: 'cr', cr: 'dr'}

class AccountBars extends Component {
   constructor(props) {
      super(props)
		this.state = {
			accounts: this.props.accounts,
			settings: this.props.settings,
			selected: [],
			transactions: [],

			currentEntry: null,
		}

		// Initialise accounts object
		var initialGrossTotalAssets = 0
		var initialTotalAssets = 0
		for (var accType in this.state.accounts) {
			for (var aIdx in this.state.accounts[accType]) {
				this.state.accounts[accType][aIdx].type = accType // Push account type down to account level, so we can pass around the account object and know how to update it centrally
				this.state.accounts[accType][aIdx].accountIdx = aIdx  // Same with account index
				if (accType == 'a') {
						initialTotalAssets += this.state.accounts.a[aIdx].value
					if (this.state.accounts.a[aIdx].value > 0) {
						initialGrossTotalAssets += this.state.accounts.a[aIdx].value
					}
				}
				if (this.state.accounts[accType][aIdx].subAccounts) {
					for (var saIdx in this.state.accounts[accType][aIdx].subAccounts) {
						this.state.accounts[accType][aIdx].subAccounts[saIdx].type = 'sub_' + accType
						this.state.accounts[accType][aIdx].subAccounts[saIdx].accountIdx = String(aIdx) + "_" + String(saIdx)
					}
				}
			}
		}

		// Default settings
		const defaultSettings = {
			initialHeight: 300, // Starting height in pixels
			barWidth: 120,
			minAccountBarHeight: 5,
			animate: true,
			enableViewTransactions: true,
			enableInteraction: false,
			enableDebitsCredits: true,
			showButtons: false,
			showNarration: true,
		}

		for (var ds in defaultSettings) {
			if (this.state.settings[ds] === undefined) {
				this.state.settings[ds] = defaultSettings[ds]
			}
		}

		// Save original account state (for resetting)
		this.state.accountsOriginal = Object.assign({}, this.state.accounts)

		var scaleDenom = initialGrossTotalAssets
		if (this.state.settings.overrideHeightInDollars) {
			scaleDenom = this.state.settings.overrideHeightInDollars
		}
		if (!(scaleDenom > 0)) { scaleDenom = 1 }

		this.state.settings.initialScale = this.state.settings.initialHeight / scaleDenom

		this.accountActions = {
			refreshAccounts: function() {
				// TODO Repeat what you do in app.constructor to push down acctIdx etc but use setState? Then call it once you create an account in addAccount() 
			},
			addAccount: function(name, type) {
				var newAcct = { name: name, value: 0 }
				if(['a','e','l'].indexOf(type) >= 0) { // Create a regular type
					newAcct.type = type
					this.setState((prevState) => {
						newAcct.accountIdx = prevState.accounts[type].length // Bit flaky, best to use refreshAccounts above
						const newAccounts = update(prevState.accounts, {[type]: {$push: [newAcct]}})
						return {accounts: newAccounts}
					},() => {
						this.actions.onClick(newAcct)
					})
				}
			},

			resetGame: function() {
				this.setState((prevState) => {
					return { accounts: prevState.accountsOriginal, transactions: [], selected: [], cursor: 0 }
				})
			},

			applyPreloadedTransaction: function() {
				if (this.props.preloadedTransaction) {
					this.actions.setUpTransaction(this.props.preloadedTransaction)
				}
			},

			clearEntry: function(dontAnimate) {
				this.setState((prevState) => {
					var newAccounts = prevState.accounts
					for (var accType in prevState.accounts) {
						for (var aIdx in prevState.accounts[accType]) {
							newAccounts = update(newAccounts, {[accType]: {[aIdx]: {selected: {$set: false}, $unset: ['proposedChange_zeroBumps','proposedChange_otherAcct', 'proposedChange_amount', 'proposedChange_startingAmount']}}})
							if (prevState.accounts[accType][aIdx].subAccounts) {
								for (var saIdx in prevState.accounts[accType][aIdx].subAccounts) {
									newAccounts = update(newAccounts, {[accType]: {[aIdx]: {subAccounts: {[saIdx]: {selected: {$set: false}, $unset: ['proposedChange_zeroBumps','proposedChange_otherAcct', 'proposedChange_amount', 'proposedChange_startingAmount']}}}}})
								}
							}
						}
					}

					var newSelected = update(prevState.selected, {$set: []})
					var newState = {accounts: newAccounts, selected: newSelected, cursor: prevState.cursor_lastApplied, currentEntry: null, narration: ""}
					if (dontAnimate) {
						newState.settings = update(prevState.settings, {animate: {$set: false}})
					}
					return newState
				})
			},
			deselectAll: function() {
				this.setState((prevState) => {
					var newAccounts = prevState.accounts
					for (var accType in prevState.accounts) {
						for (var aIdx in prevState.accounts[accType]) {
							if (newAccounts[accType][aIdx].selected) {
								newAccounts = update(newAccounts, {[accType]: {[aIdx]: {selected: {$set: false}}}})
							}
							if (prevState.accounts[accType][aIdx].subAccounts) {
								for (var saIdx in prevState.accounts[accType][aIdx].subAccounts) {
									if (newAccounts[accType][aIdx].subAccounts[saIdx].selected) {
										newAccounts = update(newAccounts, {[accType]: {[aIdx]: {subAccounts: {[saIdx]: {selected: {$set: false}}}}}})
									}
								}
							}
						}
					}
					var newSelected = update(prevState.selected, {$set: []})
					return {accounts: newAccounts, selected: newSelected}
				})
			},
			applyEntry: function() {
				this.setState((prevState) => {
					var txn = {entries: []}
					var newAccounts = prevState.accounts
					for (var accType in prevState.accounts) {
						for (var aIdx in prevState.accounts[accType]) {

							// Apply change to account
							var acc = prevState.accounts[accType][aIdx]
							if (acc.proposedChange_amount !== undefined && acc.proposedChange_amount !== 0) {

								// Add transaction to running history
								const entryType = basicFunctions.getEntryType(acc)
								if (!acc.subAccounts) {
									txn.entries.push({name: acc.name, type: acc.type, accountIdx: acc.accountIdx, prevValue: acc.value, 
										proposedChange_amount: acc.proposedChange_amount, newValue: acc.value + acc.proposedChange_amount, entryType: entryType})
								}

								//newAccounts = update(newAccounts, {[accType]: {[aIdx]: {value: {$set: acc.value + acc.proposedChange_amount}}}})
								newAccounts = this.actions.setSingleItemField(newAccounts, acc.type, acc.accountIdx, 'value', acc.value + acc.proposedChange_amount)
							}

							if (acc.proposedChange_nulled === true) {
								//newAccounts = update(newAccounts, {[accType]: {[aIdx]: {value: {$set: acc.value + acc.proposedChange_amount}}}})
								newAccounts = this.actions.setSingleItemField(newAccounts, acc.type, acc.accountIdx, 'nulled', true)
							}

							// Apply change to subAccounts if they exist
							if (prevState.accounts[accType][aIdx].subAccounts) { 
								for (var saIdx in prevState.accounts[accType][aIdx].subAccounts) {
									var subAcc = prevState.accounts[accType][aIdx]['subAccounts'][saIdx]
									if (subAcc.proposedChange_amount !== undefined && subAcc.proposedChange_amount !== 0) {

										// Add transaction to running history
										const entryType = basicFunctions.getEntryType(subAcc)
										txn.entries.push({name: subAcc.name, type: subAcc.type, accountIdx: subAcc.accountIdx, prevValue: subAcc.value, 
											proposedChange_amount: subAcc.proposedChange_amount, newValue: subAcc.value + subAcc.proposedChange_amount, entryType: entryType})

										newAccounts = this.actions.setSingleItemField(newAccounts, subAcc.type, subAcc.accountIdx, 'value', subAcc.value + subAcc.proposedChange_amount)
									}
								}
							}

							// Reset entry
							newAccounts = update(newAccounts, {[accType]: {[aIdx]: {selected: {$set: false}, $unset: ['proposedChange_zeroBumps','proposedChange_otherAcct', 'proposedChange_amount', 'proposedChange_startingAmount']}}})
							if (prevState.accounts[accType][aIdx].subAccounts) { 
								for (var saIdx in prevState.accounts[accType][aIdx].subAccounts) {
									newAccounts = update(newAccounts, {[accType]: {[aIdx]: {subAccounts: {[saIdx]: {selected: {$set: false}, $unset: ['proposedChange_zeroBumps','proposedChange_otherAcct', 'proposedChange_amount', 'proposedChange_startingAmount']}}}}})
								}
							}
						}
					}
					var newTransactions = prevState.transactions
					if (txn.entries.length > 0) {
						// TODO Sort
						if (prevState.narration) {
							txn.narration = prevState.narration
						}
						txn.entries = basicFunctions.processJournalEntries(txn.entries) // Adds opposite entry for each & sorts
						txn.appliedDateUTC = new Date()
						newTransactions = update(newTransactions, {$push: [txn]})

					}
					return {accounts: newAccounts, transactions: newTransactions, narration: "", selected: [], cursor_lastApplied: prevState.cursor}
				})
			},
			updateAccount(type, accountIdx, fields, values, options) {
				this.setState((prevState) => {
					var updateFn = this.actions.setSingleItemField
					if (options && options['updateType'] !== undefined) {
						if (options['updateType'] == 'unset') { updateFn = this.actions.unsetSingleItemField }
					}

					var newAccounts = prevState.accounts
					for (var idx in fields) {
						newAccounts = updateFn(newAccounts, type, accountIdx, fields[idx], values[idx])
					}
					return {accounts: newAccounts}
				})
			},
			setSingleItemField(accounts, type, accountIdx, field, value) {
				if (type.substr(0, 3) === 'sub') {
					const pType = type.substr(4)
					const aIdxSplit = accountIdx.split('_')
					const pIdx = parseInt(aIdxSplit[0])
					const aIdx = parseInt(aIdxSplit[1])
					return update(accounts, {[pType]: {[pIdx]: {subAccounts: {[aIdx]: {[field]: {$set: value}}}}}})
				}
				else {
					return update(accounts, {[type]: {[accountIdx]: {[field]: {$set: value}}}})
				}
			},
			unsetSingleItemField(accounts, type, accountIdx, field) {
				if (type.substr(0, 3) === 'sub') {
					const pType = type.substr(4)
					const aIdxSplit = accountIdx.split('_')
					const pIdx = parseInt(aIdxSplit[0])
					const aIdx = parseInt(aIdxSplit[1])
					return update(accounts, {[pType]: {[pIdx]: {subAccounts: {[aIdx]: {$unset: [field]}}}}})
				}
				else {
					return update(accounts, {[type]: {[accountIdx]: {$unset: [field]}}})
				}
			},
			closeAccount: async function(accObj) {
				//[{name: 'Cash', amount: 100}, {name: 'Borrowed things', amount: 100}],

				const accName = accObj.name

				const otherAccObj = this.actions.getOtherSelectedAccount(accObj)
				const otherAccName = otherAccObj.name

				const curChange = accObj.proposedChange_amount
				const origValue = accObj.value

				const remainingChange = -1 * (origValue + curChange) // The value that will null this account

				var nullTxn = []
				nullTxn.push({name: accName, amount: remainingChange})
				nullTxn.push({name: otherAccName})

				await this.actions.setUpTransaction(nullTxn)
				this.setState((prevState) => {
					var newAccounts = this.actions.setSingleItemField(prevState.accounts, accObj.type, accObj.accountIdx, 'proposedChange_nulled', true)
					return {accounts: newAccounts}
				})

			},
			
			/*
			setUpState: async function(s) { // Set up pre-loaded 'states' -- account values
				// Deselect all 
				while (Object.keys(this.state.selected).length > 0) {
					this.actions.deselectAll()
					await this.actions.sleep(10)
				}
				if (s === undefined) { return null }

				if (Array.isArray(s[0])) { // We have multiple transactions in one -- run this function on all of them.
					for (var sIdx in s) {
						await this.actions.setUpState(s[sIdx])
					}
					return
				}

				for (var sIdx in s) {
					const accDict = s[sIdx]

					var narration = null
					if (accDict.narration) {
						narration = accDict.narration
					}
					var accObj = this.actions.getAccountByName(accDict.name)
					if (accObj && accDict.value !== undefined) {
						this.setState((prevState) => {
							var newAccounts = this.actions.setSingleItemField(prevState.accounts, accObj.type, accObj.accountIdx, 'value', accDict.value)
							if (accDict.value !== 0 && accObj.nulled === true) { 
								newAccounts = this.actions.setSingleItemField(newAccounts, accObj.type, accObj.accountIdx, 'nulled', false)
							} 
							if (narration !== null) {
								return { accounts: newAccounts, narration: narration }
							} else {
								return { accounts: newAccounts }
							}

						})
					}
				}
			},*/

			setUpTransaction: async function(t, clearFirst) { // Set up pre-loaded transaction

				if (clearFirst === true) {
					//this.setState((prevState) => { // Turn off animation
					//	return { settings: update(prevState.settings, {animate: {$set: !disableAnimation}}) }
					//}, 
					this.actions.clearEntry(true)
					await this.actions.sleep(10)
				}

				// Deselect all 
				while (Object.keys(this.state.selected).length > 0) {
					this.actions.deselectAll()
					await this.actions.sleep(10)
				}

				//console.log("Setting up transaction ")
				//console.log(t)
				if (t === undefined) { return null }
				
				if (Array.isArray(t[0])) { // We have multiple transactions in one -- run this function on all of them.
					for (var tIdx in t) {
						await this.actions.setUpTransaction(t[tIdx])
					}
					return
				}

				if (this.state.currentEntry && JSON.stringify(t) === this.state.currentEntry) {
					// Don't reapply the same transaction again
					return
				}

				var narration = ""
				for (var aIdx in t) {
					const accDict = t[aIdx]
					if (accDict.narration) {
						narration = accDict.narration
					}

					// Make sure both accounts exist
					var accObj = this.actions.getAccountByName(accDict.name)
					//const otherAccObj = this.getOtherSelectedAccount(accObj)

					if (!accObj) { 
						console.log('Can\'t load account ' + accDict.name)
						return null 
					}

					// 'Click' or 'select' the account
					this.actions.onClick(accObj)

					// Null account if required

					// Wait for accounts to be selected (wait for setState call in onClick to return)
					while (accObj.selected !== true) {
						accObj = this.actions.getAccount(accObj.type, accObj.accountIdx)
						await this.actions.sleep(10)
					}
				}

				// 'Drag' to make change on first account
				const accDict = t[0]
				accObj = this.actions.getAccountByName(accDict.name)
				if (accObj) {
					console.log('Resizing ' + accObj.name)
					this.actions.onResizeStart(accObj, 'top', false)

					// Wait for resizing to be set up
					//
					while (accObj.proposedChange_amount === undefined) {
						accObj = this.actions.getAccount(accObj.type, accObj.accountIdx)
						await this.actions.sleep(10)
					}
					
					// Update account object (with refreshed proposedChange_otherAcct property, which was changed above)
					accObj = this.actions.getAccountByName(accDict.name)

					// Perform the change / resize
					this.actions.onResize(accObj, 'top', {height: accDict.amount * this.state.settings.initialScale})

					while (accObj.proposedChange_amount === undefined) {
						accObj = this.actions.getAccount(accObj.type, accObj.accountIdx)
						await this.actions.sleep(10)
					}

					this.actions.onResizeStop(accObj, 'top', {height: accDict.amount * this.state.settings.initialScale})
					this.setState({narration: narration, currentEntry: JSON.stringify(t)})
				}
			},
			onClick: function(account) {
				this.setState((prevState) => {
					//const accountObj = prevState.accounts[account.type][account.accountIdx]
					console.log("Clicked")
					console.log(account) 
					/*var newAccounts = prevState.accounts
					for (var accType in {'a': 1, 'l': 1, 'e': 1}) {
						if (accType === account.type || (['l','e'].indexOf(accType) >= 0 && ['l','e'].indexOf(account.type) >= 0)) {
							for (var accIdx in prevState.accounts[accType]) {
								var accObj = prevState.accounts[accType][accIdx]
								if (accObj.selected === true) { // Deselct any other selected item
									newAccounts = update(newAccounts, {[accType]: {[accIdx]: {selected: {$set: false}}}})
								}
							}
						}
					}

					newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {selected: {$set: true}}}})*/

					// Can't select an account that has a subAccount
					if (account.subAccounts !== undefined) {
					}
					else if (prevState.settings.allowInteraction === false) {
						// Interaction disabled: do nothing
					}
					// Check that we won't end up with two of the same item
					else if (prevState.selected.length == 1 && prevState.selected[0].name == account.name) { 
						// One selected previously and it's the same one we're trying to add now - don't do anything
					}
					else if (prevState.selected.length == 2 && prevState.selected[1].name == account.name) { 
						// The account we're trying to add now is already the most recent selection - don't do anything
					}
					else {
						var newSelected = update(prevState.selected, {$push: [account]})
						var newAccounts = prevState.accounts
						if (newSelected.length > 2) {
							var itemToDeselect = newSelected[0]
							newSelected = update(newSelected, {$splice: [[0, 1]]})

							// Set selected=false on the item we're deselecting
							//newAccounts = update(newAccounts, {[itemToDeselect.type]: {[itemToDeselect.accountIdx]: {selected: {$set: false}}}})
							newAccounts = this.actions.setSingleItemField(newAccounts, itemToDeselect.type, itemToDeselect.accountIdx, 'selected', false)
						}
						//newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {selected: {$set: true}}}})
						newAccounts = this.actions.setSingleItemField(newAccounts, account.type, account.accountIdx, 'selected', true)
						return {selected: newSelected, accounts: newAccounts, settings: update(prevState.settings, {enableViewTransactions: {$set: true}})}
					}
					return {}
				})
			},
			onResizeStart: function(account, direction, overrideDisableAnimate) {
				// Find the 'other' selected account
				//console.log('Have account ' + account.name + '.' + account.type + '.' + account.accountIdx + ' and getting ' + otherAcctObj.name + '.' + otherAcctObj.type + '.' + otherAcctObj.accountIdx)
				
				const thisAcct = {type: account.type, accountIdx: account.accountIdx}
				var parentAccObj = this.actions.getParentAccount(account, this.state.accounts) 

				var otherAcct, parentOaObj
				const otherAcctObj = this.actions.getOtherSelectedAccount(account)
				if (otherAcctObj) {
					otherAcct = {type: otherAcctObj.type, accountIdx: otherAcctObj.accountIdx}
					parentOaObj = this.actions.getParentAccount(otherAcctObj, this.state.accounts) 
				}      

				// Neither account can have subaccounts
				if(account.subAccounts || (otherAcct && otherAcct.subAccounts)) { // Should never be hit - disable dragging for accounts with subAccounts
					return
				}
		 
				var disableAnimation = true // Disable animation by default (it messes up the drag)
				if (overrideDisableAnimate !== undefined) {
					disableAnimation = overrideDisableAnimate
				}

				this.setState((prevState) => { // Update otherAcct dict in target object
					var newAccounts = prevState.accounts
					//newAccounts = update(prevState.accounts, {[account.type]: {[account.accountIdx]: {proposedChange_otherAcct: {$set: otherAcct}, proposedChange_zeroBumps: {$set: 0}}}})
					newAccounts = this.actions.setSingleItemField(newAccounts, account.type, account.accountIdx, 'proposedChange_amount', 0)
					newAccounts = this.actions.setSingleItemField(newAccounts, account.type, account.accountIdx, 'proposedChange_zeroBumps', 0)
					newAccounts = this.actions.unsetSingleItemField(newAccounts, account.type, account.accountIdx, 'proposedChange_nulled')
					newAccounts = this.actions.unsetSingleItemField(newAccounts, account.type, account.accountIdx, 'nulled')

					if (otherAcct && Object.keys(otherAcct).length > 0) { // If we're dragging two accounts at once
						newAccounts = this.actions.setSingleItemField(newAccounts, 
							account.type, account.accountIdx, 'proposedChange_otherAcct', otherAcct)
						newAccounts = this.actions.setSingleItemField(newAccounts, 
							otherAcctObj.type, otherAcctObj.accountIdx, 'proposedChange_otherAcct', thisAcct)
						newAccounts = this.actions.setSingleItemField(newAccounts, 
							otherAcctObj.type, otherAcctObj.accountIdx, 'proposedChange_amount', 0)
						newAccounts = this.actions.unsetSingleItemField(newAccounts, 
							otherAcctObj.type, otherAcctObj.accountIdx, 'nulled')
					}

					if (parentAccObj && parentAccObj.nulled) {
						newAccounts = this.actions.unsetSingleItemField(newAccounts, parentAccObj.type, parentAccObj.accountIdx, 'nulled')
					}
					
					if (parentOaObj && parentOaObj.nulled) {
						newAccounts = this.actions.unsetSingleItemField(newAccounts, parentOaObj.type, parentOaObj.accountIdx, 'nulled')
					}

					return { settings: update(prevState.settings, {animate: {$set: !disableAnimation}}), accounts: newAccounts }
				})
			},
			toggleShowSubAccounts: function(accObj) {
				this.setState((prevState) => {
					var newAccObj = this.actions.getAccount(accObj.type, accObj.accountIdx)
					var newAccounts = this.actions.setSingleItemField(prevState.accounts, accObj.type, accObj.accountIdx, 'showSubAccounts', !newAccObj.showSubAccounts)
					return {accounts: newAccounts}
				})
			},
			getParentAccount: function(accObj, accounts) {
				if (accounts === undefined) {
					accounts = this.state.accounts
				}

				if (accObj.type.substr(0, 3) === 'sub') {
					const pType = accObj.type.substr(4)
					const pIdx = parseInt(accObj.accountIdx.split('_')[0])
					return accounts[pType][pIdx]
				}
				else {
					return null
				}
			},
			getAccountByName: function(name, accounts) {
				if (accounts === undefined) {
					accounts = this.state.accounts
				}
				for (var type in accounts) {
					for(var aIdx in accounts[type]) {
						const a = accounts[type][aIdx]
						if (a.name === name) { return a }
						else if (a.subAccounts) {
							for (var saIdx in a.subAccounts) {
								const sa = a.subAccounts[saIdx]
								if (sa.name === name) { return sa }
							}
						}
					}
				}
			},
			getAccount: function(type, accountIdx, accounts) {
				if (accounts === undefined) {
					accounts = this.state.accounts
				}

				if (type.substr(0, 3) === 'sub') {
					const pType = type.substr(4)
					const aIdxSplit = accountIdx.split('_')
					const pIdx = parseInt(aIdxSplit[0])
					const aIdx = parseInt(aIdxSplit[1])
					return accounts[pType][pIdx].subAccounts[aIdx]
				}
				else {
					return accounts[type][accountIdx]
				}
			},
			getOtherSelectedAccount: function(account) { // Gets the other selected account - the account that will take the opposite entry
				for (var accType in {'a': 1, 'l': 1, 'e': 1}) { // Iterate through every account in this.state.accounts
					for (var accIdx in this.state.accounts[accType]) {
						var accObj = this.state.accounts[accType][accIdx]
						if (!(accObj.accountIdx === account.accountIdx && accType === account.type) && accObj.selected === true) {
							return accObj
						}
						if (accObj.subAccounts) {
							for (var saIdx in accObj.subAccounts) {
								var sAccObj = accObj.subAccounts[saIdx]
								if (!(sAccObj.accountIdx === account.accountIdx && sAccObj.type === account.type) && sAccObj.selected === true) {
									return sAccObj
								}
							}
						}
					}
				}
			},
			saveCurrentProposedChange: function(account) { // Saves the current proposed change to _startingAmount, so that we can persist proposed change across multiple drags and have them stack appropriately
				this.setState((prevState) => {
					var accObj = this.actions.getAccount(account.type, account.accountIdx, prevState.accounts)
					var existingChangeAmt = accObj.proposedChange_amount 
					if (existingChangeAmt !== undefined) {
						//console.log('Setting ' + account.type + '.' + account.accountIdx + ' startingAmount to ' + existingChangeAmt)
						var newAccounts = this.actions.setSingleItemField(prevState.accounts, accObj.type, accObj.accountIdx, 'proposedChange_startingAmount', existingChangeAmt)
						return { accounts: newAccounts }
					}
				})
			},
			changeAccountValue: function(accounts, accObj, entrySign, valueChange) {
				var existingChangeAmt = 0
				if (accObj.proposedChange_startingAmount !== undefined) { // We're resizing multiple times, so we already had a non-zero proposed change here
					existingChangeAmt = accObj.proposedChange_startingAmount
				}

				//var newAccounts = update(prevState.accounts, {[account.type]: {[account.accountIdx]: {proposedChange_amount: {$set: existingChangeAmt + (entrySign * valueChange)}}}})
				return this.actions.setSingleItemField(accounts, accObj.type, accObj.accountIdx, 'proposedChange_amount', existingChangeAmt + (entrySign * valueChange))

			},
			flipAccount: function(accounts, accObj, entrySign, valueChange) {
				var existingChangeAmt = 0
				if (accObj.proposedChange_startingAmount !== undefined) { // We're resizing multiple times, so we already had a non-zero proposed change here
					existingChangeAmt = accObj.proposedChange_startingAmount
				}
				valueChange = valueChange - 50 // Reduce height by more than we already have (hence flipping it)
				const chg = existingChangeAmt + (entrySign * valueChange)

				//newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {proposedChange_amount: {$set: chg}, proposedChange_startingAmount: {$set: chg}, $unset: ['proposedChange_zeroBumps']}}})
				accounts = this.actions.setSingleItemField(accounts, accObj.type, accObj.accountIdx, 'proposedChange_amount', chg)
				accounts = this.actions.setSingleItemField(accounts, accObj.type, accObj.accountIdx, 'proposedChange_startingAmount', chg)
				accounts = this.actions.unsetSingleItemField(accounts, accObj.type, accObj.accountIdx, 'proposedChange_zeroBumps')
				return accounts
			},
			onResize: function(account, direction, resizeDelta) {
				//console.log(direction)
				//if (account.proposedChange_otherAcct !== undefined && Object.keys(account.proposedChange_otherAcct).length > 0) {
					
				var oa = account.proposedChange_otherAcct
				var valueChange = resizeDelta.height / this.state.settings.initialScale // Height change, in dollars

				var accObj = this.actions.getAccount(account.type, account.accountIdx)
				var curVal = accObj.value
				curVal += accObj.proposedChange_amount

				const curBarHeight = parseInt(Math.floor(Math.abs(curVal) * this.state.settings.initialScale))
				var incrementZeroBumps = false
				if (curBarHeight <= this.state.settings.minAccountBarHeight) {
					incrementZeroBumps = true
				}

				this.setState((prevState) => {

					accObj = this.actions.getAccount(account.type, account.accountIdx, prevState.accounts)
					var parentAccObj = this.actions.getParentAccount(accObj, prevState.accounts) // Returns null if accoutn is not a child
					const accType = account.type.substr(-1)

					var oaObj, parentOaObj, oaType
					if (oa) {
						oaObj = this.actions.getAccount(oa.type, oa.accountIdx, prevState.accounts)
						parentOaObj = this.actions.getParentAccount(oaObj, prevState.accounts) // Returns null if accoutn is not a child
						oaType = oa.type.substr(-1)
					}

					var entrySign = 1 // We're dragging a positive balance up or down
					if (direction === 'bottom') { // We're dragging a negative balance up or down
						entrySign = -1 // Larger box means larger negative value for valueChange
					}
					
					var newAccounts = prevState.accounts

					// Update primary account value (proposedChange)
					newAccounts = this.actions.changeAccountValue(newAccounts, accObj, entrySign, valueChange)
					// Update parent if required

					// Update secondary (other) account value (proposedChange)
					var oppositeEntrySign = 1 * entrySign
					if (accType === oaType || (['l','e'].indexOf(accType) >= 0 && ['l','e'].indexOf(oaType) >= 0)) { // Accounts are on the same side
						oppositeEntrySign = oppositeEntrySign * -1
					}
					
					if (oaObj) {
						newAccounts = this.actions.changeAccountValue(newAccounts, oaObj, oppositeEntrySign, valueChange)
						// Update parent OA if required
					}
					
					// Update parent accounts, but only if they're not the same 
					if (parentAccObj && parentOaObj && parentAccObj.name === parentOaObj.name) {
						// Do nothing.
					}
					else {
						if (parentAccObj) { newAccounts = this.actions.changeAccountValue(newAccounts, parentAccObj, entrySign, valueChange) }
						if (parentOaObj) { newAccounts = this.actions.changeAccountValue(newAccounts, parentOaObj, oppositeEntrySign, valueChange) }
					}

					// Old way of detecting that we've hit zero
					//if (parseInt(Math.round(existingChangeAmt + valueChange)) === parseInt(Math.round(-1 * account.value))) {}

					if (incrementZeroBumps) { // Are we sitting at zero?
						const zeroBumps = accObj.proposedChange_zeroBumps
						if (zeroBumps > 50) { // Flip things negative. This will destroy the element and hence we need to clean up as onResizeStop normally would (it isn't called here)

							newAccounts = this.actions.flipAccount(account, accObj, entrySign, valueChange)
							newAccounts = this.actions.flipAccount(account, oaObj, oppositeEntrySign, valueChange)
						}
						else { // Increment zero bump count
							//newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {proposedChange_zeroBumps: {$apply: function(x) { return x + 1 }}}}})
							newAccounts = this.actions.setSingleItemField(newAccounts, accObj.type, accObj.accountIdx, 'proposedChange_zeroBumps', accObj.proposedChange_zeroBumps + 1)
						}
					} else {
						newAccounts = this.actions.setSingleItemField(newAccounts, accObj.type, accObj.accountIdx, 'proposedChange_zeroBumps', 0)
					}

					return {accounts: newAccounts}
				})
			},
			onResizeStop: function(account, direction, resizeDelta) {

				// Save the current proposed change to _startingAmount, so that we can persist proposed change across multiple drags and have them stack appropriately
				
				// Get parent accounts if relevant
				var parentAccObj = this.actions.getParentAccount(account) // Returns null if account is not a child
				
				// Save in-progress change
				this.actions.saveCurrentProposedChange(account)
				if (parentAccObj) { this.actions.saveCurrentProposedChange(parentAccObj) }

				var otherAcctObj = this.actions.getOtherSelectedAccount(account)
				if (otherAcctObj) {
					var parentOaObj = this.actions.getParentAccount(otherAcctObj) // Returns null if account is not a child

					// Save in-progress change for OA
					this.actions.saveCurrentProposedChange(otherAcctObj)
					if (parentOaObj) { this.actions.saveCurrentProposedChange(parentOaObj) }
				} 

				this.setState((prevState) => {
					//var newAccounts = update(prevState.accounts, {[account.type]: {[account.accountIdx]: {$unset: ["proposedChange_zeroBumps"] }}})
					var newAccounts = this.actions.unsetSingleItemField(prevState.accounts, account.type, account.accountIdx, 'proposedChange_zeroBumps')

					return {settings: update(prevState.settings, {animate: {$set: true}}), accounts: newAccounts}
				})

				/*this.setState((prevState) => {
					var oa = account.proposedChange_otherAcct
					var oaObj = prevState.accounts[oa.type][oa.accountIdx]
					var oaValue = oaObj.value

					var change = account.proposedChange_amount
					var oaChange = oaObj.proposedChange_amount

					if (change !== undefined && change !== 0) {
						// Update anchor account
						var newAccounts = prevState.accounts
						newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {$unset: ['proposedChange_amount', 'proposedChange_otherAcct']}}})
						newAccounts = update(newAccounts, {[account.type]: {[account.accountIdx]: {value: {$set: (account.value + change)}}}})

						// Update OA 
						newAccounts = update(newAccounts, {[oa.type]: {[oa.accountIdx]: {$unset: ['proposedChange_amount', 'proposedChange_otherAcct']}}})
						newAccounts = update(newAccounts, {[oa.type]: {[oa.accountIdx]: {value: {$set: (oaValue + oaChange)}}}})
						return {accounts: newAccounts}
					}
					return {}
				})*/
			},
			rescaleApp: function() {
				this.setState((prevState) => {
					var totalAssets = 0
					for (var aIdx in prevState.accounts['a']) {
						totalAssets += prevState.accounts.a[aIdx].value
					}
					if (totalAssets > 0) {
						var initialScale = prevState.settings.initialHeight / totalAssets
						return { settings: update(prevState.settings, {initialScale: {$set: initialScale}}) }
					}
				})
			},
			parseParam: function(pStr) {
				var paramValues = []
				console.log('parsing ' + pStr)
				const r = /(([\w]*)\()?([\w]+)=\"?([\w\s]+)\)?/
				const m = pStr.match(r)
				console.log(m)
				if (m) {
					const fn = m[2]
					const field = m[3]
					const fieldValue = m[4]

					var output = 0
					var results = []
					for (var accType in {'a': 1, 'l': 1, 'e': 1}) {
					for (var accIdx in this.state.accounts[accType]) {
							var accObj = this.state.accounts[accType][accIdx]
							if (accObj[field] === fieldValue) {
								var curVal = accObj.value
								if (accObj.proposedChange_amount !== undefined) {
									curVal += accObj.proposedChange_amount 
								}
								results.push(curVal)
							}
							if (accObj.subAccounts) {
								for (var saIdx in accObj.subAccounts) {
									var sAccObj = accObj.subAccounts[saIdx]
									if (sAccObj[field] === fieldValue) {
										var curVal = sAccObj.value
										if (sAccObj.proposedChange_amount !== undefined) {
											curVal += sAccObj.proposedChange_amount 
										}
										results.push(curVal)
									}
								}
							}
						}
					}
					console.log(results)
					if (fn) {
						if (fn === 'sum') {
							output = results.reduce((a, b) => a+b)
						} 
					}
					else { // If there's no summary function, we have to assume the filter only returned one result, so this single element is our value for this parameter
						output = results[0]
					}
					return output
				}
			}, 
			getInfoValue: function(item) {
				if (!item.type) { return }

				var value = null
				if (item.params) {
					var paramValues = []
					for (var pIdx in item.params) { // Parse\translate params
						const pStr = item.params[pIdx]
						paramValues.push(this.actions.parseParam(pStr))
					}
					if (item.type === 'ratio' && paramValues.length === 2) {
						value = (paramValues[0] / paramValues[1]).toFixed(item.precision !== undefined ? item.precision : 2)
					}
					if (item.type === 'value' && paramValues.length === 1) {
						value = (paramValues[0]).toFixed(item.precision !== undefined ? item.precision : 2)
					}
				}
				return value

			},
			sleep: function(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			},
			toggleSetting: function(setting) {
				this.setState((prevState) => {
					return { settings: update(prevState.settings, {[setting]: {$set: !prevState.settings[setting]}}) }
				})
			},
			/*stepCursor(stepAmt) {
				if (stepAmt < 0 && this.state.cursor === 0) {
					return
				}
				if (stepAmt > 0) { // We're stepping forward -- apply the next transaction
					if (!this.state.settings.enableViewTransactions) {
						this.actions.toggleSetting('enableViewTransactions')
					}
					else {
						if (this.state.preloadedTransactions[this.state.cursor - 1 + stepAmt]) {
							this.actions.setUpTransaction(this.state.preloadedTransactions[this.state.cursor - 1 + stepAmt])
							this.setState((prevState) => {
								return { cursor: prevState.cursor + stepAmt }
							})
						}
					}
				}
				else {
					this.actions.toggleSetting('enableViewTransactions')
				}
			},*/

		}

		for (var idx in this.accountActions) { this.accountActions[idx] = this.accountActions[idx].bind(this) }
		this.actions = this.accountActions
	
   }
	componentDidMount() {
		// If we've been passed a proposedTransaction, apply it
		if (this.props.preloadedTransaction) {
			console.log('loading txn')
			this.actions.applyPreloadedTransaction()
		}
	}  
   render() {
      // Get total assets
      var accountsByType = {a: [], a_neg: [], le: [], le_neg: []}
      var displayAccountsByType = {a: [], a_neg: [], le: [], le_neg: []}
      var bars = {lhs: [], rhs: []}

      // Arrange accounts into lists based on which bar they will appear in
      for (var aIdx in this.state.accounts.a) { 
         const acc = this.state.accounts.a[aIdx]
         const curDisplayValue = acc.value + (acc.proposedChange_amount !== undefined ? acc.proposedChange_amount : 0)
         if (curDisplayValue >= 0) { 
            accountsByType['a'].push(acc)
         } else { accountsByType['a_neg'].push(acc) }
      }
      for (var accType in {'l': 1, 'e': 1}) {
         for (var aIdx in this.state.accounts[accType]) {
            const acc = this.state.accounts[accType][aIdx]
            const curDisplayValue = acc.value + (acc.proposedChange_amount !== undefined ? acc.proposedChange_amount : 0)
            if (curDisplayValue >= 0) { 
               accountsByType['le'].push(acc)
            } else { accountsByType['le_neg'].push(acc) }
         }
      }

      for (var barType in accountsByType) {
         const accList = accountsByType[barType]
         for (aIdx in accList) {
            const acc = accList[aIdx]
            if (acc.nulled !== true) { // Don't display closed/nulled accounts
               const accJsx = (<Account {...acc} 
                  scale={this.state.settings.initialScale} // Pixels of height per dollar 
                  minHeight={this.state.settings.minAccountBarHeight}
                  barWidth={this.state.settings.barWidth}
                  enableViewTransactions={this.state.settings.enableViewTransactions}
                  enableInteraction={this.state.settings.enableInteraction}
                  enableDebitsCredits={this.state.settings.enableDebitsCredits}
                  showCloseButton={this.state.settings.showCloseButton}
                  animate={this.state.settings.animate}
                  totalBarAcctCount={accList.length} 
                  key={acc.type + String(acc.accountIdx)} 
                  barIdx={parseInt(aIdx)} // Position on bar
						actions={this.actions}
                  />)
               displayAccountsByType[barType].push(accJsx)
            }
         }
      }

      for (var side in displayOrder) {
         for (var barTypeIdx in displayOrder[side]) { 
            const barType = displayOrder[side][barTypeIdx]
            if (displayAccountsByType[barType].length > 0) {
               bars[side].push(<div className={"bar " + side + 'Bar'} key={side + barTypeIdx} style={{width: String(this.state.settings.barWidth) + 'px'}}>{displayAccountsByType[barType]}</div>)
            }
         }
      }

      return (
			<div>
				<div className="mainContainer" style={{height: this.state.settings.initialHeight * 1.05}}>
					<div>
						<div className={`barContainer lhsBarContainer`}>
							{bars.lhs}
						</div>
						<div className={`barContainer rhsBarContainer`}>
							{bars.rhs}
						</div>
					</div>
				</div>
				<div className="ground shadowGradient"/>
				{ this.state.settings.showButtons ? <ActionButtons actions={this.actions} /> : <span/> }
				{ this.state.settings.showSelectorButtons ? <SelectorButtons actions={this.actions} preloadedTransactions={this.props.preloadedTransactions}/> : <span/> }
				{ this.props.challenges ? <Challenges actions={this.actions} challenges={this.props.challenges} state={this.state}/> : <span/> }
				{ this.state.settings.showNarration ? <div className="narration">{this.state.narration}</div> : <span/>}
				{ this.state.settings.showJournalEntry ? <JournalEntry
						accounts={this.state.accounts}
						settings={this.state.settings}
						actions={this.actions.account}
						/> : <span/> }
			</div>

      )
   }

}

var renderAccount = function(props) {
   var proposedChange = 0
   var curDisplayValue = props.value
   var isResizing = false
   var isNulled = false

   if (props.enableViewTransactions && props.proposedChange_amount !== undefined) {
      proposedChange += props.proposedChange_amount 
      curDisplayValue += props.proposedChange_amount
      isResizing = true
   }

   if(props.proposedChange_nulled === true || curDisplayValue === 0) { 
      isNulled = true
   }

   var isNegative = false
   if (curDisplayValue < 0) {
      isNegative = true
   }

   const height = Math.abs(curDisplayValue) * props.scale
   const heightStr = String(height) + 'px'

   var acctSmall = false
   if (height < 33.0) {
      acctSmall = true
   }

   var hideAmount = false
   if (height < 40) {
      hideAmount = true
   }


   var accClasses = {
      account: true,
      accountTop: props.barIdx === 0,
      accountBottom: props.barIdx === (props.totalBarAcctCount - 1),
      accountSelected: props.selected === true,
      infoOnBottom: props.infoOnBottom === true,
		accountAnimate: props.animate === true,
      accountZero: curDisplayValue === 0,
      accountSmall: acctSmall && !isNulled & props.enableViewTransactions, // Hide account name inside the accountBox if small, not zero, and transaction view toggled on
		accountHideAmount: hideAmount,
   }

   accClasses['accountType_' + props.type.substr(-1)] = true

   // Establish which side of the river we're on.
   var side = 'lhs'
   if(props.type !== 'a') {
      side = 'rhs'
   }

   if(props.subAccounts) {
      if (props.showSubAccounts) {
         // This section of code arranges subAccounts (as AccountBars.js does for top-level accounts)
         var subAccounts = {lhs: "", rhs: ""}
         var subAccountsByPosition = {inner: [], outer: []}
         var subAccountSideLookup = {lhs: {inner: "rhs", outer: "lhs"}, rhs: {inner: "lhs", outer: "rhs"}}
         var displayAccountsBySide = {lhs: [], rhs: []}

         for (var saIdx in props.subAccounts) {
            const subAcc = props.subAccounts[saIdx]
            const curDisplayValue = subAcc.value + (subAcc.proposedChange_amount !== undefined ? subAcc.proposedChange_amount : 0)
            if (curDisplayValue >= 0) {
               subAccountsByPosition.inner.push(subAcc) // Inner has positive, outer negative balances
            } else {
               subAccountsByPosition.outer.push(subAcc)
            }
         }

         for (var curPos in subAccountsByPosition) {
            for (var saIdx in subAccountsByPosition[curPos]) {
               const subAcc = subAccountsByPosition[curPos][saIdx]
               const accJsx = (<SubAccount {...subAcc} 
                  scale={props.scale} 
                  minHeight={props.minHeight}
                  barWidth={props.barWidth}
                  enableViewTransactions={props.enableViewTransactions}
                  enableInteraction={props.enableInteraction}
                  enableDebitsCredits={props.enableDebitsCredits}
                  showCloseButton={props.showCloseButton}
                  animate={props.animate}
                  totalBarAcctCount={subAccountsByPosition[curPos].length} 
                  key={subAcc.type + String(subAcc.accountIdx)} 
                  barIdx={parseInt(saIdx)} // Position on bar
						actions={props.actions}
                  />)
               displayAccountsBySide[subAccountSideLookup[side][curPos]].push(accJsx) // Map inner/outer to left/right as required
            }
         }
         subAccounts[side] = <div className={"subAccountBarsContainer " + side}>
            <div className={"barContainer " + side + "BarContainer"} style={{width: String(props.barWidth * 2 + 10) + 'px'}}>
               <div className={"bar " + side + "Bar"} key={'sub0'} style={{width: String(props.barWidth) + 'px'}}>{displayAccountsBySide.lhs}</div>
               <div className={"bar " + side + "Bar"} key={'sub1'} style={{width: String(props.barWidth) + 'px'}}>{displayAccountsBySide.rhs}</div>
            </div>
         </div>
      }
      
      var showSubAccountToggle = <span/>
      if (props.enableShowSubAccountToggle) {
         showSubAccountToggle = <div className="showSubAccounts"><Icon style={{cursor: 'pointer'}} icon={arrowLeftThin} onClick={(e) => props.actions.toggleShowSubAccounts(props)}/></div>
         if ((props.showSubAccounts && props.type === 'a') || (!props.showSubAccounts && props.type !== 'a')) {
            showSubAccountToggle = <div className="showSubAccounts"><Icon style={{cursor: 'pointer'}} icon={arrowRightThin} onClick={(e) => props.actions.toggleShowSubAccounts(props)}/></div>
         }
      }
   }

   //minHeight={ isNulled ? 0 : props.minHeight}
   return (
      <div className={`accountContainer`} onClick={(e) => {
         if(props.enableViewTransactions && props.enableInteraction) {
            props.actions.onClick(props)
         }
         e.stopPropagation()
      }}>
         {subAccounts ? subAccounts.lhs : <span/>}
         <Resizable
            size={{ width: '100%', height: heightStr }}
            className={classNames(accClasses)}
            onResizeStart={(e, direction, ref) => {
               props.actions.onResizeStart(props, direction)
            }}
            onResize={(e, direction, ref, d) => {
               props.actions.onResize(props, direction, d)
            }}
            onResizeStop={(e, direction, ref, d) => {
               props.actions.onResizeStop(props, direction, d)
            }}
            enable={{ top: props.enableInteraction && props.enableViewTransactions && props.subAccounts === undefined && props.selected === true && !isNegative, 
                      bottom:  props.enableInteraction && props.enableViewTransactions && props.subAccounts === undefined && props.selected === true && isNegative, 
                      right:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            >
            <div className="acctName"><div className="acctDot"/>{props.name}</div>
            <div className="acctBal">
                  <AnimatedNumber component="text" 
                     initialValue={curDisplayValue}
                     value={curDisplayValue}
                     //{isNegative ? "-":""}${Math.abs(curDisplayValue).toFixed(0)}i
                     //style={{ transition: '0.5s ease-out' }}
                     formatValue={n => basicFunctions.formatBalance(n)}
                     duration={ props.animate ? 500 : 0}
                     />
            </div>
            { showSubAccountToggle ? showSubAccountToggle : <span/> }
            <AccountInfoBox {...props} isResizing={isResizing} proposedChange={proposedChange} isNegative={isNegative} height={height} acctSmall={acctSmall}/>
         </Resizable>
         {subAccounts ? subAccounts.rhs : <span/>}
      </div>

   )
}

class Account extends Component {
   constructor(props) {
      super(props)
   }
   render() {
      return renderAccount(this.props)
   }
}

class SubAccount extends Component {
   constructor(props) {
      super(props)
   }
   render() {
      return renderAccount(this.props)
   }
}

class AccountInfoBox extends Component {
   //constructor(props) { super(props) }
   render() {
      var accInfoClasses = {
         accountInfoContainer: true,
      }

      var changeFormatted = "0"
      if (this.props.proposedChange !== undefined) {
         changeFormatted = this.props.proposedChange.toFixed(0)
      }

      // For working out if we should offer to close this account
      var proposedBalanceHeight = this.props.height
      var proposedBalanceIsLow = (proposedBalanceHeight < 10)

      var previousBalance = ""
      var changeBalance = ""
      var changeBar = ""
      var barGhost = ""

      if (this.props.selected || (this.props.proposedChange_amount !== undefined && this.props.proposedChange_amount!== 0)) { // XXX Using proposedChange_amount, as per below. Want AccountInfoBox to show even when enableViewTransactions is toggled off
         var changeBarStyles = {height: (Math.abs(this.props.proposedChange * this.props.scale)) + "px"}
         if (!this.props.isResizing) {
            changeBarStyles = {height: '0px'}
         }

         // Set class flags
         var posChange = true
         var sign = "+"
         if (this.props.proposedChange_amount <= 0) { // Change is negative. XXX Note we're deliberately using proposedChange_amount, which retains its value when enableViewTransactions is toggled. Otherwise neg/pos is not handled well during the toggle (it only sees zero -- it doesn't know the sign of the underlying (hidden) transaction
            posChange = false
            sign = "-"
         }

         var changeClasses = {
            posBar: !this.props.isNegative,
            posChange: posChange,
            negBar: this.props.isNegative,
            negChange: !posChange,
				accountAnimate: this.props.animate,
				isHidden: !this.props.selected || this.props.proposedChange_amount === undefined || this.props.proposedChange_amount === 0,
         }

         changeBar = ( <div className={classNames('changeBarStripe', 'changeBar', changeClasses)} style={changeBarStyles} /> )

         var barGhostStyles = Object.assign({}, changeBarStyles)
         barGhostStyles.height = (Math.abs(this.props.proposedChange * this.props.scale) + 1) + "px" // Account box doesn't have bottom border, so we need to grow by 1px to match
         barGhostStyles.width = this.props.barWidth
         //barGhostStyles.minHeight = this.props.minHeight

         barGhost = ( <div className={classNames('barGhost', 'changeBar', changeClasses)} style={barGhostStyles} /> )

         var entryTypeText = "Change"
         if (this.props.enableDebitsCredits) {
            var dcEntryTypeText = entryTypes[basicFunctions.getEntryType(this.props)]
            if (dcEntryTypeText) { entryTypeText = dcEntryTypeText }
         }

         changeBalance = (<div className={classNames('accInfo_changeItem', changeClasses)}>
               { this.props.acctSmall & this.props.enableViewTransactions ? <div className="accInfoAcctName">{this.props.name}</div> : <span/> }
               <div className={`balanceChange`}>
						<span>{this.props.enableDebitsCredits ? basicFunctions.formatAbsChange(this.props.proposedChange) : 
							basicFunctions.formatChange(this.props.proposedChange)}</span>

                  <div className={`subtitle`}>{entryTypeText}</div>
                  {this.props.enableViewTransactions && this.props.showCloseButton && proposedBalanceIsLow ? 
                     <div className={`closeButton`}>
                        <button onClick={(e) => this.props.actions.closeAccount(this.props)}>Close</button>
                     </div> : <span/>}
               </div>
            </div>)

         /*if (this.props.type === 'a') {
            changeItem = (<div className={`change`}>{changeBalance}{changeBar}</div>)
         } else { changeItem = (<div className={`change`}>{changeBar}{changeBalance}</div>) }
         */
      }

      // Change order of display depending on which side we're on 
      /* 
      var balances = ""
      if (this.props.type === 'a') {
         balances = (<div className={`balances`}>{balance}</div>)
      } else { balances = (<div className={`balances`}>{balance}</div>) }
      */

      return (
         <div className={classNames(accInfoClasses)}>
            <div className='accountInfoContainerInner'>
               {changeBar}
               {barGhost}
               {changeBalance}
            </div>
         </div>
      )
   }
}

class ActionButtons extends Component {
	//constructor(props) { super(props)  }
	render() {
		return (
			<div>
				<button onClick={this.props.actions.clearEntry}>Clear</button>
				<button onClick={this.props.actions.applyEntry}>Apply</button>
				<button onClick={(event) => this.props.actions.toggleSetting('enableViewTransactions')}>Toggle</button>
			</div>
		)
	}
}     
class Challenges extends Component {
	constructor(props) { 
		super(props)  
		this.state = {
			currentIdx: 0,
		}
	}
	render() {
		var curChallenge = this.props.challenges[this.state.currentIdx]
		var correct = false

		// Check if challenge satisfied
		if (curChallenge.type == 'selectedAccounts') {
			if (curChallenge.answer.length == this.props.state.selected.length) {
				var matches = 0
				for (var aIdx in this.props.state.selected) {
					if (curChallenge.answer.indexOf(this.props.state.selected[aIdx].name) >= 0) {
						//console.log('looking in ',this.props.state.selected[aIdx].name)
						matches += 1
					}
				}		
				console.log(matches)
				if (matches == curChallenge.answer.length) {
					correct = true
				}
			}
		}
		
		return (
			<div>
				{ correct ? 
					<motion.div
						animate={{scale: [1, 1.2, 1]}}
						transition={{ duration: 0.3, times: [0, 0.3, 1] }}>
						<div className={"challenge correct"}>
							Correct! {curChallenge.correctText}
						</div>
					</motion.div> :
					<div className={"challenge"}>
						<b>Challenge</b>: {curChallenge.content}
					</div>
				}
			</div>
		)
	}
}     

class SelectorButtons extends Component {
	//constructor(props) { super(props)  }
	render() {
		var buttons = []
		for (var ptIdx in this.props.preloadedTransactions) {
			const txn = this.props.preloadedTransactions[ptIdx]
			buttons.push(<motion.button 
				type="button"
				whileTap={{ scale: 0.95 }}
				transition={{ duration: 0.1 }}
				className="btn actionButton buttonGradient_green"
				key={"selectorButton_" + String(ptIdx)}
				onClick={() => {this.props.actions.setUpTransaction(txn, true)}}>
				{txn[0].selectorLabel} </motion.button>)
		}

		buttons.push(<motion.button
				type="button"
				whileTap={{ scale: 0.95 }}
				transition={{ duration: 0.1 }}
				className="btn actionButton"
				key="selectorButton_clear"
				onClick={() => {this.props.actions.clearEntry(false)}}>
			Clear</motion.button>)

		return (
			<div className="selectorButtons">
				{buttons}
			</div>
		)
	}
}     


class JournalEntry extends Component {
   //constructor(props) { super(props) }
   render() {
      var journalEntries = []
      //var accountOptions = {a: [], l: [], e: []}

      for (var accType in {a: 0, l: 0, e: 0}) {
         for (var aIdx in this.props.accounts[accType]) {
            const acc = this.props.accounts[accType][aIdx]
            //accountOptions[accType].push({name: acc.name, value: acc.type + acc.accountIdx})
            // Traditionally we'd start with the debit entries before the credit entries
            if (!acc.subAccounts && acc.proposedChange_amount !== undefined && acc.proposedChange_amount !== 0) {
               journalEntries.push( <JournalEntryItem {...acc} accounts = {this.props.accounts} actions = {this.props.actions} /> )
            }
            else if (acc.subAccounts) {
               for (var saIdx in acc.subAccounts) {
                  const sAcc = acc.subAccounts[saIdx]
                  if (sAcc.proposedChange_amount !== undefined && sAcc.proposedChange_amount !== 0) {
                     journalEntries.push( <JournalEntryItem {...sAcc} accounts = {this.props.accounts} actions = {this.props.actions} /> )
                  }
                  
               }
            }
         }
      }

      // TODO sort journal entries
      
      return (
         <div className="journalEntryContainer container">
            {journalEntries}
         </div>
      )
   }
}

class JournalEntryItem extends Component {
   //constructor(props) { super(props) }
   render() {
      // Prepare list for each select box
      var accountGroups = []
      const accountTypes = {a: 'Asset', l: 'Liabilities', e: 'Equity'}
      var accountOptions = {}
      for (var accType in this.props.accounts) {
         for (var adIdx in this.props.accounts[accType]) {
            const acc = this.props.accounts[accType][adIdx]
            const accTypeFull = accountTypes[accType]
            if (accountOptions[accTypeFull] === undefined) {
               accountOptions[accTypeFull] = []
            }
            accountOptions[accTypeFull].push(<option value={acc.value}>{acc.name}</option>)
         }
      }
      //console.log(accountOptions)
      for (var accTypeFull in accountOptions) {
         accountGroups.push(<select label={accTypeFull}>{accountOptions[accTypeFull]}</select>)
      }

      var entryType = basicFunctions.getEntryType(this.props)
		var entryTypeName = entryTypes[entryType]

      return (
         <div className={"row journalEntryItemContainer " + entryType}>
				<div className="jeCell col-3">{entryTypeName}</div>
				<div className="jeCell col-6">{this.props.name}</div>
				<div className="jeCell col-3">${Math.abs(Math.round(this.props.proposedChange_amount))}</div>
         </div>
      )
   }
}



export {AccountBars}
