import React, { Component } from 'react'
import {Helmet} from "react-helmet"

class Breakeven extends Component {
   constructor(props) {
      super(props)

		// Redirect to breakeven website XXX
		window.location = "https://www.breakeven.app"
   }

	render() {

		return (
			<div style={{backgroundColor: '#fff'}}>
				<Helmet> 
					<title>Breakeven | Real-time trading game for learning economics</title> 
					<meta name="description" content="A real-time trading game designed for learning core concepts in Economics, such as supply and demand." />
				</Helmet>
			</div>
		)
	}
}
export default (Breakeven)
