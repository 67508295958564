import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Analytics from 'react-router-ga'
import FrontPage from './FrontPage.js'

import Breakeven from './Breakeven.js'
import { Breakeven_SD } from './Breakeven_SD.js'
import BreakevenSdExplainer from './Breakeven_SD_Explainer.js'

import Talbot from './Talbot.js'

import { ProductPage } from './ProductPage.js'
import ShowcasePage from './ShowcasePage.js'

import CaseStudyPage from './CaseStudyPage.js'
import CaseStudyPageUWA from './CaseStudyPage_UWA.js'

import TOS from './components/terms.js'
import User from './User.js'
import {LoadingScreen, PageNotFound} from './components/basic.js'
import withAuth from './withAuth';
import AuthService from './utils/auth'

import './css/App.css'
import 'bootstrap/dist/css/bootstrap.css'

import ReactGA from "react-ga"

import StudentView from './studentView.js'
import ControlView from './controlView.js'
import DisplayView from './displayView.js'
import DemoView from './demoView.js'
import 'bootstrap/dist/css/bootstrap.css'


import { je } from './apps/je'
import { duality } from './apps/duality'
import { cards_stp } from './apps/cards_stp'
import { cards } from './apps/cards'
import { battleships } from './apps/battleships'
import { sudoku } from './apps/sudoku'
import { talbot } from './apps/talbot'

const auth = new AuthService()
const apiConfig = require('./config/api.js')

var apps = {}

apps[je.getAppRoute()] = je
apps[duality.getAppRoute()] = duality
apps[sudoku.getAppRoute()] = sudoku
apps[cards.getAppRoute()] = cards
apps[cards_stp.getAppRoute()] = cards_stp
apps[battleships.getAppRoute()] = battleships
apps[talbot.getAppRoute()] = talbot

class App extends Component {
   render() {
      var c = <LectureInteractiveAppViaCatembe
                  apiCall={auth.apiCall}
                  logout={auth.logout}
                  history={this.props.history}
                  match={this.props.match}
                  user={this.props.user}/>
      return c
   }
}

class LectureInteractiveAppViaCatembe extends Component {
   componentDidMount() {
		ReactGA.event({
			category: 'usage',
			action: 'loadApp',
			label: this.props.match.url,
		})
	}
   componentWillMount() {
      if (this.props.match && this.props.match.params && this.props.match.params.appRoute) {
         const r = this.props.match.params.appRoute
			const app = apps[r]

		  	if (this.props.pageTitle !== undefined) {
				document.title = this.props.pageTitle

			}
		  	else if (app && app.content && app.content.title) {
				var shortTitle = app.gameDetails.shortTitle || app.content.title
				document.title = "Catembe | " + shortTitle
			}
		}
   }
   render() {
      if (this.props.match && this.props.match.params && this.props.match.params.appRoute) {
         const r = this.props.match.params.appRoute
         var v = this.props.match.params.appView

			if (v === undefined && this.props.match.path) { // If v not a variable, it's probably hard coded. Grab it from the url
				v = this.props.match.path.split('/').slice(-1)[0]
			}

         if (apps[r] === undefined) {
				return ( <PageNotFound/> )
         }

			if (this.props.public === true) {
				if (v && v.toLowerCase() == 'demo') {
               return ( <DemoView appSettings={apps[r]} {...this.props}/> )
				} else if (v && v.toLowerCase() == 'public') {  // Legacy
               return (<StudentView appSettings={apps[r]} {...this.props}/>)
				}
			}

         if (v) {
            if (v.toLowerCase() === 'student') {
               return (<StudentView appSettings={apps[r]} {...this.props}/>)
            }
            else if (v.toLowerCase() === 'display') {
               return (<DisplayView appSettings={apps[r]} {...this.props}/>)
            }
            else if (v.toLowerCase() === 'control') {
               return (<ControlView appSettings={apps[r]} {...this.props}/>)
            }
            else if (v.toLowerCase() === 'demo') { // Demo view for authenticated users
               return ( <DemoView appSettings={apps[r]} {...this.props}/> )
            }
         }
      }
      return <span>Error -- Incorrect route</span>
   }
}

class PublicShowcaseApp_Duality extends Component {
	render = () => <PublicShowcaseApp appRoute="duality" appConfig="demo" appDeploymentId="duality_demo" />
}

class PublicShowcaseApp extends Component { // Enable short urls as shortcuts to STUDENT public route
   constructor(props) {
		super(props)
		this.state = { error: false,
			appRoute: props.appRoute || null,
			appConfig: props.appConfig || null,
			appDeploymentId: props.appDeploymentId || null }
	}
   componentDidMount() {
		if (this.state.appRoute === undefined) { // If we don't already have it via props, fetch it via API
			if (this.props.match && this.props.match.params && this.props.match.params.publicUrl) {
				auth.apiCall({publicUrl: this.props.match.params.publicUrl}, 'publicapp', this.receiveAppInfo, this.receiveError)
			} else {
				this.setState({error: true})
			}
		}
   }
	receiveAppInfo = (data) => {
		if(data && data.appDeploymentId && data.appRoute) {
			var obj = { redirect: true, appRoute: data.appRoute, appDeploymentId: data.appDeploymentId }
			if (data.appConfig) { obj.appConfig = data.appConfig }
			this.setState(obj)
		} else {
			this.setState({error: true})
		}
	}
	receiveError = () => {
		this.setState({error: true})
	}
   render() {
		if (this.state.error === true) { return ( <PageNotFound/> ) }
		else if (this.state.appRoute !== null) {
			return (
				<ShowcasePage appRoute={this.state.appRoute} {...this.props}>
					<LectureInteractiveAppViaCatembe
								apiCall={auth.apiCall}
								match={{
									params: {
										appRoute: this.state.appRoute,
										appConfig: this.state.appConfig,
										appDeploymentId: this.state.appDeploymentId,
										appView: 'student',
									},
								}}
								pageTitle={this.props.pageTitle}
								logout={auth.logout}
								public={true}
							/>
				</ShowcasePage>)
		}
		else { return ( <LoadingScreen/>) }
   }
}

class PublicAppRedirect extends Component { // Enable short urls as shortcuts to STUDENT public route
   constructor() {
		super()
		this.state = { redirect: false, error: false, appRoute: null, appConfig: null, appDeploymentId: null }
		this.redirect = this.redirect.bind(this)
		this.redirectError = this.redirectError.bind(this)
	}
   componentDidMount() {
		if (this.props.match && this.props.match.params && this.props.match.params.publicUrl) {
			auth.apiCall({publicUrl: this.props.match.params.publicUrl}, 'publicapp', this.redirect, this.redirectError)
		} else {
			this.setState({redirect: true, error: true})
		}
   }
	redirect(data) {
		if(data && data.appDeploymentId && data.appRoute) {
			var obj = { redirect: true, appRoute: data.appRoute, appDeploymentId: data.appDeploymentId }
			if (data.appConfig) { obj.appConfig = data.appConfig }
			this.setState(obj)
		} else {
			this.setState({error: true})
		}

	}
	redirectError() {
		console.log('redirect')
		this.setState({error: true})
	}
   render() {
		if (this.state.error === true) { return ( <PageNotFound/> ) }
		else if (this.state.redirect === true) {
			return ( <Redirect to={"/app/public/" + this.state.appRoute + "/" + this.state.appConfig + "/" + this.state.appDeploymentId + "/student"}/>)
		}
		else { return ( <LoadingScreen/>) }
   }
}

class PublicApp extends Component {
   render() {
      var c = <LectureInteractiveAppViaCatembe
                  apiCall={auth.apiCall}
                  history={this.props.history}
                  match={this.props.match}
                  logout={auth.logout}
						public={true}
						/>
      return c
   }
}

class FrontPage_Covid extends Component {
	render () { return <FrontPage {...this.props} covidMsg={true}/> }
}

class ProductPage_LiveMode extends Component {
	render () { return <ProductPage {...this.props} scrollToLiveMode={true}/> }
}

class PlatformApp extends Component {
	componentDidMount() {
		ReactGA.initialize(apiConfig.googleAnalyticsId)
		//ReactGA.pageview(window.location.pathname + window.location.search);
	}
    render() {
			//<Route exact path="/app/:publicUrl" component={PublicShowcaseApp}/>
        return (
            <Router>
					<Analytics id={apiConfig.googleAnalyticsId}>
						 <div>
							  <Switch>
									<Route exact path="/" component={FrontPage} />
									<Route exact path="/covid19" component={FrontPage_Covid} />

									<Redirect from='/games' to="/" />
									<Redirect from='/accounting' to="/" />
									<Redirect from='/finance' to="/" />
									<Redirect from='/economics' to="/" />

									<Route exact path="/breakeven" component={Breakeven} />

									<Route exact path="/talbot" component={Talbot} />

									<Route exact path="/interactives/:appRoute/live" component={ProductPage_LiveMode} />}/>
									<Route exact path="/busa30000" component={CaseStudyPage} />}/>
									<Route exact path="/acct5432" component={CaseStudyPageUWA} />}/>
									<Route exact path="/interactives/sd" component={Breakeven_SD} />}/>
									<Route exact path="/interactives/breakeven" component={Breakeven} />}/>
									<Route exact path="/interactives/:appRoute" component={ProductPage} />}/>
									<Route exact path="/terms" component={TOS} />
									<Route exact path="/user" component={User} />
									<Route exact path="/app/:appRoute/:appConfig/:appDeploymentId/public" component={PublicApp} /> // Legacy
									<Route exact path="/app/public/:appRoute/:appConfig/demo" component={PublicApp} />
									<Route exact path="/app/public/:appRoute/:appConfig/:appDeploymentId/:appView" component={PublicApp} />
									<Route exact path="/app/:appRoute/:appConfig/:appDeploymentId/:appView" component={withAuth(App)} />
									<Route exact path="/:publicUrl" component={PublicAppRedirect}/>

									<Route exact path="/app/duality" component={PublicShowcaseApp_Duality}/>
									<Route exact path="/app/sd-explainer" component={BreakevenSdExplainer} />}/>

							  </Switch>
						 </div>
					</Analytics>
            </Router>
        )
    }
}


//<Route exact path="/dev/:appRoute/:appConfig/:appView" component={withAuth(App)} />
//<Route exact path="/app/:appRoute/:appView" component={withAuth(App)} />
//<Route exact path="/app/:appRoute/:appConfig/:appView" component={withAuth(App)} />
export default PlatformApp;
