import React, { Component } from 'react'
import io from 'socket.io-client';
import { PublicUserForm, LoadingScreen, GoogleLoginForm, LiteStudentHeader } from './components/basic.js'
import googleAuthFns from './utils/google'
import './css/StudentDefault.css'

const config = require('./config/api');

//const coreFunctions = require('./coreFunctions')
const appBaseFunctions = require('./appBaseFunctions')

const socket = io(config.apiBaseUrl, {autoConnect: false})

class StudentView extends Component {
	constructor(props) {
		super(props)
		const appSettings =  Object.assign({}, this.props.appSettings)

		// Set clientId if one hasn't already been set
		this.state = appSettings.initialState
		this.socket = socket

		this.socket.on('updateState', data => {
			this.updateStateFromServer(data)
		})

		// Install the 'with auth' apiCall function we get from upstairs
		if (this.props.apiCall) { // Use authenticated apiCall
			this.apiCall = this.props.apiCall
		}

		// Install all core functions
		for (var item in appBaseFunctions) {
			this[item] = appBaseFunctions[item].bind(this)
		}
		this.sendInput_debounced = this.getDebounced_sendInput()
		this.sendInput_debounced_300ms = this.getDebounced_sendInput(200)

		this.config = appSettings.config
		this.gameDetails = appSettings.gameDetails
		this.content = appSettings.content

		if (this.props.match && this.props.match.params) {
			this.urlParams = this.props.match.params
			if (this.urlParams.appConfig) {
				this.appConfig = this.urlParams.appConfig
				if (appSettings.appConfigs && appSettings.appConfigs[this.appConfig]) {
					this.content = Object.assign(this.content, appSettings.appConfigs[this.appConfig]) // Eventually we prol want to load this from API or a file
				}
			}
			if (this.urlParams.appDeploymentId) {
				this.gameDetails.appDeploymentId = this.props.match.params.appDeploymentId
			}
			if (this.urlParams.appView) { // This code allows any stages[appRoute] render function to know what view it is forming (e.g. CONTROL or DEMO)
				this.state.appView = this.urlParams.appView
			}
		}

		this.route = 'STUDENT'

		this.stages = {}
		if (appSettings.stages) {
			for (var stageName in appSettings.stages[this.route]) {
				this.stages[stageName] = appSettings.stages[this.route][stageName].bind(this)
			}
		} else { console.log("Error: No stages defined in studentView") }

		this.header = {}
		if (appSettings.headers) {
			this.header = appSettings.headers[this.route].bind(this)
		} else {
			// TODO load default header
		}

		this.lifecycle = {}
		if (appSettings.lifecycle) {
			for (var lc in appSettings.lifecycle[this.route]) {
				this.lifecycle[lc] = appSettings.lifecycle[this.route][lc].bind(this)
			}
		}

		// Actions that can be initiated from the header bar in any app
		this.headerActions = {}
		this.headerActions.handleLogout = function () {
			clearTimeout(this.timerObj) // Stops periodic update
			this.props.logout()
			this.props.history.push('/login')
		}

		for (var headerAction in this.headerActions) {
			this.headerActions[headerAction] = this.headerActions[headerAction].bind(this)
		}

		this.googleResponse = googleAuthFns.googleResponse.bind(this)
		this.googleOnFailure = googleAuthFns.onFailure.bind(this)
	}
	componentDidMount() {
		//this.setState(this.state)
		this.periodicUpdate()

		if (this.config.noSockets === true) {
		} else {
			this.socket.open()
		}

		// Authentication flow

		if (this.props.user || this.state.user) {
			// We're here because we've authed the proper way... using a JWT token
			// Shoot out a single update to get the ball rolling
			this.sendInitialUpdate()
		}
		else if (this.props.public === true) {

			// If you're a public user but we've seen you before, we can probably revive your existing public user account
			if (this.state.clientId === undefined) {
				if (window.localStorage) { var clientId = window.localStorage.getItem('catembe_clientId') }
				if (clientId) { // We logged in before. Set clientId so it can get tacked to an update call
					this.setState({clientId: clientId}, () => {
						this.sendInitialUpdate() // Will send clientId along with it
					})
				} else {
					// Assign new clientId and log in
					this.setState({clientId: this.getClientId()}, () => {
						if (window.localStorage) {
							window.localStorage.setItem('catembe_clientId', this.state.clientId)
						}
						this.sendInitialUpdate() // Will send new clientId along with it
					})
				}
			}

			//this.publicUserSignup(null) // Auto-login
		}

		if (this.lifecycle && this.lifecycle.componentWillMount) { // Legacy
			this.lifecycle.componentWillMount()
		}
		if (this.lifecycle && this.lifecycle.componentDidMount) {
			this.lifecycle.componentDidMount()
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.lifecycle.componentDidUpdate) {
			this.lifecycle.componentDidUpdate(prevProps, prevState)
		}
	}
	componentWillUnmount() {
		clearTimeout(this.timerObj) // Stops periodic update
	}
	render() {

		//if (this.config.requirePublicNickname && !(this.state.user && (this.state.user.firstName || this.state.user.displayName || this.state.user.nickname)) ) {

		// We need to grab a nickname
		if (this.config.requirePublicNickname && (!this.state.user || !this.state.user.nickname)) {
			return (
				<div className={`studentAppContainer container`}>
				Nickname form
				</div>
			)
		}

		// We haven't introduced outselves to the server yet, and this is not a public app. We should show the google login button
		if (!this.props.user && !this.state.user) {
			if (this.props.public) {
				if (this.config.requireAuth === false) {
					// Do nothing
				} else {
					return ( <LoadingScreen/>)
				}
			} else {
				var header = this.header()

				return (
					<div className={"container-fluid"}>
					<div className="contentContainer studentContentContainer container">
					</div>
					</div>
				)
			}
		}
		// Otherwise just load the app!

		var content = this.stages[String(this.state.localStage)]()

		var header = ""
		if (this.config.studentHeader) {
			header = (<div className="headerContainer studentHeaderContainer">
				{this.header()}
				</div>)
		}
		else {
			header = <LiteStudentHeader
			lastApiCall={this.state.lastApiCall}
			lastApiError={this.state.lastApiError}
			apiErrorDetails={this.state.apiErrorDetails}
				/>
		}

		var containerClasses = "container-fluid"
		if (this.config.fullscreen) { containerClasses = "" }

		var contentClasses = "contentContainer studentContentContainer"
		if (!this.config.studentWidescreenView) { contentClasses += " container" }

		return (
			<div className={containerClasses}>
			{header}
			<div className={contentClasses}>{content}</div>
			</div>
		)

	}
}
export default StudentView;

