import React, { Component } from 'react'
import io from 'socket.io-client';
import { hotkeys } from 'react-keyboard-shortcuts'
import Fullscreen from "react-full-screen";
import { Icon } from 'react-icons-kit'
import {ic_keyboard_arrow_left} from 'react-icons-kit/md/ic_keyboard_arrow_left'
import {ic_keyboard_arrow_right} from 'react-icons-kit/md/ic_keyboard_arrow_right'
import './css/Display.css'

const config = require('./config/api');
const appBaseFunctions = require('./appBaseFunctions')

const socket = io(config.apiBaseUrl, {autoConnect: false})

class DisplayView extends Component {
   constructor(props) { 
      super(props) 

		// Install the 'with auth' apiCall function we get from upstairs
		if (this.props.apiCall) { // Use authenticated apiCall
			this.apiCall = this.props.apiCall
		}

		// Install all core functions
		for (var item in appBaseFunctions) {
			this[item] = appBaseFunctions[item].bind(this)
		}

      const appSettings =  Object.assign({}, this.props.appSettings)
      this.state = appSettings.initialState
      this.state.isFull = false

		this.socket = socket
		this.socket.on('updateState', data => {
			this.updateStateFromServer(data)
		})

      this.config = appSettings.config
      this.gameDetails = appSettings.gameDetails
      this.content = appSettings.content

      // Add in extra content if appConfig specified
      if (this.props.match && this.props.match.params) {
			if (this.props.match.params.appConfig) {
				this.appConfig = this.props.match.params.appConfig
				if (appSettings.appConfigs && appSettings.appConfigs[this.appConfig]) {
					this.content = Object.assign(this.content, appSettings.appConfigs[this.appConfig]) // Eventually we prol want to load this from API or a file
				}
			}
			if (this.props.match.params.appDeploymentId) {
				this.gameDetails.appDeploymentId = this.props.match.params.appDeploymentId
			}
      }

		this.lifecycle = {}
		if (appSettings.lifecycle) { 
			for (var lc in appSettings.lifecycle[this.route]) {
				this.lifecycle[lc] = appSettings.lifecycle[this.route][lc].bind(this)
			}
		}

      this.route = 'DISPLAY'
      this.stages = {}
      for (var stageName in appSettings.stages[this.route]) {
         this.stages[stageName] = appSettings.stages[this.route][stageName].bind(this)
      }
   }

   goFull = () => {
      this.setState({ isFull: true });
   }

   hot_keys = { // Left and right on display view will send CONTROL route actions (which require authentication)
      'left': { priority: 1, handler: (event) => this.sendControlAction('prevStage') },
      'pageup': { priority: 1, handler: (event) => this.sendControlAction('prevStage') },
      'right': { priority: 1, handler: (event) => this.sendControlAction('nextStage') },
      'pagedown': { priority: 1, handler: (event) => this.sendControlAction('nextStage') },
   }

   componentDidMount() {
      //this.setState(this.state)
      this.periodicUpdate()
		this.socket.open()

		if (this.props.user || this.state.user) {
			// We're here because we've authed the proper way... using a JWT token
			// Shoot out a single update to get the ball rolling
			this.sendUpdate()
		}
		else if (this.props.public === true) {

			// If you're a public user but we've seen you before, we can probably revive your existing public user account
			if (this.state.clientId === undefined) {
				if (window.localStorage) { var clientId = window.localStorage.getItem('catembe_clientId') }
				if (clientId) { // We logged in before. Set clientId so it can get tacked to an update call
					this.setState({clientId: clientId}, () => { 
						this.sendUpdate() // Will send clientId along with it
					})
				} else {
					// Assign new clientId and log in
					this.setState({clientId: this.getClientId()}, () => { 
						if (window.localStorage) {
							window.localStorage.setItem('catembe_clientId', this.state.clientId)
						}
						this.sendUpdate() // Will send new clientId along with it
					})
				}
			}

			//this.publicUserSignup(null) // Auto-login
		}

		if (this.lifecycle && this.lifecycle.componentWillMount) { // Legacy
			this.lifecycle.componentWillMount()
		}
		if (this.lifecycle && this.lifecycle.componentDidMount) {
			this.lifecycle.componentDidMount()
		}
   }
   componentWillUnmount() {
      clearTimeout(this.timerObj) // Stops periodic update
   }
   render() {
      const curStage = this.state.stage || 0
	  
	  var content = <span/>
	  if (this.stages[String(curStage)]) {
		  content = this.stages[String(curStage)]()
	  } else { // No single stage for us -- check for range-based stages
		  var rangeStages = Object.keys(this.stages).filter(x => x.indexOf(',') >= 0)
		  for (var sIdx in rangeStages) {
	 	      var [min, max] = rangeStages[sIdx].split(',')
			  if(max === '') { max = 9999 }
			  if (Number(curStage) >= Number(min) && Number(curStage) <= Number(max)) {
			      content = this.stages[rangeStages[sIdx]]()
			  }
		  }
	  }

      return (
         <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({isFull})} >
            { 0 && !this.state.isFull ? <div className="fsButton">
               <button className="btn btn-primary" onClick={this.goFull}> Go Fullscreen </button>
					
            </div> : <span/> }
            <div className={`displayAppContainer full-screenable-node`}>
					{ !this.config.hideDisplayArrows ?
						[<div key="prevAr" className="displayArrow _left" onClick={(event) => this.sendControlAction('prevStage')}><Icon size={42} icon={ic_keyboard_arrow_left}/></div>,
						<div key="nextAr" className="displayArrow _right" onClick={(event) => this.sendControlAction('nextStage')}><Icon size={42} icon={ic_keyboard_arrow_right}/></div>] : <span/> }
               <div className={`contentContainer controlContentContainer`}>
				  {content}
			   </div>
            </div>
         </Fullscreen>
      )
   }
}
export default hotkeys(DisplayView);
