import React, {Component} from 'react'
import update from 'immutability-helper'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from 'react-icons-kit'
import {ic_close} from 'react-icons-kit/md/ic_close'
import { ControlButton } from './control'
import {ChallengeHeader} from './challenge.js'
import {DonutChart} from './basic.js'
import {basicFunctions} from '../include/basic.js'

import '../css/Accounting.css'
const uuidv4 = require('uuid/v4')
const refEntryTypes = [null, {value: "dr", name:"Dr"}, {value: "cr", name: "Cr"}]

class JournalEntryChallengeInput extends Component {
   constructor(props) {
      super(props)
      this.updateJournalEntry = this.updateJournalEntry.bind(this)  
      this.addLine = this.addLine.bind(this)  
      this.removeLine = this.removeLine.bind(this)  
	}
   updateJournalEntry(change) {
		// change.txnId, change.lineId, change.item, change.value
		//console.log("update journal entry")
		var transactions = this.props.transactions
		for (var tIdx in transactions) {
			if(transactions[tIdx].id === change.txnId) {
				for (var lIdx in transactions[tIdx].lines) {
					if (transactions[tIdx].lines[lIdx].id === change.lineId) {
						transactions[tIdx].lines[lIdx][change.item] = change.value
					}
				}
			}
		}
		if (this.props.updateData) {
			this.props.updateData('transactions', transactions)
		}
   }
   addLine(transactionId) {
		var transactions = this.props.transactions
		for (var tIdx in transactions) {
			if(transactions[tIdx].id === transactionId) {
				transactions[tIdx].lines.push({id: uuidv4().substr(0, 8)})
			}
		}
		if (this.props.updateData) {
			this.props.updateData('transactions', transactions)
		}
   }
   removeLine(transactionId, lineIdToRemove) {
		var transactions = this.props.transactions
		var transIdxToAlter, lineIdxToRemove
		for (var tIdx in transactions) {
			if(transactions[tIdx].id === transactionId) {
				for (var lIdx in transactions[tIdx].lines) {
					if (transactions[tIdx].lines[lIdx].id === lineIdToRemove) {
						transIdxToAlter = tIdx
						lineIdxToRemove = lIdx
					}
				}
			}
		}
		if (transIdxToAlter !== undefined) {
			console.log(transIdxToAlter)
			console.log(lineIdxToRemove)
			transactions[transIdxToAlter].lines.splice(lineIdxToRemove, 1)
		}
		if (this.props.updateData) {
			this.props.updateData('transactions', transactions)
		}
   }
   render() {
		var items = []
      for (var tIdx in this.props.transactions) {
			const currentEntry = this.props.transactions[tIdx]

			items.push(<SingleJournalEntryInput
				currentQn = {this.props.currentQn}
				{...currentEntry}
				key = {'transaction_' + this.props.prefix + '_qn' + String(this.props.currentQn) + "_" + String(tIdx)}
				jeKey = {'transaction_' + this.props.prefix + '_qn' + String(this.props.currentQn) + "_" + String(tIdx)}
				correct = {this.props.correct}
				txnId = {currentEntry.id}
				onUpdate = {this.updateJournalEntry}
				addLine = {this.addLine}
				removeLine = {this.removeLine}
				accounts = {this.props.accounts}
				options = {this.props.options}
				displayMode = {this.props.displayMode}
				showErrors={this.props.showErrors}
			/>)
		}
		if (this.props.options && this.props.options.allowAddTransaction) {
			items.push(<button onClick={this.addTransaction}>Add Transaction</button>)
		}

		return items
	}
}


class SingleJournalEntryInput extends Component { // Single transaction
   render() {
      var lines = []
      for (var lIdx in this.props.lines) {
         const curLine = this.props.lines[lIdx]

         lines.push(
            <SingleJournalEntryInputLine
					{...curLine}
               accounts={this.props.accounts}
					key = { this.props.jeKey + "_line_" + String(curLine.id)}
					lineKey = { this.props.jeKey + "_line_" + String(curLine.id)}
               removeLine={this.props.removeLine}
					txnId = {this.props.txnId}
               lineId = {curLine.id}
               onUpdate = {this.props.onUpdate}
               disabled={this.props.displayMode ? null : curLine.disabled}
					displayMode={this.props.displayMode}
					options={this.props.options}
					showErrors={this.props.showErrors}
            />
         )
      }

      var classNames = "journalEntryInput"
      if (this.props.additionalClassNames) {
         classNames += " " + this.props.additionalClassNames
      }
		if (this.props.showErrors && this.props.correct) {
         classNames += " _correct"
		}

      return (
         <div className={classNames} key={this.props.currentQn + "_" + this.props.txnId}>
				<AnimatePresence>
					{lines}
				</AnimatePresence>
            { this.props.options && this.props.options.allowAddLine && this.props.addLine ?
               <button className="actionButton buttonGradient_blueGreen" onClick={this.props.addLine.bind(this, this.props.txnId)}>Add Line</button> : <span/> }
         </div>
      )
   }
}

class SingleJournalEntryInputLine extends Component { // Single transaction line (component debit or credit)
   constructor(props) {
      super(props)
      this.updateLine = this.updateLine.bind(this)  
   }
   updateLine(item, value) {
      this.props.onUpdate({item: item, value: value, lineId: this.props.lineId, txnId: this.props.txnId})
   }
   render() {
      var accountValue
      if (this.props.inputAccount) {
         accountValue = String(this.props.inputAccount).toLowerCase()
      }

      var accountOptions = []
		const accounts = Array(null).concat(this.props.accounts)
      for (var aIdx in accounts) {
			var selected = false
			const account = accounts[aIdx]
			if (!account) {
				accountOptions.push(<option disabled selected={!accountValue} key={this.props.lineKey + 'accDef'}>Account</option>)
			}
			else {
				accountOptions.push(
					<option value={account.name.toLowerCase()} selected={accountValue === account.name.toLowerCase()} key={this.props.lineKey + 'acc' + aIdx}>{account.name}</option>
				)
			}
      }

		var entryTypes = []
		for (var etIdx in refEntryTypes) {
			var selected = false
			const entry = refEntryTypes[etIdx]
			if (!entry) {
				if (!this.props.inputEntryType) { selected = true }
				entryTypes.push(<option disabled selected={selected} key={this.props.lineKey + 'etDef'}></option>)
			}
			else {
				if (this.props.inputEntryType === entry.value) { selected = true }
				entryTypes.push(<option value={entry.value} selected={selected} key={this.props.lineKey + 'et' + etIdx}>{entry.name}</option>)
			}
		}

		var errors = {}
		if (this.props.errors && this.props.showErrors) {
			errors = this.props.errors
		}

	   var inputAmount = this.props.inputAmount
	   if (inputAmount !== 0 && !inputAmount) {
		   inputAmount = ""
	   }

      return (
         <motion.div className={"journalEntryInputLine " + (errors.all ? "_error" : "")}
				animate={{ scale: [0, 1.1, 1], opacity: [0, 1, 1] }}
				transition={{ duration: 0.3 }}
				initial={{ scale: 0, opacity: 0 }}
				exit={{ translateX: -500, opacity: 0 }}>
            <select name="inputEntryType" className={"inputEntryType " + (errors.inputEntryType ? "_error" : "")} disabled={this.props.disabled}
               onChange={(e) => {this.updateLine("inputEntryType", e.target.value)}}>
					{entryTypes}
            </select>
            <select name="inputAccount" className={"inputAccount " + (errors.inputAccount ? "_error" : "")} disabled={this.props.disabled}
               onChange={(e) => {this.updateLine("inputAccount", e.target.value)}}>{accountOptions}</select>
            <input name="inputAmount" className={"inputAmount " + (errors.inputAmount ? "_error" : "")} placeholder="Amount" disabled={this.props.disabled} autoComplete="off"
               onChange={(e) => {this.updateLine("inputAmount", e.target.value)}} value={this.props.displayMode ?  parseFloat(inputAmount).toLocaleString() : inputAmount}/>
            { this.props.options.allowRemoveLine && !this.props.disabled && this.props.removeLine ?
            <Icon size={24} className="removeLineIcon" icon={ic_close} value={this.props.lineIdx} onClick={this.props.removeLine.bind(null, this.props.txnId, this.props.lineId)}/>
               : <span/> }
         </motion.div>
      )
   }
}

class JournalEntryWorksheet_ControlPanel extends Component {
   constructor(props) {
      super(props)
   }
   render() {
      var results = []
      for (var rIdx in this.props.results) {
         var r = this.props.results[rIdx]
         results.push(
            <div className="row controlResultRow">
               <div className="col">
                  <SingleJournalEntryInput
                     key={String(this.props.currentQn) + String(rIdx)}
                     entryIdx = {String(this.props.currentQn) + String(rIdx)}
                     currentEntry = {r.entry}
                     accounts = {this.props.data.accounts}
                     additionalClassNames = { r.correct ? "correctAnswer" : "studentAnswer"}
                     disabled = {true}
                  />
               </div>
               <div className="col">
                  <div className="largeNum">{(r.score * 100).toFixed(0)}%</div>
                  <div className="smallCapsHeading">Correct</div>
               </div>
               <div className="col">
                  <div className="largeNum">{(r.nProportion * 100).toFixed(0)}%</div>
                  <div className="smallCapsHeading">Of responses ({r.n})</div>
               </div>
            </div>)
         
      }
      return (
         <div>
            <div>Current Question: {parseInt(this.props.currentQn) + 1}</div>
            <div className="row">
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('prevQn') }}>Previous question</ControlButton>
               </div>
               <div className="col">
                  <ControlButton onClick={() => { this.props.sendAction('nextQn') }}>Next question</ControlButton>
               </div>
            </div>
            <div>
               {results}
            </div>
         </div>)
   }
}

class JournalEntries_LiveDisplayView extends Component {
   constructor(props) { 
		super(props) 
		this.state = {
			showOptions: false,
			showResults: false,
		}

	}
	showResults = () => {
		this.setState({showResults: true})
		this.props.sendControlAction('showResults')

	}
   render() {

		if (!this.props.challengeActive) {
			return (
				<div className="container">
					<div className="row">
						<div className="col text-center" style={{margin: '0 10%', marginTop: '35vh', fontSize: '60px'}}>
							<button className="actionButton buttonGradient_blueGreen" onClick={() => { this.props.sendControlAction('enableChallenges') }}>Start the challenge!</button>
						</div>
					</div>
				</div>
			)
		}

		var challengeInitialState = {options: {}}
		if (this.props.challenge && this.props.challenge.state) {
			challengeInitialState = this.props.challenge.state
			// Override these for the display version
			challengeInitialState.options.allowAddLine = false
			challengeInitialState.options.allowAddTransaction = false
			challengeInitialState.options.allowRemoveLine = false
		}

		var results = this.props.results
		if (this.props.results && this.state.showOptions && !this.state.showResults) { 
			// We're showing options but not correct answers, randomise the order
			var seeds = results.map(result => result.hash)
			results = basicFunctions.shuffle(this.props.results, seeds)
		}
	
		var total_n = 0
		if (results) {
			//total_n = results.reduce((a, b) => (a.n + b.n))
			for (var rIdx in results) {
				total_n += results[rIdx].n
			}
			if (total_n < results.length) {
				total_n = results.length // Fudge the number if all answers are incorrect to avoid giving away the fact that nobody got it
			}
		}

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-5">
						{ this.props.challenge ?
							<ChallengeHeader
								{...this.props.challenge}
								currentQn={this.props.currentQn}
								challengeStarted={this.props.challengeStarted}
								showTimeLeft={!this.state.showResults}
								/> : <span/> }

						{ this.props.challenge ?
							<JournalEntryChallengeInput
								{...this.props.challenge.state}
								currentQn={this.props.currentQn}
								/> : <span/> }

					</div>

					<div className="col-7 text-center">
						<div className="container studentAnswersContainer">
							<div className="row">
								<div className="col text-center">
									<h2>Student Responses</h2>
								</div>
							</div>
							{  results && this.state.showOptions ? (results.map((result, idx) => {
									return (
									<motion.div positionTransition className={"row studentAnswerRow " + (result.correct ? "correct" : "")} key={"studentRow_"+result.hash}>
										<div className="_lhs">
											<JournalEntryChallengeInput
												accounts = { this.props.challenge.state.accounts }
												options = { this.props.challenge.state.options }
												{...result}
												currentQn={this.props.currentQn}
												prefix={"studentResponse" + idx}
												displayMode={true}
												showErrors={this.state.showResults}
											/>
										</div>
										<div className="_rhs">
											<DonutChart x={result.nProportion} zeroData={!this.state.showResults}/>
										</div>
									</motion.div>
									)
								})) : <span/>
							}
							{ !this.state.showOptions ? 
									<motion.button
										type="button" 
										whileTap={{ scale: 0.9 }}
										transition={{ duration: 0.1 }}
										disabled={total_n === 0}
										className="btn actionButton buttonGradient_blueGreen mt-5"
										onClick={() => { this.setState({showOptions: true})}}>
										{ total_n > 0 ? 
											( total_n === 1 ? <span>Show 1 response</span> :
												<span>Show {total_n} responses</span> 
											): <span>Waiting for responses...</span> }
									</motion.button> : <span/>
							}
							{ (this.state.showOptions && !this.state.showResults) ? 
									<motion.button
										type="button" 
										whileTap={{ scale: 0.9 }}
										transition={{ duration: 0.1 }}
										className="btn actionButton buttonGradient_blueGreen m-5"
										onClick={ this.showResults }>Show Results
									</motion.button> : <span/>
							}
						</div>
					</div>
				</div>

			</div>
		)
	}
}

export { JournalEntryWorksheet_ControlPanel, JournalEntryChallengeInput, JournalEntries_LiveDisplayView }
