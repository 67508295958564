import { render } from 'react-dom'
import React, { useState, Component } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { Textfit } from 'react-textfit'
import { ControlButton } from './control.js'
import {FeedbackBox} from './basic.js'
import '../css/Cards.css'

var seedrandom = require('seedrandom')

const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -500 }) // Starting position (off top of screen)
const to = i => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 }) // Position on pile

// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) => `perspective(1500px) rotateX(5deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

function Deck({ onSwipe, cards, mode, entryAnimation }) {

	if (cards.length === 0) {
		return (<div>No cards to display</div>)
	}

	const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
	const [props, set] = useSprings(cards.length, i => ({ ...to(i), from: entryAnimation ? from(i) : null })) // Create a bunch of springs using the helpers above

	// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
	const bind = useGesture(({ args: [index], down, delta: [xDelta, yDelta], distance, direction: [xDir, yDir], velocity }) => {
		//console.log('xDelta: ' + String(xDelta))
		//console.log('yDelta: ' + String(yDelta))

		const curCard = cards[index]
		if (curCard.mode !== undefined) { // mode can be global or card-specific
			mode = curCard.mode
		}			
		if (mode === undefined) {
			mode = 'lr'
		}
		
		var trigger = velocity > 0.5 // If you flick hard enough it should trigger the card to fly out

		var dir
		if (mode === 'two_dim') {
			dir = [xDir < 0 ? -1 : 1, yDir < 0 ? -1 : 1]
			distanceFromHome = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(yDelta, 2))
		}
		else {
			var relevantDirection = xDir
			var distanceFromHome = xDelta
			if (mode === 'ud') {
				relevantDirection = yDir
				distanceFromHome = yDelta
			}

			dir = relevantDirection < 0 ? -1 : 1 // Direction should either point left or right

		}

		if (!trigger && Math.abs(distanceFromHome) > 100) { // If they drop the card more than 100px from 'home', trigger swipe
			trigger = true

			// Override direction from position, not direction of velocity
			if (mode == 'two_dim') {
				dir = [xDelta < 0 ? -1 : 1, yDelta < 0 ? -1 : 1]
			} else {
				dir = distanceFromHome < 0 ? -1 : 1 
			}
		}

		if (!down && trigger) {
			gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
	
			if (mode === 'two_dim') {
				if (dir[0] == -1 && dir[1] == -1) {
					onSwipe('leftUp')
				} else if (dir[0] == -1)  {
					onSwipe('leftDown')
				} else if (dir[0] == 1 && dir[1] == -1) {
					onSwipe('rightUp')
				} else {
					onSwipe('rightDown')
				}

			} else {
				if (dir < 0 && mode === 'lr') {
					onSwipe('left')
				} else if (dir < 0 && mode === 'ud') {
					onSwipe('up')
				} else if (mode == 'lr') {
					onSwipe('right')
				} else {
					onSwipe('down')
				}
			}
		}

		set(i => {
			if (index === i) { // We're only interested in changing spring-data for the current spring
				const isGone = gone.has(index)

				var x = 0, y = 0, delta = 0

				if (mode === 'lr') {
					delta = xDelta
					x = isGone ? (300 + window.innerWidth) * dir : down ? delta : 0 // Card takes flight in X
				}
				else if (mode === 'ud') {
					delta = yDelta
					y = isGone ? (300 + window.innerWidth) * dir : down ? delta : 0 // Card takes flight in Y
				} 
				else if (mode === 'two_dim') {
					delta = Math.sqrt(xDelta*xDelta + yDelta*yDelta)
					x = isGone ? (300 + window.innerWidth) * dir[0] : down ? xDelta : 0 // Card takes flight in X
					y = isGone ? (300 + window.innerWidth) * dir[1] : down ? yDelta : 0 // Card takes flight in Y
				}
				const rot = delta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
				const scale = down ? 1.1 : 1 // Active cards lift up a bit
				return { x, y, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
			} else { // Other springs
				return
			}
		})

		//if (!down && gone.size === cards.length) setTimeout(() => gone.clear() || set(i => to(i)), 600)
	})

	// Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
	return props.map(({ x, y, rot, scale }, i) => (
		<animated.div className="cardContainer" key={cards[i].text + i} style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}>
		{/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
			<animated.div {...bind(i)} style={{ transform: interpolate([rot, scale], trans) }}>
				<div className={'cardInner' + (cards[i].img ? ' img' : '')}>
					<div>
					{ cards[i].img ? 
							<img className="img-fluid" onDragStart={(e) => { e.preventDefault() }} src={cards[i].img}/> :
							<span>{cards[i].text}</span> }
					</div>
				</div>
			</animated.div>
		</animated.div>
	))
}

class EmptyDeck extends Component {
	render() {
		return (
			<animated.div className="cardContainer" key="empty">
				<animated.div>
					<div className='cardInner'>
						<div>
							<h3 className='mb-4'>{this.props.title}</h3>
							<p className='mb-4' style={{fontSize: '16px', lineHeight: '130%'}}>To play, swipe each card towards the correct category.</p>
							<button type="button" className="btn actionButton playAgainButton buttonGradient_blueGreen"
								onClick={() => {this.props.onStart()}}>Start game</button>
							
						</div>
					</div>
				</animated.div>
			</animated.div>
		)
	}
}

class CardResponses extends Component {
   //constructor(props) { super(props) this.state = {} }
	render() {
		if (this.props.lastSwipe && this.props.lastSwipe.response) {
			const p = this.props.lastSwipe.points
			return (
				<div key="response" className={'cardResponse ' + this.props.mode + ' ' + this.props.lastSwipe.direction}>
					{this.props.lastSwipe.response} {p >= 0 ? <span>({ p > 0 ? "+" : ""}{p} points)</span> : <span/>}
				</div>)
		}
	}
}

class CardDestinations extends Component {
   //constructor(props) { super(props) this.state = {} }
	renderDestContent = (cat, lastSwipe) => {
		if (cat.img) {
			var img = cat.img

			// Show correct/incorrect version of destination, if available
			if (lastSwipe && lastSwipe.category === cat.id && lastSwipe.points >= 0) {
				if (cat.img_positive && lastSwipe.points > 50) {
					img = cat.img_positive
				} else if (cat.img_negative && lastSwipe.points === 0) {
					img = cat.img_negative
				}
			}
			return (<img src={img}/>)
		} 
		return (<span>{cat.name}</span>)
	}

	render() {
		var cats = this.props.categories
		var destinations = []
		if (this.props.curCard) {

			if (this.props.curCard.leftUp !== undefined && cats[this.props.curCard.leftUp]){
				destinations.push(<div key="leftUp" className={'cardDestination leftUp ' + this.props.mode}>{this.renderDestContent(cats[this.props.curCard.leftUp], this.props.lastSwipe)}</div>)
			}
			if (this.props.curCard.rightDown !== undefined && cats[this.props.curCard.rightDown]) {
				destinations.push(<div key="rightDown" className={'cardDestination rightDown ' + this.props.mode}>{this.renderDestContent(cats[this.props.curCard.rightDown], this.props.lastSwipe)}</div>)
			}
			if (this.props.mode === 'two_dim') {
				if (this.props.curCard.leftDown !== undefined && cats[this.props.curCard.leftDown]){
					destinations.push(<div key="leftDown" className={'cardDestination leftDown ' + this.props.mode}>{this.renderDestContent(cats[this.props.curCard.leftDown], this.props.lastSwipe)}</div>)
				}
				if (this.props.curCard.rightUp !== undefined && cats[this.props.curCard.rightUp]) {
					destinations.push(<div key="rightUp" className={'cardDestination rightUp ' + this.props.mode}>{this.renderDestContent(cats[this.props.curCard.rightUp], this.props.lastSwipe)}</div>)
				}
			}
		}

		destinations.push(
			<AnimatePresence key="img">
				{ this.props.lastSwipe && this.props.lastSwipe.card && this.props.lastSwipe.card.img ?
					<motion.div 
						initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
						key="destImg" className={'lastCard ' + this.props.lastSwipe.direction + " " + this.props.mode}>
						<img className="img-fluid" src={this.props.lastSwipe.card.img}/>
					</motion.div> : <span/> }
			</AnimatePresence>)

		return (destinations)
	}
}

class GameFinished extends Component {
	render() {
		const r = this.props.results
		
		var timerEnabled = false
		var timeLeft = 0
		if (r && r.timeLeft !== null && r.timeLeft !== undefined) {
			timerEnabled = true
			timeLeft = r.timeLeft.toFixed(2)
		}

		var reportCorrect = false
		if (r && r.numCorrect !== undefined) {
			reportCorrect = true
		}

		return (
			<div className="gameFinished">
				<div className="container gameCompleteBox">
					<div className="row justify-content-center">
						<div className="col">
							<div className="smallCapsHeading text-center _gameOver mb-4">GAME OVER</div>
							<div className="container text-center mb-4">
								<div className="row mb-3">
									{ reportCorrect ? <div className="col _label">Cards correct</div> : <span/> }
									{ timerEnabled ? <div className="col _label">Time left</div> : <span/> }
								</div>
								<div className="row mb-3">
									{ reportCorrect ? 
										<div className="col _value bigNumber">
											<motion.div animate={{scale: [1, 1.2, 1]}} transition={{ duration: 0.3, times: [0, 0.3, 1] }}>
												{r.numCorrect} / {r.numCards}
											</motion.div>
										</div> : <span/> }
									{ timerEnabled ? 
										<div className="col _value bigNumber">
											<motion.div animate={{scale: [1, 1.2, 1]}} transition={{ duration: 0.3, times: [0, 0.3, 1] }}>
												{timeLeft}s
											</motion.div>
										</div> : <span/> }
								</div>
								<div className="row mb-3">
									{ reportCorrect ? <div className="col bigNumberSubtitle"><span className="pointsPill">{r.correctPoints}</span> points</div> : <span/> }
									{ timerEnabled && r.timeLeftPoints >= 0 ? <div className="col bigNumberSubtitle">+<span className="pointsPill">{r.timeLeftPoints}</span> bonus points</div> : <span/> }
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col">
							<div className="finalScore container">
								<div className="row mb-3">
									<div className="col _label text-left">Your score</div>
									<div className="col _value text-right">{r ? r.pointsTotal : 0} points</div>
								</div>
								{ r && r.pointsHighScore !== undefined ? 
									<div className="row mb-3">
										<div className="col _label text-left">Your high score</div>
										<div className="col _value text-right">{r.pointsHighScore} points</div>
									</div> : <span/> }
								{ r && r.overallHighScore !== undefined ? 
									<div className="row mb-3">
										<div className="col _label text-left">Class high score</div>
										<div className="col _value text-right">{r.overallHighScore} points</div>
									</div> : <span/> }
							</div>
						</div>
					</div>
				{ this.props.liveMode === true ? <span/> :
					<div className="row justify-content-center">
						<div className="col">
							<motion.button
								type="button" 
								whileTap={{ scale: 0.9 }}
								transition={{ duration: 0.1 }}
								className="btn actionButton playAgainButton buttonGradient_redYellow"
								onClick={() => {this.props.onReset()}}>Play Again
							</motion.button>
						</div>
					</div> }
				{ this.props.liveMode ?  <FeedbackBox onChange={this.props.sendFeedback}/> : <span/> }
				</div>
			</div>

		)
	}
}
class LiveCardDisplay extends Component {
   //constructor(props) { super(props) this.state = {} }
	render() {

		var categories = []
		var totalResponses = 0

		var sortedCats = []
		for (var c in this.props.categories) {
			if (this.props.studentResponseSummary[c]) {
				totalResponses += this.props.studentResponseSummary[c] 
				sortedCats.push([c, this.props.studentResponseSummary[c]])
			}
			else {
				sortedCats.push([c, 0])
			}
		}
		
		var numUnseenResponses = 0
		if (this.props.showStudentResponses) {
			sortedCats = sortedCats.sort((a, b) => b[1] - a[1])
			if (this.props.numStudentResponses) {
				numUnseenResponses = this.props.numStudentResponses - totalResponses
			}
		}
		else {
			numUnseenResponses = this.props.numStudentResponses
		} 

		for (var cIdx in sortedCats) {
			const catId = sortedCats[cIdx][0]
			const catResponses = sortedCats[cIdx][1]
			const personaResponse = this.props.categories[catId].response
			const personaPoints = this.props.categories[catId].points

			var img = this.props.refCategories[catId].img
			if (this.props.showAnswers && personaPoints >= 0) {
				if (this.props.refCategories[catId].img_positive && personaPoints > 50) {
					img = this.props.refCategories[catId].img_positive
				} else if (this.props.refCategories[catId].img_negative && personaPoints === 0) {
					img = this.props.refCategories[catId].img_negative
				}
			}

			categories.push(
				<motion.div positionTransition className="displayCategory" key={this.props.deckId + "_" + catId}>
					<div className="row">
						<div className="_name text-left col-6">
							<img className="img-fluid" src={img}/>
							<div>{this.props.categories[catId].name}</div>
						</div>
						<div className="_responses col-6">
							{ this.props.showStudentResponses  ? 
								<div className="resultBar">
									<div className="resultBarInner" style={{width: (totalResponses > 0 ? 100 * (catResponses / totalResponses) : 0) + "%"}}></div>
									<div className="resultBarLabel">{catResponses}</div> 
								</div> : <span/> }
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							{ this.props.showAnswers & personaResponse !== undefined ? <div className="cardResponse _display two_dim leftUp">{personaResponse} {personaPoints >= 0 ? <span>({personaPoints} points)</span> : <span/>}</div> : <span/> }
						</div>
					</div>
				</motion.div>)
		}

		var cards = []
		for (var i in this.props.cards) {
			var rng = seedrandom(this.props.cards[i].text)
			const randomDeg = (rng() * 4) - 2
			cards.push(
			<div className="cardContainer" key={this.props.cards[i].text + i} style={{position: 'relative', width: 'auto', height: 'auto'}}>
				<div style={{transform: 'rotate(' + String(randomDeg) + 'deg)'}}>
					<div className={'cardInner' + (this.props.cards[i].img ? ' img' : '')}>
						<div>
						{ this.props.cards[i].img ? 
								<img className="img-fluid" src={this.props.cards[i].img}/> :
								<span>{this.props.cards[i].text}</span> }
						</div>
					</div>
				</div>
			</div>)
		}

		return (
			<div className="container-fluid">
				<div className="studentInstructionsHeaderBar">Join using URL: catem.be/livestp</div>
				<div className="row">
					<div className="col-4">
						<div className="displayCards">
							<div className="smallCapsHeading text-center mt-5 mb-4">Current Card</div>
							{cards}
						</div>
						{ this.props.gameFinished === true ? <div className="gameCompleteBox">
							<div className="smallCapsHeading text-center _gameOver mb-4">GAME OVER</div>
						</div> : <span/> }
					</div>
					<div className="col-8">
						<div className="displayCategories container" key={this.props.deckId}>
							<div className="_buttons row p-3">
								<div className="col">
									<ControlButton onClick={() => { this.props.sendControlAction('showStudentResponses') }}>Show Student Responses ({numUnseenResponses})</ControlButton>
								</div>
								<div className="col">
									<ControlButton onClick={() => { this.props.sendControlAction('showAnswers') }}>Show Answers</ControlButton>
								</div>
							</div>
							<div>
								{categories}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export { Deck, EmptyDeck, CardDestinations, GameFinished, CardResponses, LiveCardDisplay }
