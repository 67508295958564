import React from 'react'
import { FrontendAppSettings } from './FrontendAppSettings'

const gameDetails = {
	gameId: 'breakeven', // Unique identifier for this game
	appRoute: 'breakeven', // Determines url of app
	title: "Breakeven",

	discipline: "Economics", // Shown on frontpage tile and product page
	veryShortTitle: "Breakeven", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Breakeven", // Used as interactive name on FrontPage
	shortDescription: "A simple trading platform for learning economics concepts", // Description on FrontPage
	isNew: true,
	longDescription: [
		<p>Breakeven is a real-time trading platform, designed for economics students. Teach concepts such as supply and demand, comparative advantage, monopoly/ogilopoly, elasticity, and much more.</p>,
		<p>Students learn first-hand from their own experience in a fun and engaging platform that includes rewards and customisable elements.</p>
	],
	ctaExtra: (<div className="alert alert-warning mb-5"><strong>Breakeven</strong> is currently in private beta, with opportunities for a limited number of schools to pilot it. If your school is interested in participating, please complete the form below!</div>),

	features: [
		{
			heading: "Simple and powerful",
			description: "Students buy and sell emoji on a powerful, real-time trading platform, designed solely for education. Can be accessed on any smartphone, tablet, or computer; in-person or remotely.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Breakeven/Feature1.svg",
		},
		{
			heading: "Bite-sized activities",
			description: "Comprehensive range of pre-configured 10-30 minute activities, each designed to teach a single topic. Includes a lesson plan and interactive debrief slides, which explain the theories involved and summarise trading outcomes.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Breakeven/Feature2.svg",
		},
		{
			heading: "Fun and engaging",
			description: "Engage students with social and game elements, such as a news feed, teams/groups, personalised avatars, and rewards.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Breakeven/Feature3.svg",
		},
		{
			heading: "Advanced capabilities",
			description: "Incorporate auctions, labour markets, manufacturing of goods, and derivative securities into activities.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Breakeven/Feature4.svg",
			comingSoon: true,
		},
	],

	topics: {
		simple: {
			title: 'Gains from trade',
			longDescription: (
				<div>
					<p>A fast-paced trading game that allows students to trade freely, demonstrating the concept of scarcity and the benefits of trade.</p>
					<p>All students start with a random assortment of emoji. The aim of the game is to assemble a set of valuable combinations &#8211; e.g. five-of-a-kind, or one of each type &#8211; each worth a certain number of points based on scarcity.</p>
					<p>Students then trade emoji with each other to assemble their desired combinations, with the aim of reaching the highest score in the class!</p>
				</div>
			),
			videoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Breakeven_Combos.mp4',
		},
		sd: {
			title: 'Supply and demand',
			longDescription: (
				<div>
					<p>This activity is a digital take on the experiments conducted by <a href="https://www.journals.uchicago.edu/doi/abs/10.1086/256654?journalCode=jpe">Chamberlin (1948)</a> and <a href="https://www.journals.uchicago.edu/doi/abs/10.1086/258609?journalCode=jpe">Smith (1962)</a>.</p>
					<p>Students are assigned roles of buyers or sellers, and given price limits. They trade by making offers or accepting the offers of others.</p>
					<p>Immediately after, numerous interactive charts are available to analyse the data generated and confirm whether an efficient outcome was achieved, as predicted by the theory!</p>
					<p>An <a href="/app/sd-explainer">interactive explainer</a> is also available for this topic.</p>
				</div>
			),
			videoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Breakeven/Breakeven_Activity_Video_sd.mp4',
			videoType: 'no_phone',
		},
	},

	videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Breakeven.mp4", // Video on frontPage and Product page
	//asyncDemoUrl: "/app/public/je/async_demo/je_demo/student",
	//liveMode: { },
}

var breakeven = new FrontendAppSettings(gameDetails)

export { breakeven }
