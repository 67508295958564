var config = require('../config/api');
const seedrandom = require('seedrandom');

const basicFunctions = {
	formatTime: function(sec) {
		const minsNum = Math.floor(sec / 60)
		const mins = ('0'+minsNum).slice(-2)

		var secs
		if (minsNum === 0) {
			secs = ('0'+Math.floor(sec)).slice(-2)
		} else {
			secs = ('0'+Math.floor((sec) % (60 * minsNum))).slice(-2)
		}
		return [mins, secs]
	},
	sleep: function(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	},
	apiCall: function(data, callback, errorCallback) { 
		// TODO rename from very generic sounding apiCall to sendContact or something
		var payloadJson
		try {
			payloadJson = JSON.stringify(data)
		} catch(e) {
			console.log(e)
			return
		}

		return fetch(config.apiBaseUrl + 'contact', {
			method: 'post',
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			body: payloadJson,
		})
		.then( response => response ? response.json() : null )
		.then( (data) => {
			if(data) {
				callback(data)
			}
		})
		.catch( error => {
			console.log("Unhandled API error")
			console.log(error)
			if (errorCallback) {
				errorCallback()
			}
		})
	},
	shuffleArray: function (array) {
		  var currentIndex = array.length, temporaryValue, randomIndex;

		  // While there remain elements to shuffle...
		var newArray = JSON.parse(JSON.stringify(array))
		  while (0 !== currentIndex) {

			  // Pick a remaining element...
			  randomIndex = Math.floor(Math.random() * currentIndex);
			  currentIndex -= 1;

			  // And swap it with the current element.
			  temporaryValue = newArray[currentIndex];
			  newArray[currentIndex] = newArray[randomIndex];
			  newArray[randomIndex] = temporaryValue;
			}

		  return newArray
	},
	shuffleSeeded: function (ar, seed){
		var seedNums = []
		for( var sIdx in seed) {
			var myrng = seedrandom(seed[sIdx])
			seedNums.push(myrng.quick())
		}

		var numbers = [];
		for( var a = 0, max = ar.length; a < max; a++){
			    numbers.push(a);
		}
		var shuffled = [];
		for( var i = 0, len = ar.length; i < len; i++ ){
			    var r = parseInt(seedNums[i] * (len - i));
			    shuffled.push(ar[numbers[r]]);
			    numbers.splice(r,1);
		}
		return shuffled;
	},
}
export { basicFunctions }
