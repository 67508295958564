import React, { Component } from 'react'
import {BasicControlView} from './components/control.js'
//import appSettings from './apps/mi'
//const coreFunctions = require('./coreFunctions')
const appBaseFunctions = require('./appBaseFunctions')

class ControlView extends Component {
   constructor(props) { 
      super(props) 
      const appSettings =  this.props.appSettings

		// Install the 'with auth' apiCall function we get from upstairs
		if (this.props.apiCall) { // Use authenticated apiCall
			this.apiCall = this.props.apiCall
		}

		// Install all core functions
		for (var item in appBaseFunctions) {
			this[item] = appBaseFunctions[item].bind(this)
		}

      this.state = appSettings.initialState
      this.config = appSettings.config
      this.gameDetails = appSettings.gameDetails
		this.content = appSettings.content

      if (this.props.match && this.props.match.params) {
			this.urlParams = this.props.match.params
			if (this.urlParams.appDeploymentId) {
				this.gameDetails.appDeploymentId = this.urlParams.appDeploymentId
			}
	  		if (this.urlParams.appView) { // This code allows any stages[appRoute] render function to know what view it is forming (e.g. CONTROL or DEMO)
				this.state.appView = this.urlParams.appView
			}
			if (this.urlParams.appConfig) {
				this.appConfig = this.urlParams.appConfig
				if (appSettings.appConfigs && appSettings.appConfigs[this.appConfig]) {
					this.content = Object.assign(this.content, appSettings.appConfigs[this.appConfig]) // Eventually we prol want to load this from API or a file
				}
			}
      }

      this.route = 'CONTROL'

		// App has specified a custom CONTROL stage. Great! Use it.
		if (appSettings.stages[this.route]) {
			this.renderSingleStage = appSettings.stages[this.route].bind(this)
		} 
		else { // Otherwise use the default one.
			this.renderSingleStage = function() {  
				return (<BasicControlView
							appState = {this.state}
							sendAction = {this.sendAction}
							stages = {this.gameDetails.stageNames} />)
			}
		}
   }
   componentDidMount() {
      //this.setState(this.state)
	  this.sendUpdate()
      this.periodicUpdate()
   }
   componentDidUnmount() {
      clearTimeout(this.timerObj) // Stops periodic update
   }
   render() {
      var content = this.renderSingleStage()
		if (this.props.demo === true) {
			return (<div className={`controlAppContainer container-fluid`}>
					<div className={`contentContainer controlContentContainer container`}>{content}</div>
				</div>
				)

		}

      return (<div className={`controlAppContainer container-fluid`}>
            <div className={`headerContainer controlHeaderContainer`}>Control view</div>
            <div className={`contentContainer controlContentContainer container`}>{content}</div>
         </div>
         )
   }
}
export default ControlView;

