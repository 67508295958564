import React, { Component } from 'react'
import {Helmet} from "react-helmet"

class BreakevenSdExplainer extends Component {
   constructor(props) {
      super(props)

		// Redirect to breakeven website XXX
		window.location = "https://www.breakeven.app/explainer/sd/"
   }

	render() {

		return (
			<div style={{backgroundColor: '#fff'}}>
				<Helmet>
					<title>Supply & Demand</title>
					<meta name="description" content="An interactive explainer, from first principles." />
				</Helmet>
			</div>
		)
	}
}
export default (BreakevenSdExplainer)
