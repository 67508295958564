//import update from 'immutability-helper'
const oppEntry = {dr: 'cr', cr: 'dr'}

var basicFunctions = {
   getEntryType: function(account) {
      const type = account.type.substr(-1)
      if (type === undefined || account.proposedChange_amount === undefined) {
         return null
      }
      if (type === 'a') {
         if (account.proposedChange_amount > 0) { return 'dr' }
         else if (account.proposedChange_amount < 0) { return 'cr' }
      }
      else {
         if (account.proposedChange_amount > 0) { return 'cr' }
         else if (account.proposedChange_amount < 0) { return 'dr' }
      }
      return null
   },
   getBalanceType: function(account) {
      const type = account.type.substr(-1)
      if (type === undefined || account.value === undefined) {
         return null
      }
      if (type === 'a') {
         if (account.value >= 0) { return 'dr' }
         else { return 'cr' }
      }
      else {
         if (account.value >= 0) { return 'cr' }
         else { return 'dr' }
      }
   },
   formatChange: function(n) {
      if (n > 0) {
         return "+" + this.formatBalance(n)
      }
      return this.formatBalance(n)
   },
   formatAbsChange: function(n) {
      return this.formatBalance(Math.abs(n))
   },
   formatBalance: function(n) {
      var out = ""
      if (n < 0) {
         out += "-"
      }
      const abs_n = Math.abs(n)
      const final_n = abs_n.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      out += "$" + final_n
      return out
   },
   processJournalEntries(inputEntries) {
      
      var entries = {dr: [], cr: []}
      var totals = {dr: 0, cr: 0}
      for (var eIdx in inputEntries) {
         const entry = inputEntries[eIdx]
         if (entry.entryType && entries[entry.entryType]) {
            entries[entry.entryType].push(entry) 
            totals[entry.entryType] += Math.abs(entry.proposedChange_amount)
         }
      }
      
      var output = []
      for (var entryType in entries) {
         entries[entryType].sort((a, b) => parseFloat(Math.abs(b.proposedChange_amount)) - parseFloat(Math.abs(a.proposedChange_amount))) // Sort descending
         for (var eIdx in entries[entryType]) {
            var entry = entries[entryType][eIdx]
            if (entries[oppEntry[entryType]].length === 1) {
               entry.otherAcct = entries[oppEntry[entryType]][0].name
            }
            else if (entries[oppEntry[entryType]].length === 2) {
               entry.otherAcct = entries[oppEntry[entryType]][0].name + " / " + entries[oppEntry[entryType]][1].name
            }
            else {
               entry.otherAcct = "Multiple accounts"
            }
            output.push(entry)
         }
      }
      return output
   },
   getCurrentTransaction: function(accounts) {
      var curTransaction = []
      for (var accType in {a: 0, l: 0, e: 0}) {
         for (var aIdx in accounts[accType]) {
            const acc = accounts[accType][aIdx]
            if (!acc.subAccounts && acc.proposedChange_amount !== undefined && acc.proposedChange_amount !== 0) {
               acc.entryType = this.getEntryType(acc)
               curTransaction.push(acc)
            }
            else if (acc.subAccounts) {
               for (var saIdx in acc.subAccounts) {
                  const sAcc = acc.subAccounts[saIdx]
                  if (sAcc.proposedChange_amount !== undefined && sAcc.proposedChange_amount !== 0) {
                     sAcc.entryType = this.getEntryType(sAcc)
                     curTransaction.push(sAcc)
                  }
               }
            }
         }
      }
      return curTransaction
   },
}

export {basicFunctions}
