import React, { Component } from 'react'
import { Icon } from 'react-icons-kit'

import ReactGA from "react-ga"

import AuthService from './utils/auth'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'

//import { GoogleLogin } from 'react-google-login'

import googleAuthFns from './utils/google'

//import {question} from 'react-icons-kit/fa/question'
//import {ic_list} from 'react-icons-kit/md/ic_list'
//import {checkSquareO} from 'react-icons-kit/fa/checkSquareO'
//import {gavel} from 'react-icons-kit/fa/gavel'

//import {list} from 'react-icons-kit/icomoon/list'
//import {ic_grid_on} from 'react-icons-kit/md/ic_grid_on'
//import {dollar} from 'react-icons-kit/fa/dollar'
//import {clockO} from 'react-icons-kit/fa/clockO'
//import {flask} from 'react-icons-kit/fa/flask'
//import {users} from 'react-icons-kit/icomoon/users'

import {checkmark} from 'react-icons-kit/icomoon/checkmark'

import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare'
import {facebook} from 'react-icons-kit/fa/facebook'
import {twitter} from 'react-icons-kit/fa/twitter'

import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down'

import { motion } from "framer-motion"
import { basicFunctions } from './include/basic.js'

import {CatembeInteractiveApp, EmailSubscribeForm, ContactForm, ImageSection, FooterRow} from './components/frontpage.js'

import './css/FrontPage.css'

import { battleships } from './apps/battleships'
import { duality } from './apps/duality'
import { je } from './apps/je'
import { sudoku } from './apps/sudoku'
import { talbot } from './apps/talbot'
import { breakeven } from './apps/breakeven'

var config = require('./config/api');

var apps = {}
apps[battleships.getAppRoute()] = battleships
apps[je.getAppRoute()] = je
apps[duality.getAppRoute()] = duality
apps[sudoku.getAppRoute()] = sudoku
apps[talbot.getAppRoute()] = talbot
apps[breakeven.getAppRoute()] = breakeven

//const featuredApps = ['breakeven', 'talbot', 'battleships', 'fss', 'gt']
const featuredApps = ['breakeven', 'battleships', 'fss', 'je']

const auth = new AuthService();
class FrontPage extends Component {
	//<iframe className={`heroVideo`} src="https://www.youtube-nocookie.com/embed/DGqV_cFOol8?rel=0&amp;controls=0&amp;showinfo=0;autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
   constructor(props) {
      super(props)
      this.googleResponse = googleAuthFns.googleResponse.bind(this)
      this.googleOnFailure = googleAuthFns.onFailure.bind(this)

		// Get list of appRoutes with valid videos, so we can choose one at random
		var appRoutes = []
		for (var appIdx in featuredApps) {
			const appRoute = featuredApps[appIdx]
			if (apps[appRoute].gameDetails && apps[appRoute].gameDetails.videoUrl) {
				appRoutes.push(appRoute)
			}
		}

      this.state = {
			selectedApp: appRoutes[Math.floor(Math.random() * appRoutes.length)], // Random video plays on startup
			showContactForm: false,
			showHelpContactForm: false,
			messageSent: false,
			emailSubscribed: false,
		}
   }

   componentWillMount(){
   }

	componentDidMount() {
		setTimeout( () => {
				ReactGA.event({
					category: 'conversion',
					action: '1minOnFrontPage'
				})
			}, 60*1000)
	}

//<div className="embed-responsive embed-responsive-16by9 heroDevice" style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/Laptop_v2.png')"}}> //<video src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Catembe_Hero_SD.mp4" autoPlay={true} muted={true} loop={true}/> //</div>
//<ul className="problemPoints"> <li>The business curriculum doesn't naturally lend itself to hands-on learning</li> <li>Students often don’t understand context or relevance of concepts being taught</li> <li>Lack of instant feedback on correct understanding of concepts</li> </ul>
//<h2 className="lecturesSubtitle">The traditional approach to teaching business by simply <span className="highlight">describing</span> concepts is outdated and ineffective.</h2>
	// <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Bored.png" alt="Bored person" style={{height: '200px', width: '200px'}}/>

	selectApp = (appRoute) => {
		ReactGA.event({ category: 'conversion', action: 'viewAppVideo', label: appRoute, })

		this.setState((prevState) => {
			if (prevState.selectedApp !== appRoute && apps[appRoute] && apps[appRoute].gameDetails && apps[appRoute].gameDetails.videoUrl) {
				this.setState({selectedApp: appRoute})
			}
		})
	}

	clickApp = (appRoute) => {
		ReactGA.event({ category: 'conversion', action: 'clickApp', label: appRoute, })
		this.props.history.push('/interactives/' + appRoute)
	}

	getStarted = () => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({ category: 'conversion', action: 'showContactForm' })
		this.setState({ showContactForm: true })
	}

	helpUs = () => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({ category: 'conversion', action: 'showHelpContactForm' })
		this.setState({ showHelpContactForm: true })
	}

	apiSubmit = (data) => {
		//ga('send', 'event', 'conversion', 'sendContactForm');
		if (data.action === 'subscribe') { ReactGA.event({ category: 'conversion', action: 'submitEmailSubscribeForm' }) }
		else if (data.action === 'contact') { ReactGA.event({ category: 'conversion', action: 'submitContactForm' }) }
		const callback = (data) => {
			if (data.success === true) {
				if (data.action === 'subscribe') { this.setState({emailSubscribed: true})
				} else { this.setState({messageSent: true}) }
			}
		}
		basicFunctions.apiCall(data, callback)
	}
   //<div className="container-fluid" > <div className="row"> <div className="container bodySection fatBottom px-5"> <div className="row text-center mb-5"> <div className="col-lg-10 offset-lg-1"> <h2 className="lecturesSubtitle">Do your online classes and lectures <span className="highlight nowrap">involve and engage</span> students?</h2> </div> </div> </div> </div> </div>

	render() {
		var videoUrl
		if (apps[this.state.selectedApp] && apps[this.state.selectedApp].gameDetails && apps[this.state.selectedApp].gameDetails.videoUrl) {
			videoUrl = apps[this.state.selectedApp].gameDetails.videoUrl
		}

		const appFns = {onHover: this.selectApp, onClick: this.clickApp, selectedApp: this.state.selectedApp}

		var featuredInteractives = []
		for (var aIdx in featuredApps) {
			featuredInteractives.push(<CatembeInteractiveApp app={apps[featuredApps[aIdx]]} key={featuredApps[aIdx]} {...appFns}/>)
		}
		//<a className="_viewAll" href="/games/">View all games</a>

		return (
      <GoogleOAuthProvider clientId="32806058107-p5l9ilglstapunasva4hkna5dgbilkqi.apps.googleusercontent.com">
        <div style={{backgroundColor: '#fff'}}>
          { this.props.covidMsg ? <div className="covidOffer"><strong>COVID-19</strong>: First semester free for colleges and universities impacted by COVID-19</div> : <span/> }
          <div className="container-fluid headerSection unslant" style={{backgroundColor: '#f6f6f6'}}>
            <div className="scrollIndicator">
              <Icon icon={ic_keyboard_arrow_down} size={48}/>
            </div>
                 <div className="container-fluid slant">
                    <div className="row">
                       <div className="col-lg-5 col-md-12 text-center" style={{minHeight: '400px'}}>
                          <div className="jumboContainer" style={{backgroundColor: 'inherit', bottom: '0'}}>
                    <div className='logoContainer'>
                      <img src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/CatembeLogoSmallSquare.png" className="logoImg img-fluid" alt="Catembe"/>
                    </div>
                    <h2 className="lead">Serious games for business schools</h2>
                    <div className='googleLoginContainer'>
                       { this.state.error ? <div>Error logging in. Please try again later.</div> : <span/> }
                       { !auth.loggedIn() ? <GoogleLogin
                            clientId="32806058107-p5l9ilglstapunasva4hkna5dgbilkqi.apps.googleusercontent.com"
                            text="continue_with"
                            onSuccess={(response) => this.googleResponse('/user', response)}
                            onFailure={this.googleOnFailure}
                            theme="outline"
                            size="large"
                            shape="pill"
                            logo_alignment="center"
                            useOneTap
                          /> : <span/> }
                       { auth.loggedIn() ?
                      <a href="/user">Log in</a> : <span/> }
                          </div>
                       </div>
                    </div>
                    <div className="col-lg-7 col-md-12 heroImage">
                    <picture>
                      <source srcSet="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HeroImg.webp" type="image/webp" />
                      <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HeroImg.png" alt="iPhone with Catembe"/>
                    </picture>
                       </div>
                    </div>
                 </div>
          </div>

          <div className="container-fluid blueGradient unslant">

            <div className="row">
              <div className="col-12">
                <div className="container bodySection px-5 slant">
                  <div className="row text-center mb-5">
                    <h1 className="mainTagline mb-4">Catembe makes it easy to add highly engaging games to your business course.</h1>
                    <h2 className="lead">Transform your course with serious games to engage students and improve learning outcomes.</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="container fatBottom slant appListContainer">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-sm-12 _lhs">
                      <h3 className="smallCapsHeading text-center white-text">Featured games</h3>
                      <div className="appList">
                        {featuredInteractives}
                      </div>

                    </div>
                    <div className="col-lg-6 col-md-12 _rhs">
                      <div className="iPhoneContainer">
                         <div className="embed-responsive iPhone" style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
                          <video src={videoUrl} autoPlay={true} muted={true} loop={true}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
              <div className="container-fluid" >
                 <div className="row">
                    <div className="container bodySection fatBottom howItWorks px-5 text-center">
                <div className="row text-center mb-5">
                  <div className="col-12 text-center">
                    <h1 className="mainTagline mb-4">A new way of teaching.</h1>
                  </div>
                </div>
                <div className="row">

                  <div className="col-md-4 col-sm-12">
                    <picture>
                      <source srcSet="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_1.webp" type="image/webp" />
                      <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_1.png" alt="Person using phone before class"/>
                    </picture>
                    <div><h3>Before class</h3></div>
                    <p>Students play a game to become familiar with the concept and context</p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <picture>
                      <source srcSet="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_2.webp" type="image/webp" />
                      <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_2.png" alt="People using phones during class"/>
                    </picture>
                    <div><h3>Live mode - In class</h3></div>
                    <p>Educators lead students through an interactive, multiplayer version of the same game, in-person or online</p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <picture>
                      <source srcSet="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_3.webp" type="image/webp" />
                      <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/HowItWorks_3.png" alt="Person using phone after class"/>
                    </picture>
                    <div><h3>After class</h3></div>
                    <p>Students re-attempt game to reinforce learning</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid unslant" style={{background: '#3e3b44', position: 'relative', zIndex: '100'}}>
            <div className="row">
              <ImageSection
                  src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/GirlPhone.png"
                  style={{backgroundPosition: 'right top', backgroundRepeat: 'no-repeat', color: 'white'}}>
                <div className="container slant bodySection fatBottom">
                  <div className="row">
                    <div className="col-lg-5 col-md-8 col-sm-12 text-center imageSectionText">
                      <h2 className="mb-5">Focus on usability and student experience</h2>
                      <p className="mb-3">Our our interactives are designed to be engaging, simple, and intuitive, with a focus on play and experimentation.<br/>No instructions necessary.</p>
                      <p className="mb-3">Engaging and explorable content can significantly reduce the cognitive load and anxiety around learning.</p>
                    </div>
                  </div>
                </div>
              </ImageSection>
            </div>
          </div>

          <div className="container-fluid imageSection" style={{background: '#ffffff', marginTop: '-30px', position: 'relative'}}>
            <div className="row">
              <ImageSection
                  src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Lecture.png"
                  style={{backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', color: 'black'}}>
                <div className="container bodySection fatBottom">
                  <div className="row justify-content-end">
                    <div className="col-lg-5 col-md-8 col-sm-12 text-center imageSectionText white">
                      <h2 className="mb-5">Transform the lecture</h2>
                      <p className="mb-3">We believe that truly interactive lectures and webinars should involve more than just live polling.</p>
                      <p className="mb-3">All of our interactives can be used in <span className="liveMode">live mode</span>, a real-time multiplayer version that is completely controlled by the lecturer.</p>
                      <p className="mb-3">Use them to create amazing synchronous learning experiences: online, in interactive lessons and activites, or face-to-face in lectures and classrooms.</p>

                    </div>
                  </div>
                </div>
              </ImageSection>
            </div>
          </div>

          <div className="container-fluid slant imageSection" style={{background: '#033a63', marginTop: '-30px', position: 'relative'}}>
            <div className="row">
              <ImageSection
                  src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Business.png"
                  style={{backgroundPosition: 'right top', backgroundRepeat: 'no-repeat', color: 'white'}}>
                <div className="container unslant bodySection fatBottom">
                  <div className="row">
                    <div className="col-lg-5 col-md-8 col-sm-12 text-center imageSectionText">
                      <h2 className="mb-5">Built for business</h2>
                      <p className="mb-3">Our interactives and simulations are built specifically for the business curriculum.</p>
                      <p className="mb-3">We harness the mechanics of the concept itself to drive the gameplay: for instance, in our Supply & Demand game, students are challenged to find the equilibrium of an unseen supply and demand model.</p>
                      <p className="mb-3">Mastering the game and truly understanding the concept become one and the same.</p>
                    </div>
                  </div>
                </div>
              </ImageSection>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
                    <div className="container bodySection">
                       <div className="row">
                          <div className="col-lg-12 px-5">
                             <div className="heading_lineAbove">
                                <div className="line"/>
                                <span>Benefits</span>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Deployment flexibility</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Our content can be deployed real-time in lectures or accessed by students in their own time, on campus or online</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Highly engaging content</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Our games feature engaging elements such as rewards, random chance, and instant feedback, encouraging students to make repeated attempts to master the content</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Easy to use</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Customise and deploy games in minutes -- our platform takes care of the infrastructure and logistics</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Access on any device</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Any number of students can participate in real time using their own mobile devices: laptop, tablet, or smartphone</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Real incentives</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Students earn and spend a platform currency with real value, enabling realistic profit incentives for decision making</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Reliable</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p>Our secure platform has been battle-tested with large lecture cohorts</p>
                                </div>
                             </div>
                             <div className="row benefit mt-5">
                                <div className="col-lg-4 offset-lg-1">
                                    <h3>Our supporters</h3>
                                </div>
                                <div className="col-lg-6">
                        <a href="https://unimelb.edu.au/mec/melbourne-innovated" target="_catembe_new">
                          <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Images/SupportedBy_UoM.png"/>
                        </a>
                        <a href="https://www.futuremindsaccelerator.com/startups" target="_catembe_new">
                          <img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Images/SupportedBy_FMA.png"/>
                        </a>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
          <div className="container-fluid" >
            <div className="row">
                    <div className="container bodySection lastSection">
                       <div className="row">
                          <div className="col-lg-12 px-5">
                          </div>
                       </div>
                    </div>
                 </div>
              </div>

          <div className="container-fluid footerSection" style={{backgroundColor: '#f6f6f6'}}>
            <div className="row mb-5">
              <div className="col-sm-12 col-md-6 text-center _lhs">
                <h2 className="footerText display-5 mb-4">Stay in touch</h2>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8">
                      <p className="mb-3">We'll keep you in the loop, and you'll be the first to try out brand new content!</p>
                      <EmailSubscribeForm
                        apiSubmit={this.apiSubmit}
                        messageSent={this.state.emailSubscribed}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 text-center">
                <div className="smallCapsHeading text-center">Educators</div>
                <h2 className="footerText display-5 mb-4">Ready to transform your course?</h2>
                { this.state.showContactForm ?
                  <div className="container" id="enquireForm">
                    <div className="row justify-content-center">
                      <div className="col-md-10 col-lg-8">
                        <h6 className="">Fill in the form below and we'll get in touch.</h6>
                        <ContactForm
                          type="enquire"
                          apiSubmit={this.apiSubmit}
                          messageSent={this.state.messageSent}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <button id="getStartedButton" className="btn actionButton buttonGradient_redYellow" onClick={this.getStarted}>Get started</button>
                }
              </div>
            </div>
            <FooterRow/>
          </div>
        </div>
      </GoogleOAuthProvider>
		)
	}
}
export default (FrontPage)
