import React from 'react'
import '../css/Cards.css'

import { FrontendAppSettings } from './FrontendAppSettings'
import { basicFunctions } from '../include/basic'
import { GameTimeLeft } from '../components/timing'
import { BasicControlView, ControlButton } from '../components/control.js'

import { IntroPage, ButtonFooter, Title, Description, StudentAccessInstructions } from '../components/basic.js'
import { Deck, CardDestinations, GameFinished, CardResponses, LiveCardDisplay } from '../components/cards'

import { SwipeCarousel } from '../components/swipe'

import { PersonaCard } from '../components/marketing'
//import { DragSource, DragTarget } from '../components/dnd'

//import { DndProvider } from 'react-dnd'
//import TouchBackend from 'react-dnd-touch-backend'
//import MultiBackend, { Preview } from 'react-dnd-multi-backend'
//import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

const gameDetails = { // Items specific to this deployment
	gameId: 'cards_stp', // Unique identifier for this game
	appRoute: 'cards_stp', // Determines url of app
	//stageNames: ['a', 'b'], // Optional -- for naming the sections of the control view
}

var cards_stp = new FrontendAppSettings(gameDetails)

cards_stp.config.fullscreen = true
cards_stp.config.studentWidescreenView = true
//cards_stp.config.noStudentPeriodicUpdate = true
cards_stp.config.studentHeader = false

cards_stp.content= {
	title: "STP Cards",
}

cards_stp.appConfigs = {
	stp_live: {
		personas: {
			bob: { 
				id: "bob",
				name: "Bob",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_angry.png",
				namedAttributes: {
					'Age': "36",
					'Location': 'Shepparton, Regional Victoria',
					'Occupation': "Welder",
					"Income": "$50k / year",

				},
				listAttributes: [
					"Divorced, with 2 children",
					"Currently between jobs",
					"Also relies on income from a pension",
				],
			},
			fatima: { 
				id: 'fatima',
				name: "Fatima",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_angry.png",
				namedAttributes: {
					'Age': "23",
					'Location': 'Carlton, Melbourne',
					'Occupation': "Student, works part-time in hospitality",
					"Income": "$20k p/a",
				},
				listAttributes: [
					"Studying Medicine",
					"Politically progressive",
					"Involved in activism",
					"Vegan",
				],
			},
			gregory: { 
				id: 'gregory',
				name: "Gregory",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_angry.png",
				namedAttributes: {
					'Age': "46",
					'Location': 'Double Bay, Sydney',
					'Occupation': "Entrepreneur",
					"Income": "$140k p/a",
				},
				listAttributes: [
					"Married with 1 child",
					"Loves fine wine",
					"Eats at restaurants frequently",
					"Drives an upmarket 4WD",
				],
			},
			judy: { 
				id: 'judy',
				name: "Judy",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_angry.png",
				namedAttributes: {
					'Age': "59",
					'Location': 'Naree Warren, Melbourne',
					'Occupation': "Retired Schoolteacher",
					"Income": "$60k p/a",
				},
				listAttributes: [
					"Married, 2 adult kids and 3 grandchildren",
					"Enjoys home cooking and crafts",
					"Loves shopping",
				],
			},
		}
	},
	stp: {
		personas: {
			bob: { 
				id: "bob",
				name: "Bob",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/bob_angry.png",
				namedAttributes: {
					'Age': "36",
					'Location': 'Shepparton, Regional Victoria',
					'Occupation': "Welder",
					"Income": "$50k / year",

				},
				listAttributes: [
					"Divorced, with 2 children",
					"Currently between jobs",
					"Also relies on income from a pension",
				],
			},
			fatima: { 
				id: 'fatima',
				name: "Fatima",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/fatima_angry.png",
				namedAttributes: {
					'Age': "23",
					'Location': 'Carlton, Melbourne',
					'Occupation': "Student, works part-time in hospitality",
					"Income": "$20k p/a",
				},
				listAttributes: [
					"Studying Medicine",
					"Politically progressive",
					"Involved in activism",
					"Vegan",
				],
			},
			gregory: { 
				id: 'gregory',
				name: "Gregory",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/greg_angry.png",
				namedAttributes: {
					'Age': "46",
					'Location': 'Double Bay, Sydney',
					'Occupation': "Entrepreneur",
					"Income": "$140k p/a",
				},
				listAttributes: [
					"Married with 1 child",
					"Loves fine wine",
					"Eats at restaurants frequently",
					"Drives an upmarket 4WD",
				],
			},
			judy: { 
				id: 'judy',
				name: "Judy",
				img: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_neutral.png",
				img_positive: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_happy.png",
				img_negative: "https://d3tx1wevfc318r.cloudfront.net/Images/judy_angry.png",
				namedAttributes: {
					'Age': "59",
					'Location': 'Naree Warren, Melbourne',
					'Occupation': "Retired Schoolteacher",
					"Income": "$60k p/a",
				},
				listAttributes: [
					"Married, 2 adult kids and 3 grandchildren",
					"Enjoys home cooking and crafts",
					"Loves shopping",
				],
			},
		}
	}
}

cards_stp.lifecycle.STUDENT.componentWillMount = function() {
	this.onSwipe = (dir) => {
		if (this.state.curCardIdx <= 0) { // All cards are gone
			if (this.state.liveMode) { } 
			else {
				setTimeout(() => { this.gameFinished() }, 2500)
			}
		}
		this.sendInput({action: 'swipeCard', direction: dir, curCardIdx: this.state.curCardIdx})

		if (this.state.curCardIdx > 0) { // Go to next card in the deck
			this.setState((prevState) => {
				return {curCardIdx: prevState.curCardIdx - 1}
			})
		}
	}
	this.gameFinished = () => {
		//console.log('game finished ' + s)
		if (this.state.gameFinished !== true) {
			this.sendInput({action: 'gameFinished'})
			this.setState({gameFinished: true, curCardIdx: null})
		}
	}
	this.resetGame = () => {
		if (this.state.gameFinished === true) {
			this.sendInput({action: 'resetGame'})
			this.setState({gameEndTime: null, gameFinished: false}) // Need to foce endTime here otherwise timer will wake up before the new endTime is returned from the API
			this.prevStage() 
		}
	}
	this.onContinue = () => { 
		this.nextStage() 
		this.sendInput({action: 'enrol'})
	}

}

cards_stp.stages = {
	CONTROL: function () {
		const inGameButtons = [
			<ControlButton onClick={() => {
					if(window.confirm("This will end the game and show students a GAME OVER screen. Are you sure?")) { this.sendAction('endLiveGame') } 
				}} dangerous={true}> End the live session </ControlButton>
		]
		return (<BasicControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					stages = {this.gameDetails.stageNames} 
					extraControlPanelItems = {this.state.liveMode ? inGameButtons : []}
					refreshSession = {this.refreshSession}
					appView = {this.state.appView}
				/>)

	},
	STUDENT: {
		'0': function() {
			return (
				<div className="container">
					<div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '400px'}}>
						<SwipeCarousel>
							{this.content.personas ? Object.values(this.content.personas).map((x) => <PersonaCard {...x}/>) : ""}
						</SwipeCarousel>
					</div>
					<ButtonFooter
						onClick = {this.onContinue}
						/>
				</div>
			)
		},
		'1': function() {
			if (this.state.cards) {

				if (this.state.cards.length === 0) {
					return (
						<div className="deck zeroData">
						{ this.state.liveMode ? 
							<div className="text-center">
								<div className="cardsInstructions" style={{fontSize: '24px', marginTop: '50vh'}}>Waiting for your lecturer to issue a card...</div> 
								<button className="btn actionButton buttonGradient_blue mt-3" onClick={this.prevStage}>Back to personas</button>
							</div>:
							<div className="cardsInstructions">No cards here!</div> }
						</div>
					)
				}

				// Initialise cursor
				if (this.state.curCardIdx === undefined || this.state.curCardIdx === null) {
					this.setState({curCardIdx: this.state.cards.length - 1})
				}

				var resultClass = ""
				if (this.state.lastSwipe) {
					if (this.state.lastSwipe.correct === true) { resultClass = 'correct' }
					if (this.state.lastSwipe.correct === false) { resultClass = 'incorrect' }
				}
				return (
					<div className={"deck " + resultClass}>
						{ this.state.gameFinished === true ? 
							<GameFinished
								onReset={this.resetGame}
								results={this.state.results}
								liveMode={this.state.liveMode}
								sendFeedback={this.sendFeedback}
								/> : 
							<Deck 
								key={this.state.deckId}
								onSwipe={this.onSwipe} 
								cards={this.state.cards}
								mode={this.state.mode}
								entryAnimation={true}
							/>
						}
						{ (this.state.cards && !this.state.liveMode && this.state.curCardIdx + 1 === this.state.cards.length) ?
							<div className="cardsInstructions">Swipe the products towards the persona you would target!</div> : <span/> }
						{ this.state.curCardIdx !== undefined && this.state.gameFinished !== true ?
							<CardDestinations 
								curCard={this.state.cards[this.state.curCardIdx]}
								categories={this.content.personas}
								lastSwipe={this.state.lastSwipe}
								mode={this.state.mode}
							/> : <span/> }

						{ this.state.curCardIdx !== undefined && this.state.gameFinished !== true && this.state.lastSwipe && this.state.lastSwipe.response ?
							<CardResponses
								categories={this.state.categories}
								mode={this.state.mode}
								lastSwipe={this.state.lastSwipe}
								/> : <span/> }
					</div>
				)
			} else {
				return <span/>
			}
		}
	},
	DISPLAY: {
		'0': function() {
			return (
				<IntroPage>
					<Title>{this.content.title}</Title>
					<Description>{this.content.description}</Description>
					<StudentAccessInstructions app={this}/>
				</IntroPage>
			)
		},
		'1': function() {
			return (
				<div className="container">
					<div className="studentInstructionsHeaderBar">Join using URL: catem.be/livestp</div>
					<div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '1000px'}}>
						<SwipeCarousel>
							{this.content.personas ? Object.values(this.content.personas).map((x) => <PersonaCard {...x} widescreen={true}/>) : ""}
						</SwipeCarousel>
					</div>
				</div>
			)
		},
		'2': function() {
			return (
				<LiveCardDisplay 
					key={this.state.deckId}
					sendControlAction={this.sendControlAction}
					refCategories={this.content.personas}
					{...this.state}
				/>
			)
		},
		'3': function() {
			return (
				<LiveCardDisplay 
					key={this.state.deckId}
					sendControlAction={this.sendControlAction}
					refCategories={this.content.personas}
					{...this.state}
				/>
			)
		},
		'4': function() {
			return (
				<LiveCardDisplay 
					key={this.state.deckId}
					sendControlAction={this.sendControlAction}
					refCategories={this.content.personas}
					{...this.state}
				/>
			)
		},
		'5': function() {
			return (
				<LiveCardDisplay 
					key={this.state.deckId}
					sendControlAction={this.sendControlAction}
					refCategories={this.content.personas}
					{...this.state}
				/>
			)
		},
		'6': function() {
			return (
				<LiveCardDisplay 
					key={this.state.deckId}
					sendControlAction={this.sendControlAction}
					refCategories={this.content.personas}
					{...this.state}
				/>
			)
		},
	},
}

export { cards_stp }
