import React, { Component } from 'react'
import { basicFunctions } from '../include/basic'

class GameTimeLeft extends Component {
	constructor(props) {
		super(props)
		this.state = { timeLeft: this.getTimeLeft(), finished: false }
	}
	getTimeLeft = () => {
		const secsLeft = (new Date(this.props.endTime) - Date.now()) / 1000 
		//const timeLeft = basicFunctions.formatTime(this.state.gameTimeLeft)
		return Math.max(0, secsLeft)
	}
	componentWillUpdate(newProps, newState) {
	}
	refresh = () => {
		if (this.props.active) {
			this.setState({ timeLeft: this.getTimeLeft() })
		}
	}
	render() {
		if (!this.props.active || this.props.endTime === null) { // Sometimes the timer can be turned on before we've received an endTime from the API
			return <span/>
		}

		if (Date.now() < new Date(this.props.endTime)) {
			if (this.state.finished === true) { // Can't be!
				this.setState({finished: false}) // Let's turn ourselves on again
			}

			setTimeout(() => { this.refresh() }, 50)
		}
		else {
			if (this.state.finished !== true) { // Timer has run out
				this.setState({finished: true}) // Let's turn ourselves off
				if (this.props.active === true) { // If we were active at the time, we need to stop the game also!
					this.props.onFinish()
				}
			}
		}
		//<div className="_value">{this.state.timeLeft[0] + ":" + this.state.timeLeft[1]}</div>
		return (
			<div className="gameTimeLeft">
				<div className="_label">Time left</div>
				<div className="_value">{this.state.timeLeft.toFixed(2)}</div>
			</div>
		)
	}
}
export { GameTimeLeft }
