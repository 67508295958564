import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import AuthService from './utils/auth'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { Icon } from 'react-icons-kit'
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward'
import { ic_arrow_upward } from 'react-icons-kit/md/ic_arrow_upward'
import { ic_arrow_downward } from 'react-icons-kit/md/ic_arrow_downward'

import { Link, Element, animateScroll as scroll, scroller } from "react-scroll"

import ReactGA from "react-ga"
import { CatembeInteractiveApp, ContactForm, ImageSection, FooterRow } from './components/frontpage.js'
import { CatembeNavBar } from './components/basic.js'
import { basicFunctions } from './include/basic.js'

import DemoView from './demoView.js'

import './css/FrontPage.css'

import { battleships } from './apps/battleships'
import { duality } from './apps/duality'
import { je } from './apps/je'
import { sudoku } from './apps/sudoku'
import { breakeven } from './apps/breakeven'
import { talbot } from './apps/talbot'

const auth = new AuthService()

var apps = {}
apps[battleships.getAppRoute()] = battleships
apps[duality.getAppRoute()] = duality
apps[je.getAppRoute()] = je
apps[sudoku.getAppRoute()] = sudoku
apps[breakeven.getAppRoute()] = breakeven
apps[talbot.getAppRoute()] = talbot

//<meta name="twitter:title" content={game.title + " | Catembe"} />
//<meta name="twitter:image" content={'https://d3tx1wevfc318r.cloudfront.net/Images/SocialImage_Product_' + game.appRoute + '.jpg'} />
//<meta name="twitter:description" content={game.shortDescription} />

class ProductInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			demoActive: false,
		}
	}
	componentDidMount() {
	}
	launchAsyncDemo = () => {
		ReactGA.event({ category: 'conversion', action: 'launchAsyncDemo', label: this.props.app.gameDetails.appRoute })
		this.setState({demoActive: true})
	}
	render() {

		if (this.props.app && this.props.app.gameDetails) {

			const game = this.props.app.gameDetails

			var topics = []
			if (game.topicsCovered) { topics = game.topicsCovered.map(topic => <li>{topic}</li>) }

			return (
					<div className="container-fluid" >
						<div className="row productInfoContainer">
							<div className="col-lg-6 col-sm-12 _lhs">
								<div className="mb-3">
									<div className="smallCapsHeading display-block">Featured Interactive</div>
									<h1>{game.title}</h1>
								</div>
							{ topics.length > 0 ?
								<div className="mb-3">
									<ul className="topicsCovered">{topics}</ul>
								</div> : <span/> }
							{ game.longDescription ?
								<div className="mb-3">
									{game.longDescription}
								</div> : <span/> }

							{ game.asyncDemoUrl ?
								<div className="launchDemoButtonContainer text-center mt-5">
									{ this.state.demoActive ?
										<button className="btn actionButton" disabled={true}>Demo active <Icon icon={ic_arrow_forward}/></button> :
										<button className="btn actionButton buttonGradient_blue" onClick={() => this.launchAsyncDemo()}>
											<div>Try it now</div>
											<div className="_subtitle">Single-player mode</div>
										</button> }
								</div> : <span/> }

							{ this.props.liveModeLink ?
									<div className="launchDemoButtonContainer text-center">
											<button className="btn actionButton buttonGradient_green" onClick={() => window.location = this.props.liveModeLink}>
												<div>Try it now</div>
												<div className="_subtitle">Live mode (multiplayer)</div>
											</button>
									</div> : <span/>
							}
							{ !this.props.liveModeLink && game.liveMode && game.liveMode.demo ?
								<Link to="liveMode" smooth={true} offset={100}>
									<div className="launchDemoButtonContainer text-center">
											<button className="btn actionButton buttonGradient_green" onClick="">
												<div>Try it now</div>
												<div className="_subtitle">Live mode (multiplayer)</div>
											</button>
									</div>
								</Link>: <span/> }

							</div>

							<div className="col-lg-6 col-sm-12 _rhs">
								<div className="iPhoneContainer">
									<div className="embed-responsive iPhone"
										style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
										{ this.state.demoActive ?
											<iframe className="iPhoneStudentView demoActive" src={game.asyncDemoUrl}/> :
											<video src={game.videoUrl} autoPlay={true} muted={true} loop={true}/> }
										{ this.state.demoActive ?
											<div className="demoActiveLabel">
												<div className="_label">Live demo</div>
											</div> : <span/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>)
		} else {
			return (<span>Unable to load app</span>)
		}


	}
}

class LiveModeDemo extends Component {
	constructor(props) {
		super(props)
		this.state = { demoActive: false, isTourOpen: false, activeVariant: '0' }
	}
	launchSyncDemo = () => {
		this.setState({ demoActive: true })
		//this.timer = setTimeout(() => {this.startTour()}, 2000)
		ReactGA.event({ category: 'conversion', action: 'launchAsyncDemo', label: this.props.app.gameDetails.appRoute })

	}
	startTour = () => {
		this.setState({ isTourOpen: true })
	}
	closeTour = () => {
		this.setState({ isTourOpen: false })
	}

	render() {
		if (this.props.app && this.props.app.gameDetails && this.props.app.gameDetails.liveMode) {

			const game = this.props.app.gameDetails

			const videoDemo_separate = ( // XXX LEGACY, DO NOT USE FOR ANYTHING NEW
				<div className="row liveModeDemoContainer">
					<div className="col-lg-4 col-md-12 _lhs">
						<div className="iPhoneContainer">
							<div className="embed-responsive img-fluid iPhone" id="tour1"
								style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
								<video src={game.liveMode.studentVideoUrl} autoPlay={true} muted={true} loop={true}/>
								<img className="_student" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Student.png"/>
							</div>
						</div>
						<div className="_label">
							<h3>Student view</h3>
							<p>What students see</p>
						</div>
					</div>
					<div className="col-lg-8 col-md-12 _lhs">
						<div className="embed-responsive Projector blueGradient">
							<video src={game.liveMode.displayVideoUrl} className="img-fluid" autoPlay={true} muted={true} loop={true}/>
							<img className="_lecturer" src="https://d3tx1wevfc318r.cloudfront.net/Frontpage/Lecturer.png"/>
						</div>
						<div className="_label">
							<h3>Projector view</h3>
							<p>What the whole class sees</p>
						</div>
					</div>
				</div>
			)

			const videoDemoJsx = (videoUrl) => (
				<div className="row liveModeDemoContainer">
					<div className="col-12">
						<video src={videoUrl} key={videoUrl} className="img-fluid" autoPlay={true} muted={true} loop={true}/>
					</div>
				</div>
			)

			var videoDemo = <span/>

			if (game.liveMode.variants) {
				var selectors = []
				var videoJsx = null
				for (var vIdx in game.liveMode.variants) {
					var variant = game.liveMode.variants[vIdx]
					if (variant.videoUrl) {
						const curVar = vIdx
						if (curVar === this.state.activeVariant) {
							videoJsx = (<div>
								{ variant.description ? <p className="variantDescription">{variant.description}</p> : <span/> }
								{videoDemoJsx(variant.videoUrl)}
							</div>)
						}
						selectors.push(<button key={"v" + vIdx} type="button" className={"btn" + (curVar === this.state.activeVariant ? " btn-primary" : "")}
							onClick={() => { this.setState({activeVariant: curVar}) }}>{variant.name}</button>)
					}
					videoDemo = (
						<div className="row liveModeDemoContainer">
							<div className="col">
								<div className="btn-group mb-4" role="group">{selectors}</div>
								<div>
									{videoJsx}
								</div>
							</div>
						</div>)
				}

			}
			else if (game.liveMode.studentVideoUrl && game.liveMode.displayVideoUrl) {
				videoDemo = videoDemo_separate
			} else if (game.liveMode.videoUrl) {
				videoDemo = videoDemoJsx(game.liveMode.videoUrl)
			}

			const disableBody = target => {console.log(target); disableBodyScroll(target)}
			const enableBody = target => enableBodyScroll(target)

			var tourAvailable = false
			if (game.liveMode && game.liveMode.demo && game.liveMode.demo.tourSteps) {
				tourAvailable = true
			}
			//<p>Your screen is too small to display the Live Mode demo effectively. Please resize or use another device!</p>

			return (
				<div className="container-fluid overflow-hidden" style={{background: '#faf9fa'}}>
					<Element name="liveMode"/>

				{ game.liveMode.longDescription ?
					<div className="row">
						<div className="col">
							<div className="container px-5">
								<div className="row justify-content-center mb-5 text-center">
									<div className="col-lg-6">
										<h1>Live mode</h1>
										{game.liveMode.longDescription}
									</div>
								</div>
							</div>
						</div>
					</div> : <span/> }

					{ game.liveMode.demo ?
					<div className="row">
						<div className="col">
							<div className="container mb-5 px-5">
								<div className="row">
									<div className="launchDemoButtonContainer text-center">
										{ this.state.demoActive ?
											<button className="btn actionButton" disabled={true}>Demo active <Icon icon={ic_arrow_downward}/></button> :
											<button className="btn actionButton buttonGradient_green" onClick={() => this.launchSyncDemo()}>
												<div>Try it now</div>
												<div className="_subtitle">Live, multi-player mode</div>
											</button> }
										<div className="buttonSubtitle">Launch a fully-functional demo in the browser.</div>
									</div>
									{ tourAvailable && this.state.demoActive ?
										<div className="launchDemoButtonContainer text-center">
											{ !this.state.isTourOpen ?
												<button className="btn actionButton buttonGradient_green" onClick={() => this.startTour()}>Launch tour</button> :
												<button className="btn actionButton" disabled={true}>Tour active <Icon icon={ic_arrow_downward}/></button>
											}
										</div> : <span/> }
								</div>
							</div>
						</div>
					</div> : <span/> }

					{ tourAvailable ?
						<Tour
							steps={game.liveMode.demo.tourSteps}
							isOpen={this.state.isTourOpen}
							onAfterOpen={disableBody}
							onBeforeClose={enableBody}
							onRequestClose={this.closeTour}
							disableKeyboardNavigation={true}
						/> : <span/> }

					<div className="row" style={{minHeight: '700px'}}>
						<div className={"col liveDemoViewContainer" + (this.state.demoActive ? " demoActive" : "")}>
							{ this.state.demoActive ?
								<div className="" className="demoView_notAvailable">
								</div> : <span/> }
							{ this.state.demoActive ?
								<DemoView appSettings={this.props.app} apiCall={auth.apiCall} public={true}/> :
								<div className="container">
									<div className="row">
										<div className="col">
											{ videoDemo }
										</div>
									</div>
								</div> }
						</div>
					</div>


				</div>
			)
		}
		else {
			return <span/>
		}
	}
}

class ProductPage extends Component {
   //constructor(props) { super(props) }
   constructor(props) {
      super(props)

      this.state = {
			showContactForm: false,
			messageSent: false,
		}

      if (props.match && props.match.params && props.match.params.appRoute) {
			const r = props.match.params.appRoute
			if (apps && apps[r]) {
				this.state.app = apps[r]
			}
		}
   }

	apiSubmit = (data) => {
		//ga('send', 'event', 'conversion', 'sendContactForm');
		ReactGA.event({ category: 'conversion', action: 'productContactForm' })
		const callback = (data) => {
			if (data.success === true) {
				this.setState({messageSent: true})
			}
		}
		basicFunctions.apiCall(data, callback)
	}

	componentDidMount() {
		setTimeout( () => {
				ReactGA.event({
					category: 'conversion',
					action: '1minOnProductPage'
				})
			}, 60*1000)

			if (this.props.scrollToLiveMode) {
				scroller.scrollTo('liveMode')
			}
			else {
				window.scrollTo(0, 0);
			}
	}

	getStarted = (app) => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({
			category: 'conversion',
			action: 'showContactForm'
		})
		this.setState({ showContactForm: true })
	}


	launchDemo = (app) => {
		//ga('send', 'event', 'conversion', 'showContactForm');
	}

	clickRelatedApp = (appRoute) => {
		ReactGA.event({ category: 'conversion', action: 'clickRelatedApp', label: appRoute, })
		//this.props.history.push('/interactives/' + appRoute)
	}

	render() {
		var veryShortTitle
		if (this.state.app && this.state.app.gameDetails) {
			veryShortTitle = this.state.app.gameDetails.veryShortTitle || this.state.app.gameDetails.shortTitle || this.state.app.gameDetails.title
		}

		var features
		if (this.state.app.gameDetails && this.state.app.gameDetails.features) {
			features = this.state.app.gameDetails.features.map( feature => (
				<div key={feature.heading} className="col-md-6 col-sm-12 _feature">
					<img src={feature.imageUrl}/>
					<h3>{feature.heading} {feature.comingSoon ? <div className="_comingSoon">coming soon</div> : <span/> } </h3>
					<p>{feature.description}</p>
				</div>)
			)
		}

		var relatedGames = []
		if (this.state.app.gameDetails && this.state.app.gameDetails.relatedGames) {
			for (var rIdx in this.state.app.gameDetails.relatedGames) {
				var relatedGame = this.state.app.gameDetails.relatedGames[rIdx]
				relatedGames.push(<CatembeInteractiveApp app={apps[relatedGame]} key={relatedGame} href={'/interactives/' + relatedGame + '/'} onClick={this.clickRelatedApp}/>)
			}
		}

		return (
			<div style={{backgroundColor: '#f9f9f9'}}>
				<Helmet>
					<title>{this.state.app.gameDetails.title + " | Catembe"}</title>
					<meta name="twitter:title" content={this.state.app.gameDetails.title + " | Catembe"} />
					<meta name="twitter:image" content={'https://d3tx1wevfc318r.cloudfront.net/Images/SocialImage_Product_' + this.state.app.gameDetails.appRoute + '.jpg'} />
					<meta name="twitter:description" content={this.state.app.gameDetails.shortDescription} />
				</Helmet>
				<CatembeNavBar />
				<div className="container bodySection fatBottom px-5">
					<ProductInfo app={this.state.app}/>
				</div>
				<LiveModeDemo app={this.state.app}/>
				<div className="container-fluid footerSection blueGradient" style={{backgroundColor: '#f6f6f6'}}>
					{ this.state.app.gameDetails && this.state.app.gameDetails.features ?
						<div className="container appFeatures fatBottom">
							<div className="row">
								{features}
							</div>
						</div> : <span/> }
					<div className="row justify-content-center">
						<div className="col-sm-12 col-md-6 text-center">

							<div className='footerCta'>
								<div className="smallCapsHeading text-center white-text">Educators</div>
								<h2 className="footerText display-5 mb-4">Use this interactive in your course.</h2>
								{ this.state.app.gameDetails && this.state.app.gameDetails.ctaExtra ? this.state.app.gameDetails.ctaExtra : "" }
								{ this.state.showContactForm ?
									<div className="container">
										<div className="row justify-content-center">
											<div className="col-md-10 col-lg-8">
												<ContactForm
													type="productEnquiry"
													messageSent={this.state.messageSent}
													apiSubmit={this.apiSubmit}
													product={this.state.app.gameDetails.appRoute}
													darkBg={true}
												/>
											</div>
										</div>
									</div>
									:
									<button className="btn actionButton buttonGradient_redYellow" onClick={this.getStarted}>Get started</button>
								}
							</div>

							{ relatedGames.length > 0 ?
								<div className='relatedApps'>
									<div className="smallCapsHeading text-center white-text">Related Games</div>
									{relatedGames}
								</div> : <span/> }
						</div>
					</div>
					<FooterRow darkBg/>
				</div>
			</div>
		)
	}
}

export { ProductPage, ProductInfo, LiveModeDemo }
