import React, {Component} from 'react'
import ScrollableFeed from 'react-scrollable-feed'
import { motion } from 'framer-motion'
import { formatNumber } from './basic.js'

import '../css/Talbot.css'
import {talbotData} from '../include/talbot-data.js'
import {basicFunctions} from '../include/basic.js'
import ClipLoader from "react-spinners/ClipLoader"

import TimeAgo from 'react-timeago'

class TextAdventureUI extends Component {
	render() {
		return (<div className="textAdvContainer">
			<TextAdventureDisplay content={this.props.output}/>
			<TextAdventureInput onSubmit={this.props.onSubmit}/>
		</div>)
	}
}

class TextAdventureDisplay extends Component {
	render() {
		var output = []
		for (var iIdx in this.props.content) {
			var item = this.props.content[iIdx]
			if (item) {
				if (typeof item === 'object') {
					if (item.type === 'input') {
						output.push( <div className='contentItem input' key={iIdx}>{item.value}</div>)
					} else {
						output.push( <div className='contentItem' key={iIdx}>{item.value}</div>)
					}
				} else if (typeof item === 'string' || item instanceof String) {
					output.push( <div className='contentItem' key={iIdx}><pre>{item}</pre></div>)
				}
			}
		}
		return (<div className="taDisplayContainer">
				<ScrollableFeed forceScroll={true}>{output}</ScrollableFeed>
			</div>)
	}
}
class TextAdventureInput extends Component {
	constructor(props) {
		super(props)
		this.state = { value: '' }
	}
	componentDidMount() {
		//this.textInput.focus();
	}
	onChange = (e) => {
		this.setState({value: e.target.value})
	}
	onKeyUp = (e) => {
		if (e.keyCode === 13) {
			this.props.onSubmit(this.state.value)
			this.setState({value: ''})
		}
	}
	render() {
		return (<div className="taInputContainer">
				<input 
					autoFocus
					ref={(input) => { this.textInput = input; }} 
					id="taInputBox"
					autoComplete="new-password"
					type="search"
					placeholder="Enter something here..."
					value={this.state.value}
					onKeyUp={this.onKeyUp} 
					onChange={this.onChange}/>
		</div>)
	}
}

class TalbotTable extends Component {
	//constructor(props) { }
	render() {
		var data = this.props.data

		var cols = {}
		if (this.props.options && this.props.options.columns) {
			cols = this.props.options.columns
		} else {
			// Build columns object dynamically
			for (var rIdx in data) {
				var row = data[rIdx]
				for (var colId in row) { cols[colId] = colId }
			}
		}

		var headerRow = []
		var bodyRows = []
		for (var colId in cols) {
			var classExtra = []
			if (this.props.options && this.props.options.align) {
				if (this.props.options.align[colId]) { classExtra.push('_align_' + this.props.options.align[colId]) }
			}
			headerRow.push(<th key={'th' + colId} className={classExtra.join(' ')}>{cols[colId]}</th>)
		}
				
		for (var rIdx in data) {
			var row = data[rIdx]

			var cols = []
			for (var colId in row) { 
				var content = row[colId]
				var classExtra = []
				if (this.props.options && this.props.options.align) {
					if (this.props.options.align[colId]) { classExtra.push('_align_' + this.props.options.align[colId]) }
				}
				if (this.props.options && this.props.options.format && this.props.options.format[colId]) {
					content = formatNumber(Number(content), this.props.options.format[colId])
				}
				cols.push(<td key={'td' + rIdx + colId} className={classExtra.join(' ')}>{content}</td>)
			}
			bodyRows.push(<tr key={'tr' + rIdx} className={(rIdx == data.length - 1 ? "_last" : '')}>{cols}</tr>)
		}
		return (<div className="taTable">
			<table>
				<thead>
					<tr>{headerRow}</tr>
				</thead>
				<tbody>
					{bodyRows}
				</tbody>
			</table>
		</div>)
	}
}

class TalbotHeroDemo extends Component {
	constructor(props) {
		super(props)
		this.state = { 
			curInput: '',
			conversationIdx: 0,
			conversation: [],
			loading: false,
		}
		this.data = talbotData.heroDemo
		if (this.data && this.data[0] && this.data[0].type === 'intro') {
			var intro = this.data[0]

			this.state.conversation = [intro]
			this.state.conversationIdx = 1
		}
	}
	componentDidMount() {
		setTimeout(this.nextInput, 500)
	}
	typeInput = async (input) => {
		const c = 40
		const v = 20
		for (var cIdx in input.split('')) {
			await basicFunctions.sleep(c + Math.random() * v)
			this.setState(prevState => (
				{curInput: prevState.curInput + input[cIdx]}))
		}
	}
	nextInput = async () => {
		console.log('running nextInput')
		const curPair = this.data[this.state.conversationIdx]

		// Populate input
		await this.typeInput(curPair.input)

		// Pause prior to pressing enter
		await basicFunctions.sleep(900 + Math.random() * 300)

		//Clear input
		this.setState({curInput: '', loading: true})
		const newEntry = {
			content: curPair.input,
			dateCreatedUTC: (new Date()).getTime(),
			participant: 0, // Us
		}

		// Add conversation entry for input
		this.setState(prevState => ({ conversation: [...prevState.conversation, newEntry] }))

		// Compute time
		await basicFunctions.sleep(1500 + Math.random() * 500)

		const newReply = {
			content: curPair.output,
			dateCreatedUTC: (new Date()).getTime(),
			participant: 1, // Us
		}
		console.log(newReply)
		this.setState(prevState => ({ loading: false, conversation: [...prevState.conversation, newReply] }))

		// Increment counter & schedule next one
		this.setState(prevState => ({conversationIdx: prevState.conversationIdx + 1}),
			() => {
				if (this.data[this.state.conversationIdx]) {
					setTimeout(this.nextInput, 4000)
				}
			}
		)

		// If there are more, set another timeout
	}
	render() {
		var conversation = []
		for(var eIdx = this.state.conversation.length-1; eIdx>=0; eIdx--) {

			var entry = this.state.conversation[eIdx]
			var content = ""

			if (entry.content) {
				if (entry.content.element === 'table') {
					content = <TalbotTable {...entry.content.props}/>
				}
				else {
					content = entry.content
				}
			}
			const own = entry.participant === 0 ? '_own' : ''

			if (entry.type && entry.type === 'intro') {
				conversation.push(<motion.div positionTransition className="taIntro">
					{content}
				</motion.div>)
			} else {
				conversation.push(<motion.div positionTransition className={"_entry " + own} key={entry.dateCreatedUTC}>
					<div className="_content">{content}</div>
					<div className="_time"><TimeAgo date={entry.dateCreatedUTC}/></div>
				</motion.div>)
			}
		}
		return (
			<div className="talbotHeroDemo">
				<div className="_body">
					{conversation}
				</div>
				<div className="_input">
					<input value={this.state.curInput} disabled={true}/>
					{ this.state.loading ? <div className="_loading"> <ClipLoader size={30} color={'#000000'}/> </div> : <span/> }
				</div>
			</div>
		)
	}
}



export { TextAdventureUI, TalbotHeroDemo, TalbotTable }
