import AuthService from './auth'
const auth = new AuthService();
var config = require('../config/api');
var googleAuthFns = {
 googleResponse: function (redirectUrl, response) {
    const tokenBlob = new Blob([JSON.stringify({access_token: response.credential}, null, 2)], {type : 'application/json'})
    const options = {
       method: 'POST',
       body: tokenBlob,
       mode: 'cors',
       cache: 'default'
    }
    try {
      fetch(config.apiBaseUrl + 'auth', options).then(r => {
        const token = r.headers.get('x-auth-token')
        r.json().then(user => {
          if (token) {
            //this.setState({isAuthenticated: true, user, token})
            //auth.setToken(token, user);
            //auth.setUserInfo(response.user, user);
            auth.setToken(token)
            auth.setUser(user)

            if (redirectUrl === undefined) {
              redirectUrl = '/user'
            }
            if (this.props.history) {
              this.props.history.push(redirectUrl);
            } else {
              window.location = redirectUrl
            }
          }
        }).catch(function(err) {
          console.log('error 1', err)
        })
      })
    }
    catch(e) {
      console.log('error 2')
    }
  },

  onFailure: function (error) {
    console.log(error)
    this.setState({error: true})
  }
}
export default googleAuthFns
