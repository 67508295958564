import React, { useState, Component } from 'react'
import { Icon } from 'react-icons-kit'
import {LoadingScreen, PageNotFound} from './components/basic.js'
import {DemoControlView} from './components/control.js'
import './css/Demo.css'

import {externalLink} from 'react-icons-kit/fa/externalLink'

const uuidv4 = require('uuid/v4')

const appBaseFunctions = require('./appBaseFunctions')

class DemoView extends Component {
   constructor(props) { 
      super(props) 
      const appSettings =  this.props.appSettings
		this.iPhoneContainer = React.createRef()

		// Install the 'with auth' apiCall function we get from upstairs
		if (this.props.apiCall) { // Use authenticated apiCall
			this.apiCall = this.props.apiCall
		}

		// Install all core functions
		for (var item in appBaseFunctions) { this[item] = appBaseFunctions[item].bind(this) }

      this.state = appSettings.initialState
      this.config = appSettings.config
      this.gameDetails = appSettings.gameDetails

      if (this.props.match && this.props.match.params) {
			// Get details from the URL used to access us
			this.urlParams = this.props.match.params
			if (this.urlParams.appDeploymentId) {
				this.gameDetails.appDeploymentId = this.props.match.params.appDeploymentId
			}
	  		if (this.urlParams.appView) { // This code allows any stages[appRoute] render function to know what view it is forming (e.g. CONTROL or DEMO)
				this.state.appView = this.urlParams.appView
			}
		} else if (this.gameDetails && this.gameDetails.liveMode && this.gameDetails.liveMode.demo) { 
			// For when we're called from ProductPage as a live mode demo, use the default demo appConfig
			this.urlParams = {}
			this.urlParams.appConfig = this.gameDetails.liveMode.demo.appConfig
			if (this.gameDetails.liveMode.demo.appDeploymentId) {
				this.urlParams.appDeploymentId = this.gameDetails.liveMode.demo.appDeploymentId
			}
		}

      this.route = 'CONTROL'

		this.state.demoSessionId = uuidv4()
		this.refreshSession = () => {
			this.setState({demoSessionId: uuidv4()})
		}

		if (appSettings.stages['CONTROL']) {
			this.renderDemoControls = appSettings.stages['CONTROL']
		} else {
			this.renderDemoControls = () => { // Default
				return (<DemoControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					refreshSession = {this.refreshSession}
				/>)
			}
		}
   }
	createDemoSession = () => {
      this.apiCall({
				gameId: this.gameDetails.gameId,
				appConfig: this.urlParams.appConfig,
			}, 'deployments/demo/create', (result) => {
			if (result && result.appDeploymentId) {
				this.setState({appDeploymentId: result.appDeploymentId})
				this.gameDetails.appDeploymentId = result.appDeploymentId

				this.apiCall({
						input: {action: 'startGame'}, 
						clientId: uuidv4(), 
						testing: true,
						gameId: this.gameDetails.gameId, 
						appDeploymentId: result.appDeploymentId, 
						route: 'CONTROL'
					}, 'app/' + this.gameDetails.appRoute + '/update', 
					(result) => {})
			}
		})
	}
   componentDidMount() {
      //this.setState(this.state)
	  this.sendUpdate()
      this.periodicUpdate()

		if (this.props.public === true && this.urlParams && this.urlParams.appDeploymentId === undefined) {
			this.createDemoSession()
		}

   }
   componentWillUnmount() {
      clearTimeout(this.timerObj) // Stops periodic update
   }
	render() {
		var urlBase = "/notFound"
		var appDeploymentId
		if (this.urlParams) {
	
			appDeploymentId = this.urlParams.appDeploymentId || this.state.appDeploymentId

			if (!appDeploymentId) { // No app deployment Id
				return (<LoadingScreen message="Launching demo..."/>)
			}

			urlBase = "/app/public/" + this.gameDetails.appRoute + "/" + this.urlParams.appConfig + "/" + appDeploymentId
		}

		const targetWidth = 375
		const targetHeight = 667

		var scale = 1
		if (this.iPhoneContainer && this.iPhoneContainer.current) {
			scale = String(parseFloat(this.iPhoneContainer.current.offsetWidth) / targetWidth)
		}

		const demoControls = (
			<div className="container demoControlContainer">
				<div className="row">
					<div className="col-12">
						{this.renderDemoControls()}
					</div>
				</div>
			</div>)

		const projectorView = (
			<div className="container">
				<div className="row">
					<div className="col-12">
						{/* <div className="demoDisplayControlPanel container">
							<div className="row">
							<div className="col"><span className="_button" onClick={() => this.sendAction('prevStage')}>Previous</span></div>
							<div className="col"><span className="_slide">Slide {this.state.stage + 1}</span></div>
							<div className="col"><span className="_button" onClick={() => this.sendAction('nextStage')}>Next</span></div>
							</div>
						</div>*/}
						<div className="projectorViewContainer blueGradient" id="tour-displayView">
							<iframe className="projectorDisplayView" src={urlBase + "/display"} key={this.state.demoSessionId}/>
						</div>
						<div className="_label">
							<h3 className="">Projector view</h3>
							<div className="demoView_resWarning alert alert-danger">For best results, please use a higher resolution display (e.g. a desktop) to demo in Live Mode</div>
							<p>What the whole class sees. <a href={urlBase + "/display"} target="_catembe_display_new">Fullscreen <Icon icon={externalLink}/></a></p>
						</div>
					</div>
				</div>
			</div>)
		const studentView = (
			<div>
				<div className="iPhoneContainer"> 
					<div className="embed-responsive iPhone student" id="tour-studentView" style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
						{ /* <div className="iPhoneStudentView"> {this.props.sv} </div> */ }
						<div className="iPhoneStudentView" ref={this.iPhoneContainer}>
							<iframe className="" src={urlBase + "/student"} key={this.state.demoSessionId} 
								style={{transform: 'scale(' + scale + ')', 
									width: targetWidth + 'px',
									height: targetHeight + 'px'
								}} />
						</div>
					</div>
				</div>
				<div className="_label">
					<h3>Student view</h3>
					<p>What students see. <a href={urlBase + "/student"} target="_catembe_student_new">Fullscreen <Icon icon={externalLink}/></a></p>
					<a onClick={() => this.setState({hideStudentView: true})} tabindex="1">Hide student view</a>
				</div>
			</div>)

		if (!this.state.liveMode) {
			return (
				<div className={"container demoView liveModeDemoContainer asyncMode"}>
					<div className="row">
						<div className="col">
							{studentView}
							{demoControls}
						</div>
					</div>
				</div>)
		}

		// Live mode
		return (
			<div className={"container demoView liveModeDemoContainer liveMode"}>
				<div className="row">
					{ !this.state.hideStudentView ? 
						<div className="col-lg-4 col-md-12 _lhs">
							{studentView}
						</div> : 
						<div className="col-lg-1 _lhs_collapsed">
							<a onClick={() => this.setState({hideStudentView: false})} tabindex="1"><b>Show student view</b></a>
						</div>}
					<div className={ this.state.hideStudentView ? "col-lg-11 col-md-12 _rhs" : "col-lg-8 col-md-12 _rhs" }>
						{projectorView}
						{demoControls}
					</div>
				</div>
			</div>
		)
	}
}


export default DemoView
