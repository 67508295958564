import React, { Component } from 'react'
import ReactGA from "react-ga"
import { CatembeNavBar } from './components/basic.js'
import { ShowcaseCTAFooter } from './components/frontpage.js'

import './css/FrontPage.css'
import './css/CaseStudy.css'

class CaseStudyPage extends Component {
	render() {
		return (
			<div>
				<CatembeNavBar/>
				<div className="container caseStudy">
					<div className="row">
						<div className="col _section">
							<div className="smallCapsHeading">CASE STUDY</div>
							<h1 className="_title">
								<span className="_client">University of Melbourne</span> / 
								<span className="_discipline">Marketing</span>
							</h1>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Introduction</h2>
							<p>The <a href="https://www.unimelb.edu.au" target="_new_">University of Melbourne</a> is a public research university located in Melbourne, Australia. Founded in 1853, it is Australia's second oldest university and the oldest in Victoria. It is the top ranked university in Australia and number 32 in the world.</p>
							<p>Throughout 2020, Catembe was piloted in a final-year Management and Marketing subject, <a href="https://handbook.unimelb.edu.au/2019/subjects/busa30000" target="_new_">Business Judgement (BUSA30000)</a>. The subject is a final-year Management and Marketing capstone in the Bachelor of Commerce, with approximately 200 students across 5 streams, and three lecturers in the teaching team, led by Associate Professor <a href="https://findanexpert.unimelb.edu.au/profile/1692-andre-sammartino" target="_new_">Andre Sammartino</a>, who is also Deputy Head of Department (Teaching & Learning).</p>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<div className="_subjectInfo">
								<h3 className="_subjectName">
									<a href="https://handbook.unimelb.edu.au/2019/subjects/busa30000" target="_new_">
										<span>Business Judgement</span>
									</a>
									<span className="_code">BUSA30000 </span>
								</h3>
								<p>This subject serves as a capstone for the Management and Marketing majors within the Bachelor of Commerce. As such, the aim is to integrate students’ knowledge of core business functions and analytical tools and apply these to the problems faced by modern organisations and decision-makers. Students will be exposed to these problems through simulation tasks and case analyses. Students will be challenged to form and act upon judgments around strategic directions, marketing priorities, and entrepreneurial opportunities.</p>
							</div>
							
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<p>Delivery of this subject involved weekly, 2-hour seminars, approximately 30-50 students per stream. It was impacted by COVID-19 shutdowns, with all classes transitioned to remote delivery (via Zoom) in mid-March 2020 (Week 4 of Semester 1). Semester 2 classes were all delivered online.</p>
							<p>There were 210 and 250 enrolled students in Semesters 1 and 2 respectively.</p>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Overview</h2>
							<p>A number of Catembe games were introduced into the subject. As a final-year capstone, the aim was to refresh student's knowledge of various frameworks that were covered in previous subjects. The subject also featured a multi-week business simulation by <a href="https://www.capsim.com/">Capsim</a>.</p>

							<div className="_infoTableContainer">
								<table className="_info">
									<thead>
										<tr>
											<td>Topic</td>
											<td>Single-player game (asynchronous)</td>
											<td>In-class multiplayer game (synchronous)</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> <div className="_bubble">Porter's Five Forces</div> </td>
											<td>Timed card-sorting activity</td>
											<td>Lecturer play-through with input and discussion from class (5-10 mins only)</td>
										</tr>
										<tr>
											<td> <div className="_bubble">Porter's Generic Strategies</div> </td>
											<td>Battleships-style game, with companies and products hidden on a matrix with uniqueness and market scope as dimensions</td>
											<td>Multiplayer version of game, where each round of firing is shown on a heatmap and discussed</td>
										</tr>
										<tr>
											<td> <div className="_bubble">Segmentation & Targeting</div> </td>
											<td>Tinder-style game where students target various products to 4 marketing personas</td>
											<td>Multiplayer version of game, where student's decision for each product are shown to the class and discussed</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p>The asynchronous single-player version was released to students as an optional activity in the week prior to when it would be covered in classes. It was designed to be accessed via the Canvas LMS, quick to play (under 5 mins) and optimised for mobile so they can easily engage with the content prior to class.</p>
							<p>In classes, a live, multiplayer version of each game was played, which was designed for 20-40 minutes of guided play and discussion in real-time. This as not only to provide feedback on answers within the game, but to explore together some of the more subjective elements of the theories and frameworks under consideration.</p>




						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Overall Results</h2>

							<CaseStudyQuote 
								text="The impact of these games was most definitely positive, and it certainly engaged the students. It got them to interact with some ideas they hadn't seen in a while, and it gave me a greater confidence that I didn't have to go back over them again. It absolutely delivered on what we wanted, which was to get them up to speed quickly on these concepts."
								author={{name: "A/Prof Andre Sammartino", position: "Lecturer and Subject Co-ordinator"}}/>

							<CaseStudyQuote 
								text="Students can get good grades on these topics and still not fully understand the basic fundamentals of the theory, so by putting it into a simple game like this and getting them to engage directly, it really helped them to improve their understanding."
								author={{name: 'Sol Abdulhak', position: 'Lecturer'}} />

							<CaseStudyQuote 
								text="The Catembe games really helped my students stay engaged, excited, and motivated about this subject."
								author={{name: 'Thomas Whitford', position: 'Lecturer'}} />

							<CaseStudyQuote 
								text="Overall, I loved the concept of the games. There were insightful and supported my learning. I definitely learned more from the games than by reading alone; it's more like 'real life'. I would really love it if my other subjects did this!"
								author={{name: 'Nicole', position: 'Student', student: true}} />

							<CaseStudyQuote 
								text="This was my first class with interactive games. I want this in all of my classes!"
								author={{name: 'Skye', position: 'Student', student: true}} />

						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Games</h2>
							<p>In this section, we highlight a few of the most impactful games deployed in the subject.</p>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h3>Battleships (single-player)</h3>

							<div className="iPhoneContainer" style={{width: '320px', marginBottom: '40px', marginTop: '30px'}}>
								<div className="embed-responsive iPhone" 
									style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
									<video src="https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Battleships_v2.mp4" autoPlay={true} muted={true} loop={true}/>
								</div>
								<div className="_caption">Single-player version of Battleships, for teaching Porter's Generic Strategies.</div>
							</div>

							<p className="mt-5">In this game, we re-purpose the classic game of Battleships to teach <a href="https://en.wikipedia.org/wiki/Porter%27s_generic_strategies">Porter's Generic Strategies</a>. With a battlefield determined by market scope and level of uniqueness, students apply their understanding of these concepts as they attempt to find where real companies are lurking. They earn points for each direct hit. The fewer the shots taken, the higher the score.</p>
							<div className="text-center">
								<div className="_infoTableContainer _slim">
									<table className="_info">
										<thead> <tr> <td></td> <td>Sessions<sup>1</sup></td> <td>Completions</td> </tr> </thead>
										<tbody>
											<tr>
												<td className="nowrap">Semester 1</td>
												<td className="_num">114</td>
												<td className="_num">
													<div>40</div>
													<div className="_subtitle">0.35 / session</div>
												</td>
											</tr>
											<tr>
												<td className="nowrap">Semester 2</td>
												<td className="_num">168</td>
												<td className="_num">
													<div>171</div>
													<div className="_subtitle">1.02 / session</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<p>A number of improvements were made between semesters, a few of which were designed to encourage students to complete the game (e.g., after numerous misses, they received some useful hints, and had the option of just seeing the answer and continuing).</p>

							<h4>Feedback</h4>
							<CaseStudyQuote 
								text="The way this game is structured and the nature the game itself really fits well with the concepts we were teaching. The theory is right there in front of them: they are looking at real-life companies, making mistakes, and learning from those mistakes."
								author={{name: 'Sol Abdulhak', position: 'Lecturer'}} />

							<CaseStudyQuote 
								text="This game made me realise I didn't know these businesses very well, so there was a good learning moment."
								author={{name: 'James', position: 'Student', student: true}} />


						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h3>Battleships (multiplayer)</h3>
							<div className="_imageContainer text-center mb-4">
								<img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Images/Battleships_VB.png"/>
								<div className="_caption">Heat map from the multiplayer version of Battleships, generated from BUSA30000 students' assessment of <a href="https://en.wikipedia.org/wiki/Victoria_Bitter" target="_new_">Victoria Bitter</a>, a popular Australian lager. Lecturer's answer is the green square.</div>
							</div>

							<p>The multiplayer version of this game had a novel social dimension: in class, the class could see a heat map of where other students had fired, which became the subject of a class discussion. The resulting conversation was especially interesting when there was significant disagreement between students.</p>

							<h4>Survey results</h4>
							<p>A quick survey was presented to students immediately after the in-class activity had ended:</p>
							<div className="text-center">
								<div className="_infoTableContainer _slim">
									<table className="_info">
										<thead> <tr> <td>Survey Prompt</td> <td>Rating (/ 5)</td> </tr> </thead>
										<tbody>
											<tr>
												<td className="">This game was engaging</td>
												<td className="_num"> 4.24 (n = 154)</td>
											</tr>
											<tr>
												<td className="">This game contributed to my understanding of the topic</td>
												<td className="_num"> 4.35 (n = 152)</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
				
							<h4>Feedback</h4>
							<p className="mb-5">Overall, students thought that the battleships gameplay was a good fit for the Generic Strategies framework, and found the single player version to be both engaging and educational. But they were significantly more enthusiastic about the game when it was used as an in-class activity. For many, the social aspect of seeing their classmates' opinions was by far the most valuable piece.</p>

							<CaseStudyQuote 
								text="I loved delivering this game; it was probably one of the best sessions I've run for the subject. The heat map got students to see how everyone else was thinking, and it really stimulated engaging conversation and discussion."
								author={{name: 'Sol Abdulhak', position: 'Lecturer'}} />

							<CaseStudyQuote 
								text="This was really fun. I liked that you repurposed a childhood game with marketing content! It got the whole class guessing, and it was interesting to see how your classmates perceive things."
								author={{name: 'Jiang', position: 'Student', student: true}} />

							<CaseStudyQuote 
								text="The heat map was really cool. There's the knowledge that you're participating together, and you can see what your classmates are thinking. It's useful to get immediate and direct feedback on what you did wrong or when your classmates are saying something different."
								author={{name: 'James', position: 'Student', student: true}} />
							

						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h3>Porter's Five Forces</h3>

							<div className="container mb-4">
								<div className="row">
									<div className="col-lg-6">
										<p>This single-player game is short, sharp and challenging. It was popular with students, with multiple plays observed per session.</p>
										<div className="text-center">
											<div className="_infoTableContainer _slim">
												<table className="_info">
													<thead> <tr> <td></td> <td>Sessions<sup>1</sup></td> <td>Completions</td> </tr> </thead>
													<tbody>
														<tr>
															<td className="nowrap">Semester 1</td>
															<td className="_num">137</td>
															<td className="_num">
																<div>446</div>
																<div className="_subtitle">3.3 / session</div>
															</td>
														</tr>
														<tr>
															<td className="nowrap">Semester 2</td>
															<td className="_num">142</td>
															<td className="_num">
																<div>1,018</div>
																<div className="_subtitle">7.2 / session</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<p>The key change made between semesters was the addition of the 'class high score' to the results screen, which seemed to have a considerable effect on some students' usage. The eventual class high score was achieved after 172 attempts, and the highest number of attempts was 365!</p>


									</div>

									<div className="col-lg-6">

										<div className="iPhoneContainer">
											<div className="embed-responsive iPhone" 
												style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
												<video src="https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Cards_v2.mp4" autoPlay={true} muted={true} loop={true}/>
											</div>
										</div>

									</div>
								</div>
							</div>
							<h4>Feedback</h4>
							<CaseStudyQuote 
								text="I found this engaging. It was a different way to interact with the material, serving as a 'mini refresher' of the topic. This style of game would be really useful for exam revision." 
								author={{name: 'James', position: 'Student', student: true}} />
							<CaseStudyQuote 
								text="I hadn't done the readings at this stage so this game certainly helped me get up to speed quickly."
								author={{name: 'Skye', position: 'Student', student: true}} />
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Using the platform</h2>
							<div className="_imageContainer text-center mb-4">
								<img className="img-fluid" src="https://d3tx1wevfc318r.cloudfront.net/Images/Battleships_DemoView.png"/>
								<div className="_caption">The demo view of Catembe, used to run the multiplayer sessions.</div>
							</div>
							<CaseStudyQuote 
								text="I think it was as easy to use as it was going to be! The live sessions were easy to run and required very little practice. If I had to do it again now, I'm confident I could pick it up quickly."
								author={{name: "A/Prof Andre Sammartino", position: "Lecturer and Subject Co-ordinator"}}/>
							<CaseStudyQuote 
								text="Before the first time I used it, I took an hour to run through it in detail. But once I understood how it worked, I absolutely loved it, it was fun and easy to run."
								author={{name: 'Sol Abdulhak', position: 'Lecturer'}} />

						</div>
					</div>


					<div className="row">
						<div className="col _section">
							<h2>Conclusion</h2>
							<p>The pilot of the Catembe games in BUSA30000 was highly successful in engaging students and contributing positively to learning outcomes for students. The games helped the teaching team avoid low-value re-teaching of previously learned topics, instead revisiting them in a highly engaging and effective way that challenged students to apply and extend the concepts in new and interesting ways.</p>
							<p>With the COVID-19 related disruption, this pilot also demonstrated that these activities could be used effectively across both in-class and online settings.</p>
							<CaseStudyQuote 
								text="I'm delighted we used the games and delighted to continue using them. We were hugely fortunate it worked so well in the online environment. [The in-class games] were a really nice way of livening up a classroom!'"
								author={{name: "A/Prof Andre Sammartino", position: "Lecturer and Subject Co-ordinator"}}/>

						</div>
					</div>

					<div className="_footnotes">
						<h4>Footnotes</h4>
						<div>1: All games were accessed anonymously by students, so we don't have usage information in terms of students. But we expect that sessions will roughly correspond with unique users, as all uses from the same browser are normally counted as part of the same session.</div>
					</div>

				</div>
				<ShowcaseCTAFooter {...this.props}/>
			</div>
		)
	}
}


class CaseStudyQuote extends Component {
	render() {
		return (
			<div className={"_quote" + (this.props.author.student === true ? " _student" : "")}>
				<div className="_body">"{this.props.text}"</div>
				<div className="_author">
					<div className="_name">{this.props.author.name}</div>
					<div className="_position">{this.props.author.position}</div>
				</div>
			</div>

		)
	}
}


export default CaseStudyPage
