import React, { Component } from 'react'
import ReactGA from "react-ga"
import { CatembeNavBar } from './components/basic.js'
import { ShowcaseCTAFooter } from './components/frontpage.js'

import './css/FrontPage.css'

class ShowcasePage extends Component {
	render() {
		return (
			<div>
				{ (this.props.header === undefined || this.props.header === true) ? <CatembeNavBar login={false}/> : <span/> }
				<div className="fatBottom">
					{this.props.children}
				</div>
				{ (this.props.footer === undefined || this.props.footer === true) ? <ShowcaseCTAFooter {...this.props}/> : <span/> }
			</div>
		)
	}
}
export default ShowcasePage
