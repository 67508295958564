import React from 'react'

const table_pl = [
	{item: 'Sales', val: 234770},
	{item: 'COGS', val: 164339},
	{item: 'Wages', val: 51665},
	{item: 'Marketing', val: 14974},
	{item: 'Net Profit', val: 3792},
]
const table_bs = [
	{item: 'Cash', val: 25830},
	{item: 'Loan', val: 0},
	{item: 'Retained Profits', val: 25830},
]


const table_acct_pl = [
	{item: 'Revenue', y1: 107, y2: 98, y3: 96, y4: 48},
	{item: 'Wages', y1: 56, y2: 64, y3: 73, y4: 93},
	{item: 'Depreciation', y1: 28, y2: 20, y3: 17, y4: 9},
	{item: 'Net Profit', y1: 23, y2: 14, y3: 6, y4: -54},
]

const table_acct_bs = [
	{item: 'Cash', y1: 26, y2: 37, y3: 39, y4: 27},
	{item: 'A/R', y1: 21, y2: 22, y3: 53, y4: 27},
	{item: 'PPE', y1: 135, y2: 101, y3: 89, y4: 89},
	{item: 'A/P', y1: 4, y2: 5, y3: 7, y4: 8},
	{item: 'Loan', y1: 36, y2: 36, y3: 49, y4: 64},
	{item: 'Retained Profit', y1: 97, y2: 74, y3: 80, y4: 26},
	{item: 'Share Capital', y1: 45, y2: 45, y3: 45, y4: 45},
]

const table_acct_roa = [
	{item: 'EBIT', y2: 14, y3: 6, y4: -54},
	{item: 'Assets', y2: 160, y3: 181, y4: 143},
	{item: 'ROA', y2: '8.75%', y3: '3.31%', y4: '-37.76%'},
]

const table_acct_depn = [
	{item: 'Facility 1', y1: 9, y2: '-', y3: '-', y4: '-'},
	{item: 'Facility 2', y1: 6, y2: 7, y3: '-', y4: '-'},
	{item: 'Facility 3', y1: 13, y2: 13, y3: 17, y4: 9},
	{item: 'Total', y1: 28, y2: 20, y3: 17, y4: 9},
]

const talbotData = {
	acctDemo: [
		{ 
			type: 'intro',
			content: (<div>
			Modius Pty Ltd. declared bankruptcy in the middle of 2019. You are a financial analyst who has been hired by the shareholders to investigate the main causes of failure. You will do this by analysing previously issued financial statements and asking any relevant questions. Were there any early warning signs? What could have been done differently? Prepare a short 1000-word report on your conclusions.
			</div>)
		},
		{input: 'view the income statement 2015-2018',
			output: {
				element: 'table', props: {
					data: table_acct_pl, 
					options: {
						columns: {item: 'Item ($m)', y1: '2015', y2: '2016', y3: '2017', y4: '2018'}, 
						align: {y1: 'r', y2: 'r', y3: 'r', y4: 'r'}, 
					}
				} 
			}
		},
		{input: 'view balance sheet over last 4 years', 
			output: {
				element: 'table', props: {
					data: table_acct_bs, 
					options: {
						columns: {item: 'Item ($m)', y1: '2015', y2: '2016', y3: '2017', y4: '2018'}, 
						align: {y1: 'r', y2: 'r', y3: 'r', y4: 'r'}, 
					}
				} 
			}
		},
		{input: 'what was ROA in last 3 years?', 
			output: {
				element: 'table', props: {
					data: table_acct_roa, 
					options: {
						columns: {item: 'Item ($m)', y2: '2016', y3: '2017', y4: '2018'}, 
						align: {y1: 'r', y2: 'r', y3: 'r', y4: 'r'}, 
					}
				} 
			}
		},
		{input: 'why did PPE decrease in 2016 and 2017?', output: 'There were two major asset sales in 2016 and 2017 involving PPE with a combined value of $38m.'},
		{input: 'view detail about depreciation expense', 
			output: {
				element: 'table', props: {
					data: table_acct_depn, 
					options: {
						columns: {item: 'Item ($m)', y1: '2015', y2: '2016', y3: '2017', y4: '2018'}, 
						align: {y1: 'r', y2: 'r', y3: 'r', y4: 'r'}, 
					}
				} 
			}
		},
	],
	heroDemo: [
		{input: 'view the most recent profit & loss statement',
			output: {
				element: 'table', props: {
					data: table_pl, 
					options: {
						columns: {item: 'Item', val: 'Month 1'}, 
						align: {val: 'r'}, 
						format: {val: 'dollar_full'},
					}
				} 
			}
		},
		{input: 'what is the breakdown of marketing spend?', output: '84% ($12,578) of total marketing spend was on print and in-store advertising, with 16% on online/digital marketing ($2,396).'},
		{input: 'Increase marketing spend to $30k, 50% for online', output: 'Budgeted marketing spend is now $30,000. 50% ($15,000) will be spent on print and in-store advertising and 50% on online/digital marketing ($15,000).'},
		{input: 'show me the current balance sheet',
			output: {
				element: 'table', props: {
					data: table_bs, 
					options: {
						columns: {item: 'Account', val: 'Month 1'}, 
						align: {val: 'r'}, 
						format: {val: 'dollar_full'},
					}
				} 
			}
		},
		{input: 'borrow cash', output: <div>[ Get a loan ]<br/>How much do you want to borrow? (Max $200,000)</div>},
		{input: '$50k', output: <div>We can do $50,000. The loan fee will be $1,250.<br/>What type of loan do you want?<br/> [1] Fixed Rate (6.50%)<br/> [2] Variable Rate (currently 4.02%)</div>},
		{input: '2', output: 'You have successfully borrowed $50,000.'},
	],
}
export { talbotData }
