import React from 'react'
import '../css/Battleships.css'
import { BasicControlView, ControlButton, DemoControlView } from '../components/control.js'
import { IntroPage, Title, Description, StudentAccessInstructions } from '../components/basic.js'

import { FrontendAppSettings } from './FrontendAppSettings'
//import { GameTimeLeft } from '../components/timing'

import { BattleShips, BattleShipsGrid } from '../components/battleships'

const gameDetails = { // Items specific to this deployment
	gameId: 'battleships', // Unique identifier for this game
	appRoute: 'battleships', // Determines url of app
	//stageNames: ['a', 'b'], // Optional -- for naming the sections of the control view

	discipline: "Marketing", // Shown on frontpage tile and product page
	title: "Generic Strategy Battleships", // Used as heading on Product Page
	veryShortTitle: "Battleships", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Generic Strategies", // Used as interactive name on FrontPage
	shortDescription: "Battleships-style game based on Porter's generic strategies", // Description on FrontPage

	videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Battleships_v2.mp4", // Video on frontPage and Product page
	//asyncDemoUrl: "/app/public/battleships/async_demo/battleships_demo/student",

	liveMode: {
		studentVideoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/Battleships_LiveModeDemo_Student.mp4',
		displayVideoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/Battleships_LiveModeDemo_Display.mp4',
		longDescription: <p>In <span className="liveMode">live mode</span>, students are shown a heat map of shots taken after each turn, presenting a brilliant opportunity to pause and discuss as a class. This game is known to elicit plenty of discussion from students, particularly when there is a significant amount of disagreement!</p>,
	},
	longDescription: [
		<p>In this game, we re-purpose the classic game of Battleships to teach <a href="https://en.wikipedia.org/wiki/Porter%27s_generic_strategies" target="_moreInfo">Porter's Generic Strategies</a>.</p>,
		<p>With a battlefield determined by market scope and level of uniqueness, students apply their understanding of these concepts as they attempt to find where real companies are lurking. They earn points for each direct hit. The fewer the shots taken, the higher the score!</p>,
	],
	topicsCovered: ['Generic strategies', 'Competitive advantage']
}

var battleships = new FrontendAppSettings(gameDetails)

battleships.config.fullscreen = true
battleships.config.studentWidescreenView = true
//battleships.config.noStudentPeriodicUpdate = false
battleships.config.studentHeader = false

const dims = {x: 8, y: 8}
battleships.content = dims

battleships.appConfigs = {
	async: {x: 6, y: 6},
	live: {x: 6, y: 6},
}

battleships.lifecycle.STUDENT.onAuthentication = function() {
}

battleships.lifecycle.STUDENT.componentWillMount = function() {
	//this.sendInput({action: 'enrol', trigger: 'componentWillMount'})

	this.onFire = (selectedCell) => {
		this.sendInput({action: 'fire', coords: selectedCell})
	}
	this.onReset = () => {
		this.sendInput({action: 'resetGame'})
	}
	this.next = () => {
		this.sendInput({action: 'next'})
	}
	this.onSelectCell = (selectedCell) => {
		if (this.state.liveMode) {
			this.sendInput({action: 'selectCell', coords: selectedCell})
		}
	}
	this.showAnswer = () => {
		this.sendInput({action: 'showAnswer'})
	}
}

battleships.stages = {
	CONTROL: function() { // Called by render() function of controlView
		var numWaiting = 0
		if (this.state.firedCells) {
			numWaiting = Object.values(this.state.firedCells).reduce((a,b) => a + b, 0)
		}

		const extraControlPanelItems = [
			<div className="col" id="tour-shotsFired">
				<div className="bigNumber">{numWaiting}</div>
				<div className="bigNumberSubtitle">Shots fired</div>
				{/*<a className="bigNumberAction" disabled={numWaiting === 0} onClick={() => { this.sendAction('showPendingResults') }}> Release results </a>*/}
			</div>,
			<div className="col" id="tour-showResults">
				<ControlButton onClick={() => { if(window.confirm("This will show the correct cell to students. Are you sure?")) { this.sendAction('showAnswer') }
					}} dangerous={true}> Show answer </ControlButton>
			</div>,
			<div className="col" id="tour-endSession">
				<ControlButton onClick={() => { if(window.confirm("This will end the game and show students a GAME OVER screen. Are you sure?")) { this.sendAction('endLiveGame') }
					}} dangerous={true}> End live session </ControlButton>
			</div>,
		]

		const selectionView = (
				<div className="">
					<div className="row">
						<div className="col">
							<div className="smallCapsHeading">Student Selections</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
						</div>
					</div>
					<div className="row">
						<div className="col-7 mt-4 mb-4 text-center">
								{ this.state.curTurn && this.state.curTurn.company ?
										<div className="_target">
											<div className="_label">Target {this.state.curTurn.turnIdx + 1} of {this.state.numCompanies}</div>
											<div className="_value">{this.state.curTurn.company}</div>
										</div>
									: <span/> }
							<div className="buttonSubtitle mb-2 text-center">This grid shows which cells students currently have <b>selected</b></div>
							<BattleShipsGrid
								x={this.content ? this.content.x : dims.x}
								y={this.content ? this.content.y : dims.y}
								controlView={true}
								gridData={this.state.selectedCells}
								sendControlAction={this.sendControlAction}
								correctAnswer={this.state.controlCurAnswer}/>
						</div>
						<div className="col-5 pt-5">
							<table className="bsStatusTable">
								<tbody>
									{ this.state.selectedCells ?
										<tr>
											<td className="_label">
												<div className="_title">Selected</div>
												<p>Number of students who have selected a cell on the grid</p>
											</td>
											<td className="_value">
												<div className="bigNumber">{Object.values(this.state.selectedCells).reduce((a,b) => a + b, 0)}</div>
											</td>
										</tr> : <tr/> }
									{ this.state.firedCells ?
										<tr>
											<td className="_label">
												<div className="_title">Waiting for result</div>
												<p>Number of students who have hit <b>fire</b> and are waiting for a result</p>
												<ControlButton disabled={numWaiting === 0} onClick={() => { this.sendAction('showPendingResults') }}> Show hidden results </ControlButton>
											</td>
											<td className="_value">
												<div className="bigNumber">{numWaiting}</div>
											</td>
										</tr> : <tr/> }
									{ this.state.numHits ?
										<tr>
											<td className="_label">
												<div className="_title">Hits</div>
												<p>Number of students who have hit the correct cell</p>
											</td>
											<td className="_value">
												<div className="bigNumber">{this.state.numHits}</div>
											</td>
										</tr> : <tr/> }
								</tbody>
							</table>
						</div>
					</div>
				</div>)

		return (
			<div>
				<BasicControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					stages = {this.gameDetails.stageNames}
					extraControlPanelItems = {extraControlPanelItems}
					refreshSession = {this.refreshSession}
					appView = {this.state.appView}
				>{selectionView}</BasicControlView>
			</div>)
	},
	STUDENT: {
		'0': function() {
			return (
				<div className="bsContainer">
					<BattleShips
						x={this.content.x}
						y={this.content.y}
						sendFeedback={this.sendFeedback}
						turn={this.state.curTurn}
						active={this.state.gameActive}
						results={this.state.results}
						numCompanies={this.state.numCompanies}
						results={this.state.results}
						onSelectCell={this.onSelectCell}
						onFire={this.onFire}
						onReset={this.onReset}
						next={this.next}
						liveMode={this.state.liveMode}
						showAnswer={this.showAnswer}
					/>
				</div>
			)
		},
	},
	DISPLAY: {
		'0': function() {
			return (
				<IntroPage>
					<Title>{this.gameDetails.title}</Title>
					<Description>{this.content.description}</Description>
					<StudentAccessInstructions app={this}/>
				</IntroPage>
			)
		},
		'1': function() {
			return (
				<div className="bsDisplay">
					<div className="_companyName">{this.state.curTurn ? this.state.curTurn.company : ""}</div>
					<div className="displayBsGrid">
						<BattleShipsGrid x={this.content.x} y={this.content.y} controlView={true} gridData={this.state.lastFiredCells} correctAnswer={this.state.curAnswer} sendControlAction={this.sendControlAction}/>
					</div>
				</div>
			)
		},
		'2': function() {
			return (
				<div className="bsDisplay">
					<div className="_companyName">{this.state.curTurn ? this.state.curTurn.company : ""}</div>
					<div className="displayBsGrid">
						<BattleShipsGrid x={this.content.x} y={this.content.y} controlView={true} gridData={this.state.lastFiredCells} correctAnswer={this.state.curAnswer} sendControlAction={this.sendControlAction}/>
					</div>
				</div>
			)
		},
		'3': function() {
			return (
				<div className="bsDisplay">
					<div className="_companyName">{this.state.curTurn ? this.state.curTurn.company : ""}</div>
					<div className="displayBsGrid">
						<BattleShipsGrid x={this.content.x} y={this.content.y} controlView={true} gridData={this.state.lastFiredCells} correctAnswer={this.state.curAnswer} sendControlAction={this.sendControlAction}/>
					</div>
				</div>
			)
		},
		'4': function() {
			return (
				<div className="bsDisplay">
					<div className="_companyName">{this.state.curTurn ? this.state.curTurn.company : ""}</div>
					<div className="displayBsGrid">
						<BattleShipsGrid x={this.content.x} y={this.content.y} controlView={true} gridData={this.state.lastFiredCells} correctAnswer={this.state.curAnswer} sendControlAction={this.sendControlAction}/>
					</div>
				</div>
			)
		},
		'5': function() {
			var topScores = []
			for (var tsIdx in this.state.topScores) {
				topScores.push(
					<div className="topScore buttonGradient_redYellow" key={"topScore" + tsIdx}>
						<div className="_label">Anonymous</div>
						<div className="_value">{this.state.topScores[tsIdx]}</div>
					</div>)
			}
			return (
				<div className="topScores">
					<div className="display-4 mb-4">High scores</div>
					<div className="topScoreList">
						{ topScores.length === 0 ? <div>Loading...</div> : topScores }
					</div>
				</div>
			)
		},
	},
}

export { battleships }
