import React, {Component} from 'react'
import {FeedbackBox} from './basic.js'
import { motion } from 'framer-motion'
import { Icon } from 'react-icons-kit'
import {check} from 'react-icons-kit/fa/check'
import {star} from 'react-icons-kit/fa/star'
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle'
//import Reward from 'react-rewards'
import '../css/Sudoku.css'

class SudokuDisplayView extends Component {
	constructor(props) { 
		super(props) 
	}
	componentDidMount() {
	}
	render() {
		if (!this.props.gameActive && !this.props.finished) {
			return (<div className="sudokuDisplayStartPrompt text-center">
				<motion.button
					type="button" 
					whileTap={{ scale: 0.9 }}
					transition={{ duration: 0.1 }}
					className="btn actionButton buttonGradient_blueGreen mt-5"
					onClick={this.props.onGameStart}>
						Start the game
				</motion.button>
			</div>)
		}
		return (
			<div className="sudokuDisplayView">
				{ this.props.teamsMode ? 
					<SudokuTeamsSummary {...this.props} /> :
					<SudokuPuzzle {...this.props} displayMode={true}/> }
			</div>
		)
	}
}
class ElementIcon extends Component {
	render() {
		const item = this.props.item
		var elementIcon = <div className="elementIcon"/>
		for (var tIdx in item.type) {
			const tag = item.type[tIdx]
			if (tag.substr(0, 8) === 'section_') {
				var text = tag.substr(8, 1).toUpperCase() + tag.substr(9)
				var cls = text.toLowerCase()
				if (cls === 'sum') { text = "Σ" }
				if (cls === 'cf') { text = "CF" }
				elementIcon = <div className={"elementIcon " + cls} >{text}</div>
				break
			}
		}
		return elementIcon
	}

}

class SudokuTeamsSummary extends Component {
	render() {
		const MAX_TEAMS_DISPLAYED = 20
		var table = []
		var thead = [<th key={'head' + this.props.currentQn} className="_cell _lbl _teamName">Team</th>]
		var tbody = []
		for (var tIdx in this.props.teams.slice(0, MAX_TEAMS_DISPLAYED)) {
			const last = parseInt(tIdx) === (this.props.teams.length - 1)
			thead.push(<th key={'head' + this.props.currentQn + tIdx} className={"_cell _headerRow _teamName" + (last ? " _last" : "")}>{Number(tIdx) + 1}</th>)
		}
		var itemLookup = {}
		for (var iIdx in this.props.items) {
			itemLookup[this.props.items[iIdx].id] = this.props.items[iIdx]
		}

		for (var itemName in this.props.teamStatus) {

			var row = []
			var spl = itemName.split('_')
			var year = spl.slice(-1).join('')
			var item = spl.slice(0, -1).join('_')

			if (item && year) {
				if (itemLookup[item]) {
					//row.push(<td className="_cell _lbl">{year}</td>)
					row.push(<td key={'cell' + this.props.currentQn + item + year} className="_cell _lbl"><ElementIcon item={itemLookup[item]}/> {itemLookup[item].name}</td>)

					for (var dIdx in this.props.teamStatus[itemName].slice(0, MAX_TEAMS_DISPLAYED)) {
						const teamStatus = this.props.teamStatus[itemName][dIdx]
						const cell = (<td key={'rcell' + this.props.currentQn + item + year + dIdx} className={"_cell _result _" + teamStatus}>
							{teamStatus === 'first' ? 
								<motion.div initial={{ scale: 0, rotate: -120 }} animate={{scale: [0, 1.2, 1], rotate: [-120, 5, 0]}} transition={{ duration: 0.5, times: [0, 0.3, 1] }}>
									<Icon icon={star} size={24}/>
								</motion.div>
								: <span/>}
							{teamStatus === 'correct' ? <Icon icon={check} size={24}/> : <span/>}
						</td>)
						row.push(cell)
					}
				}
			}
			tbody.push(<tr key={'row' + this.props.currentQn + item}>{row}</tr>)
		}

		return (
			<div>
				<div className="sudokuPuzzle" key={this.props.currentQn}>
					{ this.props.numQns > 1 ? 
						<SudokuProgressBar currentQn={this.props.currentQn} numQns={this.props.numQns} onBack={this.props.liveMode === false ? this.props.onBack : null}/> : <span/> }

					<table className="sudokuTeamsSummary">
						<thead>
							<tr>{thead}</tr>
						</thead>
						<tbody>
							{tbody}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

class SudokuPuzzle extends Component {
	//constructor(props) { super(props) }
	updateEntry = (item, value) => {
		this.props.onUpdate(item, value)
	}
	render() {
		var headerRow = [<div className="_cell _lbl"/>]
		if (this.props.team) {
			var headerRow = [<div className="_cell _lbl _teamName">Team {Number(this.props.team.id) + 1}</div>]
		}

		var footerRow = [<div className="_cell _lbl"/>]
		for (var yIdx in this.props.years) {
			const last = parseInt(yIdx) === (this.props.years.length - 1)
			const complete = this.props.complete
			headerRow.push(<div className={"_cell _header" + (last ? " _last" : "") + (complete ? " _complete" : "")}>{this.props.years[yIdx]}</div>)
			footerRow.push(<div className={"_cell _footer" + (last ? " _last" : "") + (complete ? " _complete" : "")}/>)
		}

		if (!this.props.values) {
			return <span/>
		}

		var contentRows = []
		var heading = ""
		for (var iIdx in this.props.items) {
			const itemRef = this.props.items[iIdx]
			const hidden = itemRef.type && itemRef.type.indexOf('hidden') >= 0


			if (this.props.values[itemRef.id] && !hidden) { // Item is in question subset

				var contentRow = [<div className="_cell _lbl"><ElementIcon item={this.props.items[iIdx]}/> {this.props.items[iIdx].name}</div>]
				for (var yIdx in this.props.years) {
					const last = parseInt(yIdx) === (this.props.years.length - 1)
					const key = itemRef.id + '_' + this.props.years[yIdx]
					const itemKey = [itemRef.id, this.props.years[yIdx]]
					
					var correct = (this.props.correct && this.props.correct[key])

					const winningAnswer = (this.props.correctLog && this.props.correctLog[key] && this.props.correctLog[key].length > 0) ? this.props.correctLog[key][0] : null 
					if (this.props.displayMode && winningAnswer) { correct = true }

					var val = this.props.values[itemRef.id][this.props.years[yIdx]]

					if (val === undefined) { // It might be in input (previously filled)
						if (correct && this.props.input && this.props.input[itemRef.id + "_" + this.props.years[yIdx]]) {
							val = this.props.input[itemRef.id + "_" + this.props.years[yIdx]]
						}
					}

					if (itemRef.type && itemRef.type.indexOf('percentage') >= 0) {
						if (val < 0.1) {
							val = (val * 100).toFixed(2) + '%'
						} else {
							val = (val * 100).toFixed(1) + '%'
						}
					} else if (val !== undefined) {
						val = Number(val).toLocaleString()
					}

					var valJsx
					if (val === undefined) {
						valJsx = <input className="_input" name={key} onChange={(e) => {this.updateEntry(itemKey, e.target.value)}} autoComplete="off"/>
					}
					else {
						if (correct) {
							valJsx = <input className="_fixed _correct" disabled={true} key={key} value={val} autoComplete="off"/>
						}
						else {
							valJsx = <input className="_fixed" disabled={true} key={key} value={val} autoComplete="off"/>
						}
					}

					var winningName = "???"
					var winningTime = '4.23s'
					if (this.props.displayMode && winningAnswer) {
						if (winningAnswer.name) {
							winningName = winningAnswer.name.substr(0, 8)
						}
						if (winningAnswer.time > 0) {
							winningTime = (winningAnswer.time / 1000).toFixed(2) + 's'
						}
					}

					contentRow.push(
						<div className={"_cell _value" + (last ? " _last" : "")} key={'row'+key}>
							<div className="_inputAnchor">
								{valJsx}

								{ correct ? 
									<motion.div className="_icon"
										animate={{scale: [1, 1.2, 1]}}
										transition={{ duration: 0.2, times: [0, 0.2, 1] }}>
										<Icon icon={ic_check_circle} size={24}/>
									</motion.div> : <span/> }

								{ (this.props.displayMode && winningAnswer) ? 
									<div className="_winner">
										<motion.div className=""
											animate={{scale: [1, 1.2, 1]}}
											transition={{ duration: 0.2, times: [0, 0.2, 1] }}>
											<div className="_name">
												{winningName}
											</div>
											<div className="_time">
												{winningTime}
											</div>
										</motion.div>
									</div>: <span/> }
							</div>


						</div>)
				}

				if (contentRow.length > 0 && itemRef.heading && itemRef.heading !== heading) {
					heading = itemRef.heading
					contentRows.push(<div className="_row _contentRow _headerRow" key={'headerRow' + itemRef.id}>
						<div className="_cell _lbl _heading">{heading}</div>
						<div className="_cell _value"></div>
						<div className="_cell _value _last "></div>
					</div>)
				}

				contentRows.push(<div className="_row _contentRow">{contentRow}</div>)
			}
		}

		return (
			<div>
				<div className="sudokuPuzzle" key={this.props.currentQn}>
					{ this.props.numQns > 1 ? 
						<SudokuProgressBar currentQn={this.props.currentQn} numQns={this.props.numQns} onBack={this.props.liveMode === false ? this.props.onBack : null}/> :
						<span/> }
					{ this.props.currentQn === 0 && this.props.displayMode !== true && this.props.liveMode !== true ? 
						<div className="sudokuWelcome">Welcome to <b>Financial Statement Sudoku</b>! Try to fill in the missing items using <b>only</b> the information provided. You'll see a green tick when you get an item correct!</div> :
						<span/>
					}

					{ this.props.secretInfo ? 
						<div className="secretInfoBoxContainer">
							<div className="secretInfoBox">
								{ parseInt(this.props.secretInfo.number) >= 0 ? <div className="_header">Clue {this.props.secretInfo.number}</div> : <span/> }
								<div className="_body">
									{this.props.secretInfo.description}
								</div>
							</div>
						</div> : <span/> }

					<div className="_row _headerRow">
						{headerRow}
					</div>
					{contentRows}
					<div className="_row _footerRow">
						{footerRow}
					</div>

					{ this.props.correctDescription ? 
						<motion.div className=""
							animate={{scale: [1, 1.2, 1]}}
							transition={{ duration: 0.2, times: [0, 0.2, 1] }}>
								<div className="correctDescription">
									<div className="_icon">
										<Icon icon={ic_check_circle} size={40}/>
									</div>
									<div>
										{this.props.correctDescription}
									</div>
								</div>
						</motion.div> : 
						<span/>
					}

				</div>
				{ this.props.complete ? <SudokuCongratsBox onNext={this.props.onNext}/> : <span/> } 
			</div>)
	}
}
class SudokuCongratsBox extends Component {
	componentDidMount() {
		//this.reward.rewardMe();
		//<Reward ref={(ref) => { this.reward = ref }} type='confetti' >
	}
	render() {
		return (
			<div className="container sudokuCongratsBox">
					<div className="row">
						<div className="col-6">
							<div className="_text">Well done!</div>
						</div>
						<div className="col-6 text-right">
								<motion.button
									type="button" 
									whileTap={{ scale: 0.9 }}
									transition={{ duration: 0.1 }}
									className="btn actionButton buttonGradient_blueGreen _nextButton"
									onClick={this.props.onNext}>Next puzzle
								</motion.button>
						</div>
				
					</div>
			</div>)
	}
}

class SudokuTeamGameFinished extends Component {
	render() {
		const r = this.props.results
		//if (this.props.results === undefined) { return <span/> }
		return (
			<div className="gameFinished">
				<div className="container gameCompleteBox">
					<div className="row justify-content-center">
						<div className="col">
							<div className="smallCapsHeading text-center _gameOver mb-4">GAME OVER</div>
							{ this.props.team ? <div className="smallCapsHeading text-center mb-4 _teamName">Team {Number(this.props.team.id) + 1}</div> : <span/> }
						</div>
					</div>
					{ /* <div className="row justify-content-center text-center">
						<div className="col">
							<div className="finalScore container">
								<div className="row mb-3">
									<div className="col _label text-left"># First</div>
									<div className="col _value text-right">{r && r.first ? r.first : 0}</div>
								</div>
								<div className="row mb-3">
									<div className="col _label text-left"># Correct</div>
									<div className="col _value text-right">{r && r.correct ? r.correct : 0}</div>
								</div>
							</div>
						</div>
					</div> */ }
					{ !this.props.liveMode ? 
						<div className="row justify-content-center">
							<div className="col">
								<motion.button
									type="button" 
									whileTap={{ scale: 0.9 }}
									transition={{ duration: 0.1 }}
									className="btn actionButton playAgainButton buttonGradient_redYellow"
									onClick={() => {this.props.onReset()}}>Play Again
								</motion.button>
							</div>
						</div> : <span/> }
					{ this.props.liveMode ?  <FeedbackBox noun="activity" onChange={this.props.sendFeedback}/> : <span/> }
				</div>
			</div>

		)
	}
}


class SudokuProgressBar extends Component {
	//constructor(props) { super(props) }
	render() {
		return (
			<div className="sudokuProgressBar">
				<div className="_lhs">
					<div className="_lbl">Puzzle {this.props.currentQn + 1} of {this.props.numQns}</div> 
					{ this.props.onBack && this.props.currentQn > 0 ? <div className="_back"><a onClick={this.props.onBack}>Go back</a></div> : <span/> }
				</div>
				<div className="_rhs">
					<div className="resultBar">
						<div className="resultBarInner" style={{width: (this.props.currentQn >= 0 ? 100 * ((this.props.currentQn + 1) / this.props.numQns) : 0) + "%"}}></div>
					</div>
				</div>
			</div>
		)
	}
}

export { SudokuPuzzle, SudokuDisplayView, SudokuTeamGameFinished }
