import React, { Component } from 'react'
import ReactGA from "react-ga"
import { CatembeNavBar } from './components/basic.js'
import { ShowcaseCTAFooter } from './components/frontpage.js'

import './css/FrontPage.css'
import './css/CaseStudy.css'

class CaseStudyPageUWA extends Component {
	render() {
		return (
			<div>
				<CatembeNavBar/>
				<div className="container caseStudy">
					<div className="row">
						<div className="col _section">
							<div className="smallCapsHeading">CASE STUDY</div>
							<h1 className="_title">
								<span className="_client">University of Western Australia</span> / 
								<span className="_discipline">Accounting</span>
							</h1>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Introduction</h2>
							<p>In Semester 2, 2020, Catembe was piloted in <a href="https://handbooks.uwa.edu.au/unitdetails?clearc=1&code=ACCT5432" target="_new_">Introductory Financial Accounting (ACCT5432)</a>, a post-graduate level  accounting subject. The unit has X students enrolled in two steams, one online and one face-to-face, and is run by lecturer Nikki Schonfeldt. This pilot was an exciting opportunity to experiment with new Accounting interactives in both online and f2f environments.</p>
						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Overview</h2>
							<p>The <a href="https://www.catem.be/interactives/fss">Financial Statement Sudoku</a> game was trialled in the subject in two different topics and a number of different configurations. A focus of the trial was to give students an opportunity to interact and work in teams to solve problems during the lectures.</p>

							<h3>Financial Statements</h3>

							<p>For this topic, we set up a 'bingo' team version of Sudoku. In pre-formed assignment groups, students were placed into breakout rooms and given instructions to access the game. Their task was to complete a set of line items from both the Income Statement and Balance Sheet of an example company. Every few minutes, all teams were issued a new 'clue' that helped them complete the puzzle. A leaderboard was accessible to the lecturer, who could track the progress of all the teams and offer assistance where necessary.</p>

							<p>The aim of the activity was to challenge students to recognise the impact of the clues on the various line items, as well as the relationships between them. The competitive aspect, as well as need to connect new clues to previous ones to solve the puzzle, was designed to increase engagement with the activity.</p>

							<video src="https://d3tx1wevfc318r.cloudfront.net/Video/Sudoku_LiveMode_Teams.mp4" className="img-fluid" autoPlay={true} muted={true} loop={true}></video>
							<div className="_caption">Lecturer's view of the game</div>

							<h3>Cash-flow Statements</h3>

							<p>Here, two activities were planned. The first was a series of warm-up puzzles based on the cash-flow statement, to be completed collaboratively in teams. The second was a competition mode of the game, where all students would race to be the first to fill certain items in a cash-flow puzzle, with the winner and their time displayed on a leaderboard. Unfortunately, technical issues meant the 2nd activity could not be run, and was offered to students as a single-player activity instead.</p>
				
							<p>The puzzles highlighted the relationships between cash-flow and the other statements. For example, they were challenged to work backwards to find the opening cash balance from the closing balance and the cash-flow statement; students needed to recognise that the net cash flow is the change in the cash balance over the period.</p> 

							<div className="iPhoneContainer" style={{width: '320px', marginBottom: '40px', marginTop: '30px'}}>
								<div className="embed-responsive iPhone" 
									style={{backgroundImage: "url('https://d3tx1wevfc318r.cloudfront.net/Frontpage/iPhone8_375_x_667_screen.png')"}}>
									<video src="https://d3tx1wevfc318r.cloudfront.net/Video/Sudoku_LiveMode_TeamsAsync.mp4" autoPlay={true} muted={true} loop={true}/>
								</div>
								<div className="_caption">Student view of cash-flow puzzle, completed in teams.</div>
							</div>

						</div>
					</div>

					<div className="row">
						<div className="col _section">
							<h2>Overall Results</h2>

							<CaseStudyQuote 
								text="Learning activities of this kind are often difficult to integrate into existing courses and time consuming to implement. I wanted the game to address two specific objectives: first, support students’ conceptual understanding of the Accounting Equation and the relationship between the Income Statement and the Balance Sheet and, second, to encourage collaborative learning. Both objectives were successfully addressed and I am exited to integrate Catembe activities in other topics."
								author={{name: "Nikki Schonfeldt", position: "Lecturer and Subject Co-ordinator"}}/>

							<CaseStudyQuote 
								text="I had studied accounting before so the challenge wasn't too difficult, and our team did really well. I enjoyed the competitive aspect, and it certainly was more fun in this format."
								author={{name: 'James', position: 'Student', student: true}} />

							<CaseStudyQuote 
								text="The activity was challenging, as it required a comprehensive understanding of the financial statements. I found it useful to test my knowledge and discuss the problem with my team members during the activity."
								author={{name: 'Yan', position: 'Student', student: true}} />

						</div>
					</div>

					<h4>Survey results</h4>
					<p>A quick survey was presented to students immediately after the in-class activity had ended:</p>

					<h4>Topic 1: Financial Statements</h4>
					<div className="text-center">
						<div className="_infoTableContainer _slim">
							<table className="_info">
								<thead><tr> 
									<td>Survey Prompt</td> 
									<td>Rating (/ 5)<br/>Online stream</td> 
									<td>Rating (/ 5)<br/>F2F stream</td> 
								</tr></thead>
								<tbody>
									<tr>
										<td className="">This game was engaging</td>
										<td className="_num"> 4.54 (n = 24)</td>
										<td className="_num"> 4.71 (n = 24)</td>
									</tr>
									<tr>
										<td className="">This game contributed to my understanding of the topic</td>
										<td className="_num"> 5.00 (n = 17)</td>
										<td className="_num"> 4.71 (n = 17)</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<h4>Topic 2: Cash-flow Statements</h4>
					<div className="text-center">
						<div className="_infoTableContainer _slim">
							<table className="_info">
								<thead><tr> 
									<td>Survey Prompt</td> 
									<td>Rating (/ 5)<br/>Online stream</td> 
									<td>Rating (/ 5)<br/>F2F stream</td> 
								</tr></thead>
								<tbody>
									<tr>
										<td className="">This game was engaging</td>
										<td className="_num"> 4.42 (n = 12)</td>
										<td className="_num"> 4.50 (n = 12)</td>
									</tr>
									<tr>
										<td className="">This game contributed to my understanding of the topic</td>
										<td className="_num"> 4.51 (n = 6)</td>
										<td className="_num"> 4.80 (n = 5)</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

				</div>
				<ShowcaseCTAFooter {...this.props}/>
			</div>
		)
	}
}


class CaseStudyQuote extends Component {
	render() {
		return (
			<div className={"_quote" + (this.props.author.student === true ? " _student" : "")}>
				<div className="_body">"{this.props.text}"</div>
				<div className="_author">
					<div className="_name">{this.props.author.name}</div>
					<div className="_position">{this.props.author.position}</div>
				</div>
			</div>

		)
	}
}


export default CaseStudyPageUWA
