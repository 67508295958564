import React from 'react'
import {Title, Description, StudentHeader, StudentAccessInstructions, StudentAccessHeader, WaitingForStart, GameFinished} from '../components/basic.js'
import {IntroPage} from '../components/basic.js'
import {TextAdventureUI} from '../components/talbot.js'
import {BasicControlView, ControlButton, DemoControlView} from '../components/control.js'
import {ButtonFooter} from '../components/basic.js'

import { FrontendAppSettings } from './FrontendAppSettings'

const gameDetails = {
	gameId: 'talbot', // Unique identifier for this game
	appRoute: 'talbot', // Determines url of app
	title: "Talbot",

	discipline: "Strategy", // Shown on frontpage tile and product page
	veryShortTitle: "Talbot", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Talbot", // Used as interactive name on FrontPage
	shortDescription: "AI-powered free-form simulation of business scenarios", // Description on FrontPage

	//videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Sudoku_v2.mp4", // Video on frontPage and Product page
	//asyncDemoUrl: "/app/public/je/async_demo/je_demo/student",
	//liveMode: { },

	features: [
		{
			heading: "Freedom to explore",
			description: "Unlike branched scenarios, our engine supports a large set of actions and events that occur at any point in time. This keeps complexity manageable, and encourages students to learn through play and discovery.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Talbot/Feature1.svg",
		},
		{
			heading: "In-built actions",
			description: "Because we specialise in business simluation, many common elements are available out of the box: financial accounts and statements, financing transactions, typical revenue and cashflow models, and much more.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Talbot/Feature2.svg",
		},
		{
			heading: "Natural language interface",
			description: "Our AI-powered interpreter allows students to issue instructions and request information using plain english statements, such as 'show me the latest balance sheet'.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Talbot/Feature3.svg",
		},
		{
			heading: "Model complex relationships",
			description: "Cause and effect relationships can be as simple or complex as required. For example: sales revenue could be flat, random, or a complex function of past marketing spend and other factors.",
			imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Talbot/Feature4.svg",
		},
	],
}

var talbot = new FrontendAppSettings(gameDetails)

talbot.config.studentHeader = false
talbot.config.studentWidescreenView = true
talbot.config.noStudentPeriodicUpdate = true

talbot.appConfigs = { // Contents of matching appConfig gets thrown into app.state.content
	pandemic: {
	},
}

talbot.lifecycle = {
	STUDENT: {
		componentDidMount: function() {
			this.submitInput = (value) => {
				if (value.length > 0) {
					this.sendInput_debounced({action: 'submitInput', value: value})
					this.setState(prevState => {
						const output = [...prevState.output, {type: 'input', value: '> ' + value}];
						return { output }
					})
				}
			}
		},
		onAuthentication: function() { }
	}
}

talbot.stages = {
	CONTROL: function() { // Control only has one 'stage'
		return (
			<div>
				<BasicControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					appView = {this.state.appView.toLowerCase()}
				/>
			</div>)
	},
	STUDENT: { // render() function for each stage
		'0': function() {
			return ( <TextAdventureUI
						onSubmit={this.submitInput}
						{...this.state} />)
		},
		'1': function() {},
	},
	DISPLAY: {
		'0': talbot.getDefaultStage('DISPLAY', '0'),
	},
}

export { talbot }
