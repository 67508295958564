import React from 'react'
import '../css/Cards.css'

import { FrontendAppSettings } from './FrontendAppSettings'
import { basicFunctions } from '../include/basic'
import { GameTimeLeft } from '../components/timing'

import { Deck, EmptyDeck, CardDestinations, GameFinished } from '../components/cards'

//import { SwipeCarousel } from '../components/basic'
//import { DragSource, DragTarget } from '../components/dnd'

//import { DndProvider } from 'react-dnd'
//import TouchBackend from 'react-dnd-touch-backend'
//import MultiBackend, { Preview } from 'react-dnd-multi-backend'
//import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

const gameDetails = { // Items specific to this deployment
	gameId: 'cards', // Unique identifier for this game
	appRoute: 'cards', // Determines url of app
	//stageNames: ['a', 'b'], // Optional -- for naming the sections of the control view
}

var cards = new FrontendAppSettings(gameDetails)

cards.config.fullscreen = true
cards.config.studentWidescreenView = true
cards.config.noStudentPeriodicUpdate = true
cards.config.studentHeader = false

cards.content= {
	title: "Porter's Five Forces"
}

cards.appConfigs = {
}

cards.setInitialState({
	gameEndTime: null, 
	gameFinished: false,
	gameActive: false,
})

cards.lifecycle.STUDENT.onAuthentication = function() {
	//this.sendInput({action: 'enrol'})
}
cards.lifecycle.STUDENT.componentDidMount = function() {
	this.onSwipe = (dir) => {
		if (this.state.curCardIdx <= 0) { // All cards are gone
			setTimeout(() => { this.gameFinished() }, 500)
		}
		this.sendInput({action: 'swipeCard', direction: dir, curCardIdx: this.state.curCardIdx})

		this.setState((prevState) => {
			return {curCardIdx: prevState.curCardIdx - 1}
		})
	}
	this.gameFinished = () => {
		//console.log('game finished ' + s)
		if (this.state.gameFinished !== true) {
			this.sendInput({action: 'gameFinished'})
			this.setState({gameFinished: true, curCardIdx: null})
		}
	}
	this.resetGame = () => {
		if (this.state.gameFinished === true) {
			this.sendInput({action: 'resetGame'})
			this.setState({gameEndTime: null, gameFinished: false}) // Need to foce endTime here otherwise timer will wake up before the new endTime is returned from the API
		}
	}
	this.startGame = () => {
		this.setState({gameActive: true, gameEndTime: null, gameFinished: false})
		this.sendInput({action: 'enrol'})
	}
}

cards.stages = {
	STUDENT: {
		'0': function() {
			if (!this.state.gameActive) {
				return (
					<div className="deck">
						<EmptyDeck 
							title={this.content.title}
							onStart={this.startGame}/>
						<CardDestinations 
							curCard={{leftUp: 'lu', rightDown: 'rd'}}
							categories={{lu: {name: 'Swipe towards me'}, rd: {name: 'or me!'}}}
							mode={this.state.mode || 'ud'}/>
					</div>
				)
			}
			if (this.state.cards) {

				// Initialise cursor
				if (this.state.curCardIdx === undefined || this.state.curCardIdx === null) {
					this.setState({curCardIdx: this.state.cards.length - 1})
				}

				var resultClass = ""
				if (this.state.lastSwipe) {
					if (this.state.lastSwipe.correct === true) { resultClass = 'correct' }
					if (this.state.lastSwipe.correct === false) { resultClass = 'incorrect' }
				}

				return (
					<div className={"deck " + resultClass}>
						{ this.state.gameFinished === true && this.state.results ? 
							<GameFinished
								onReset={this.resetGame}
								results={this.state.results}
								/> : 
							(this.state.gameActive ? <Deck 
								onSwipe={this.onSwipe} 
								cards={this.state.cards}
								mode={this.state.mode}/> : <EmptyDeck />)
						}
						{ this.state.gameEndTime !== undefined ?
							<GameTimeLeft
								active={!this.state.gameFinished}
								endTime={this.state.gameEndTime}
								onFinish={this.gameFinished}
								/> : <span/> }
						{ this.state.curCardIdx !== undefined && this.state.gameFinished !== true ?
							<CardDestinations 
								curCard={this.state.cards[this.state.curCardIdx]}
								categories={this.state.categories}
								mode={this.state.mode}
							/> : <span/> }
					</div>
				)
			} else {
				return <span/>
			}
		}
	},
	DISPLAY: {
		'0': cards.getDefaultStage('DISPLAY', '0')
	},
}

export { cards }
