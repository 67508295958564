import React, { Component } from 'react';
import AuthService from './utils/auth.js';
export default function withAuth(AuthComponent) {
	const Auth = new AuthService()
	return class AuthWrapped extends Component {
		constructor() {
			 super();
			 this.state = {
				  user: null
			 }
		}
		componentWillMount() {
			 if (!Auth.loggedIn()) {
			 	  if (this.props.match && this.props.match.path == '/app/:appRoute/:appConfig/:appDeploymentId/:appView' &&
				  		this.props.match.params && this.props.match.params.appView == 'student') { 
					  // Do nothing -- we allow login at the app level for student apps
				  } else {
					  this.props.history.replace('/')
				  }
			 }
			 else {
				  try {
						const token = Auth.getProfile()
						const user = Auth.getUser()
						this.setState({
							 token: token,
							 user: user,
						})
				  }
				  catch(err){
						Auth.logout()
						this.props.history.replace('/')
				  }
			 }
		}
		render() {
			return (<AuthComponent history={this.props.history} user={this.state.user} match={this.props.match} />)
		}
	}
	
}

