import React from 'react'
import {Title, Description, StudentHeader, StudentAccessInstructions, StudentAccessHeader} from '../components/basic.js'
import {IntroPage} from '../components/basic.js'
import {BasicControlView, ControlButton} from '../components/control.js'
import {ButtonFooter} from '../components/basic.js' 
import {AccountBars} from '../components/duality.js'

import { Icon } from 'react-icons-kit'
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up'
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down'

import { FrontendAppSettings } from './FrontendAppSettings'

const gameDetails = { // Items specific to this deployment
	gameId: 'duality', // Unique identifier for this game
	appRoute: 'duality', // Determines url of app

	discipline: "Accounting", // Shown on frontpage tile and product page
	title: "Double-entry Accounting", // Used as heading on Product Page and App landing page
	veryShortTitle: "Duality", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Double-entry Accounting", // Used as interactive name on FrontPage
	shortDescription: "A visual primer from first principles", // Description on FrontPage
	longDescription: [
		<p>An interactive, highly-visual primer on Double-entry Accounting, from first principles. It serves as a good introduction to the key concepts and rationale behind the technique, aimed at students studying Accounting for the first time.</p>,
		<p>Students can trigger transactions to see the effect they have on various accounting elements, and are set mini challenges along the way.</p>,
		<p>Deploy to students as standalone reading material, or use on slides in your lecture or webinar.</p>,
	],

	imageUrl: "https://d3tx1wevfc318r.cloudfront.net/Images/products/iPhone_Duality_Screenshot.png",
	videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_Duality.mp4",
	asyncDemoUrl: "/app/public/duality/demo/duality_demo/student",

	localStages: {0: 'Intro', 1: 'Start'},
	topicsCovered: ['Double entry', 'Accounting equation', 'Accounting elements', 'Debits and Credits']
}

var duality = new FrontendAppSettings(gameDetails)

duality.config.requirePublicNickname = false
duality.config.studentWidescreenView = true
duality.config.noStudentPeriodicUpdate = true
duality.config.studentHeader = false

duality.appConfigs = {
	demo: {
		heading: "Duality",
		subHeading: "An interactive primer on double entry accounting",
		sections: [
			{
				textBefore: [<p>Let's say you have $100 cash in your pocket.</p>],
				accounts: { a: [ {name: 'Cash', value: 100}, ] },
				settings: {},
			},
			{
				textBefore: [<p>We don't just want to keep track of what is in your pocket, but also who owns it. We'll represent this using two bars.</p>, <p>The <b>left side</b> represents what you have in your pocket, and on the <b>right side</b>, who owns it.</p>,<p>In other words, the left side shows <b>resources</b>, and the right shows <b>claims</b> on those resources.</p>,<p>If all of the money is yours, it will look like this:</p>],
				accounts: { a: [ {name: 'Cash', value: 100} ], e: [ {name: 'You', value: 100} ] },
				settings: {},
			},
			{
				textBefore: [<p>Instead, let's assume you owe $30 to your friend Stacey.</p>],
				accounts: { a: [ {name: 'Cash', value: 100} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {},
			},
			{
				textBefore: [<p>The amounts represented by these bars are the two sides of the same coin - every dollar of resources is a dollar that belongs to someone.</p>,<p>This means that the size of the bars will <b>always be equal</b>.</p>,<p>If they are not, it means there are resources which do not belong to anyone, or there are claims over resources that don't exist.</p>],
				accounts: { a: [ {name: 'Cash', value: 100} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {
					overrideHeightInDollars: 140,
					showSelectorButtons: true,
					enableDebitsCredits: false,
				},
				preloadedTransactions: [
					[{name: 'Cash', amount: 20, narration: '$20 that belongs to nobody.', selectorLabel: 'Increase cash'}],
					[{name: 'Stacey', amount: 30, narration: "A $30 claim over resources that don't exist.", selectorLabel: 'Increase claim'}],
				]

			},
			{
				heading: <h2>Transactions</h2>,
				textBefore: [<p>Because the two amounts must always be equal, that means that when we record a transaction, we're required to make a change to <b>both sides</b>. When we gain or lose things, we must also account for the corresponding change in the claims associated with those things.</p>,
					<p>Hence the name: double entry accounting.</p>],
				accounts: { a: [ {name: 'Cash', value: 100} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {
					overrideHeightInDollars: 140,
					showSelectorButtons: true,
					enableDebitsCredits: false,
				},
				preloadedTransactions: [
					[{name: 'Cash', amount: 20, selectorLabel: 'You find $20 on the ground'}, {name: 'You'}],
					[{name: 'Cash', amount: -30, selectorLabel: 'Pay Stacey what you owe her'}, {name: 'Stacey'}],
				]
			},
			{
				textBefore: [<p>Not all transactions will affect both sides, but they all require at least two entries to be made. Some transactions involve swapping one resource or claim for another.</p>],
				accounts: { a: [ {name: 'Shoes', value: 0}, {name: 'Cash', value: 100} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {
					overrideHeightInDollars: 120,
					showSelectorButtons: true,
					enableDebitsCredits: false,
				},
				preloadedTransactions: [
					[{name: 'Shoes', amount: 40, selectorLabel: 'Use cash to buy shoes'}, {name: 'Cash'}],
					[{name: 'Stacey', amount: -10, selectorLabel: 'Stacey forgives $10 of debt'}, {name: 'You'}],
				]
			},
			{	
				heading: <h2>The accounting equation</h2>,
				textBefore: [
					<p>The idea that we want to simultaneously track both <u>resources</u> and the <u>claims to those resources</u> is the central idea behind double-entry accounting.</p>,
					<p>In simple businesses with few stakeholders, simply keeping track of resources was probably adequate. But the emergence of the corporation with complex ownership structures necessitated a new form of record keeping.</p>,
					<p>The modern firm is a separate legal entity that controls and operates resources, distributing the proceeds to shareholders and creditors according to the nature of their claims. Accounting for resources is required to run the firm effectively, while accounting for claims is required to know how to split up the proceeds. For example, this is how we know whether the firm has made a profit, and who the profit can be paid out to.</p>,
					<p>Double-entry accounting also has a built-in mechanism for detecting record-keeping errors. Let's assume a mistake is made when summing or recording total resources. Unless the exact same mistake was also made with claims, the mistake would quickly become apparent when the two numbers failed to match.</p>
				],
				accounts: { a: [ {name: 'Cash', value: 90} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {
					overrideHeightInDollars: 120,
					enableDebitsCredits: false,
				},
			},
			{
				textBefore: [
					<p>In more formal terms, the bar on the left represents a firm's <span className="_var">Assets</span>. On the right, you have external and internal claims.</p>,
					<p>External claims are amounts owed to parties outside the firm, referred to as <span className="_var">Liabilities</span>.</p>, 
					<p>Internal claims, belonging to the owners, or shareholders of a firm, are collectively referred to as <span className="_var">Equity</span>. Equity is a residual, or left-over claim -- it is what is left once you subtract all liabilities from the value of the firm's assets.</p>,
					<p>The idea that the two bars must always be equal is captured mathematically as the <b>accounting equation</b>, which states, simply, that: <div className="formula">Assets = Liabilities + Equity</div> </p>
				],

				accounts: { a: [ {name: 'Assets', value: 100} ], l: [ {name: 'Liabilities', value: 30} ], e: [ {name: 'Equity', value: 70} ] },
				settings: {
					overrideHeightInDollars: 120,
					enableDebitsCredits: false,
					enableInteraction: true,
				},
				challenges: [
					{
						content: <span>Click on the two elements that would be affected if you purchased new shoes on credit.</span>,
						type: 'selectedAccounts',
						answer: ['Assets', 'Liabilities'],
						correctText: <span>This transaction would create a new <u>Shoes</u> asset, and a liability that is usually called <u>Accounts Payable</u>, which is owed to the shoe store.</span>
					}
				]
			},
			{
				heading: <h2>Keeping the balance</h2>,
				textBefore: [
					<p>How do we make sure our accounting equation continues to stay in balance?</p>,
					<p>We could sum up everything after recording each transaction, but that would be a lot of work. A better way would be to ensure that each <b>individual transaction</b> is balanced, with the help of <span className="_var">debits</span> and <span className="_var">credits</span>.</p>,
					<p>Let's call an increase on the left side a <b>debit</b>, and an increase on the right side a <b>credit</b>, and vice versa for decreases:</p>, 
					<p className="text-center">
						<div className="debitCreditTable">
							<table>
								<tbody>
									<tr><td></td><td><div className="acctName">Assets</div></td><td><div className="acctName">Liabilities & Equity</div></td></tr>
									<tr><td style={{textAlign: 'left'}}><Icon icon={ic_keyboard_arrow_up} size={36}/> Increase</td><td><span className="_var">Debit</span></td><td><span className="_var">Credit</span></td></tr>
									<tr><td style={{textAlign: 'left'}}><Icon icon={ic_keyboard_arrow_down} size={36}/> Decrease</td><td><span className="_var">Credit</span></td><td><span className="_var">Debit</span></td></tr>
								</tbody>
							</table>
						</div>
					</p>,
					<p>Valid, balanced transactions now have a very useful property: <div className="formula">Sum of debits = Sum of credits</div></p>,<p>If this is not the case, we will violate the accounting equation.</p>
				],
				accounts: { a: [ {name: 'Shoes', value: 0}, {name: 'Cash', value: 100} ], l: [ {name: 'Stacey', value: 30} ], e: [ {name: 'You', value: 70} ] },
				settings: {
					overrideHeightInDollars: 140,
					showSelectorButtons: true,
					enableDebitsCredits: true,
					showJournalEntry: true,
				},
				preloadedTransactions: [
					[{name: 'Cash', amount: 20, selectorLabel: 'You find $20 on the ground'}, {name: 'You'}],
					[{name: 'Cash', amount: -30, selectorLabel: 'Pay Stacey what you owe her'}, {name: 'Stacey'}],
					[{name: 'Shoes', amount: 40, selectorLabel: 'Use cash to buy shoes'}, {name: 'Cash'}],
				]
			},
			{
				heading: <h2>Examples</h2>,
				textBefore: [
					<p>Below are some examples of real firm balance sheets (lightly simplified).</p>,
					<p><b>Apple Inc.</b> in 2019. Amounts in billions $USD.</p>
				],
				accounts: { 
					a: [ 
						{name: 'Cash', value: 100},
						{name: 'Receivables', value: 23},
						{name: 'Property, Plant, and Equipment', value: 37},
						{name: 'Investments', value: 105},
						{name: 'Other', value: 48},

					], 
					l: [ 
						{name: 'Accounts Payable', value: 46},
						{name: 'Long-term Debt', value: 92},
						{name: 'Other liabilities', value: 84},
					], 
					e: [ 
						{name: 'Share Capital', value: 45},
						{name: 'Accumulated Profits', value: 46},
					] 
				},
				settings: {
					initialHeight: 700
				}
			},
			{
				textBefore: [
					<p><b>Commonwealth Bank Ltd.</b> in 2019. Amounts in billions $AUD.</p>
				],
				accounts: { 
					a: [ 
						{name: 'Cash', value: 30},
						{name: 'Loans', value: 755},
						{name: 'Investments', value: 118},
						{name: 'Other', value: 73},
					], 
					l: [ 
						{name: 'Deposits', value: 636},
						{name: 'Long-term Debt', value: 164},
						{name: 'Other liabilities', value: 107},
					], 
					e: [ 
						{name: 'Equity', value: 69},
					] 
				},
				settings: {
					initialHeight: 700
				}
			},
			{
				textBefore: [
					<p><b>Woolworths Group Ltd.</b> in 2019. Amounts in millions $AUD.</p>
				],
				accounts: { 
					a: [ 
						{name: 'Cash', value: 1066},
						{name: 'Inventory', value: 4280},
						{name: 'Property, Plant, and Equipment', value: 9519},
						{name: 'Intangible Assets', value: 6526},
						{name: 'Other', value: 2009},
					], 
					l: [ 
						{name: 'Accounts Payable', value: 6676},
						{name: 'Long-term Debt', value: 2855},
						{name: 'Other liabilities', value: 3583},
					], 
					e: [ 
						{name: 'Share Capital', value: 5828},
						{name: 'Accumulated Profits', value: 4458},
					] 
				},
				settings: {
					initialHeight: 700
				}
			},
		],
	},
}

//settings: {overrideHeightInDollars: 200, enableDebitsCredits: false},
//preloadedTransaction: [{name: 'Cash', amount: 40, narration: 'Hello'}, {name: 'Contributed Equity'}]
duality.lifecycle.STUDENT.componentWillMount = function() {
	//this.sendInput({action: 'enrol'})
}

duality.stages = {
	CONTROL: function() { // Control only has one 'stage' 
		const sendAction = (item) => {
			this.sendAction(item)
		}
		return (<BasicControlView
					stage = {this.state.stage}
					active = {this.state.active}
					numClients = {this.state.numClients}
					clients = {this.state.clients}
					sendAction = {sendAction}
					stages = {this.gameDetails.localStages}/>)
	},
	STUDENT: {
		'0': function() {
			var out = []
			if (this.content.heading) { out.push(<h1 className="display-4 dualityHeading">{this.content.heading}</h1>) }
			if (this.content.subHeading) { out.push(<h4 className="dualitySubHeading">{this.content.subHeading}</h4>) }

			for (var sectionIdx in this.content.sections) {
				var section = this.content.sections[sectionIdx]
				out.push(
					<div className="dualitySection">
						<div className="dualityText">
							{ section.heading ? section.heading : <span/> }
							{ section.textBefore }
						</div>
						<div className="dualityInteractive">
							{ section.accounts ? <AccountBars
								accounts={section.accounts}
								settings={section.settings}
								preloadedTransaction={section.preloadedTransaction}
								preloadedTransactions={section.preloadedTransactions}
								challenges={section.challenges}
								key={"section"+String(sectionIdx)} 
								/> : <span/> }
						</div>
						<div>
							{section.textAfter}
						</div>
					</div>
				)
			}
			return <div className="dualityContainer row justify-content-center">
						<div className="col col-sm-10 col-md-8 col-lg-8">
							{out}
						</div>
					</div>
		}
	},
	DISPLAY: {
		'0': function() { 
			return (
				<div className="relativeAnchor">
					<IntroPage>
						<Title>{this.content.title}</Title>
						<Description>{this.content.description}</Description>
						<StudentAccessInstructions appName={this.content.title} publicUrl={this.state.publicUrl}/>
					</IntroPage>
				</div>
			)
		},
	},
}

/*if(this.content.isSinglePage) {
	duality.stages.STUDENT['0'] = function() {
	}
}*/



export { duality }
