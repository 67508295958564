import React, {Component} from 'react'
class TOS extends Component {
	render() {
		return (
			<div className="container terms">
				<div className="row">
					<div className="col pt-5 pb-5">
						<h1>Catem.be - Terms of Service</h1>
						<h3>Last updated: April 4, 2020</h3>
						<p>
							These Terms of Service ("<strong>Terms</strong>") describe your rights and
							responsibilities as a subscriber of our Subscription Services. These Terms
						are between you and Thira Analytics Pty Ltd (ABN 78 169 339 100) ("<strong>Thira</strong>", "<strong>we</strong>" or "<strong>us</strong>"). "	<strong>You</strong>" means either you individually or the entity you
							represent in accepting these Terms. If you are accepting on behalf of your
							employer or another entity, you represent and warrant that: (i) you have
							full legal authority to bind your employer or such entity to these Terms;
							(ii) you have read and understand these Terms; and (iii) you agree to these
							Terms on behalf of the party that you represent. If you do not agree with
							these Terms you must not subscribe and you must cease using any
							Subscription Services.
						</p>
						<p>
							<strong className="heading">1. Subscription Services</strong>
						</p>
						<p>
							<strong className="heading">1.1 Your access to the Subscription Services.</strong>
							During the applicable Subscription Term, you (and your Authorised Users)
							may access and use the Subscription Services via the Website in accordance
							with these Terms, the nominated subscription plan and the Documentation.
							The rights granted to you in this clause are non-exclusive,
							non-sublicensable and non-transferable. It does not grant you any ownership
							rights nor any other right or licence of any kind except as expressly set
							out above including, without limitation, any right to obtain possession of
							any source code, data or other technical materials in relation to the
							Subscription Services.
						</p>
						<p>
							<strong className="heading">1.2 Authorised Users.</strong>
							Access to and use of the Subscription Service (including any enabled
							Interactives) is restricted to the speci&#64257;ed number of individual
							Authorised Users permitted in your subscription plan. You agree and
							acknowledge that Authorised User login details will only be used by the
							permitted number of Authorised Users. You further agree and acknowledge
							that an Authorised User login is not to be shared or used by individuals
							that are not Authorised Users of the Subscription Service. You and your
							Authorised Users are responsible for maintaining the con&#64257;dentiality
							of all Authorised User login information for your account. As between you
							and us, you are responsible for ensuring that Authorised Users comply with
							these Terms and applicable Documentation when using the Subscription
							Services and ensuring that Your Data is and remains compliant with all
							applicable laws.
						</p>
						<p>
							<strong className="heading">1.3 Limited Support.</strong>
							You acknowledge that during the Subscription Term we may provide you with
							limited free support in respect of the Subscription Services. This may
							involve limited telephone or email support in relation to configuration and
							use of the Subscription Services. If we provide such support it will be
							provided on a discretionary and reasonable efforts basis only (any will not
							give rise to any obligation to provide further support on any further
							occasion). You may also be able to purchase an enhanced support offering
							from us but, where applicable, such enhanced support will be governed by a
							separate contract between you and us.
						</p>
						<p>
							<strong className="heading">1.4 Restrictions.</strong>
							Except as otherwise expressly permitted in these Terms, you will not (and
							you will ensure Authorised Users do not): (a) reproduce, modify, adapt or
							create derivative works of the Subscription Services; (b) rent, lease,
							distribute, sell, sublicense, transfer or provide access to the
							Subscription Services to a third party; (c) use the Subscription Services
							for the benefit of any third party; (d) incorporate any Subscription
							Services into a product or service you provide to a third party; (e)
							interfere with or otherwise circumvent mechanisms in the Subscription
							Services intended to limit your use (for the avoidance of doubt, you must
							not attempt to gain unauthorised access to any materials other than those
							to which you have been given express permission to access pursuant to the
							relevant subscription plan); (f) reverse engineer, disassemble, decompile,
							translate or otherwise seek to obtain or derive the source code, underlying
							ideas, algorithms, file formats or non-public APIs to any Subscription
							Services, except to the extent expressly permitted by applicable law (and
							then only upon advance notice to us); (g) remove or obscure any proprietary
							or other notices contained in any Subscription Service; (h) use the
							Subscription Services for competitive analysis or to build competitive
							products; (i) publicly disseminate information regarding the performance of
							the Subscription Services; or (j) encourage or assist any third party to do
							any of the foregoing.
						</p>
						<p>
							<strong className="heading">2. Term and Termination</strong>
						</p>
						<p>
							<strong className="heading">2.1 Initial term and renewal.</strong>
							These Terms will be effective on the date of subscribing and will continue
							for an initial period as applicable to your nominated subscription plan.
							These Terms will automatically renew thereafter for equivalent periods
							unless either party terminates your subscription in accordance with these
						Terms (the initial period and each successive renewal period being the "	<strong>Subscription Term</strong>").
						</p>
						<p>
							<strong className="heading">2.2 AUTO-RENEWAL AND CANCELLATION.</strong>
							FOR ALL SUBSCRIPTION PLANS EXCEPT THOSE RECURRING ON A MONTHLY BASIS, WE
							WILL NOTIFY YOU BY EMAIL PRIOR TO THE END OF YOUR SUBSCRIPTION TERM OF
							RENEWAL, INCLUDING THE APPLICABLE SUBSCRIPTION FEES. REGARDLESS OF
							SUBSCRIPTION PLAN, YOU MAY ELECT TO OPT-OUT OF AUTOMATIC RENEWAL AT ANY
							TIME DURING THE SUBSCRIPTION TERM BY FOLLOWING THE PROCESSES SET OUT AT <a href="https://www.catem.be">catem.be</a>.
						</p>
						<p>
							<strong className="heading">2.3 Termination for Cause.</strong>
							Either party may terminate these Terms for cause (a) upon written notice to
							the other Party of a material breach if such breach remains uncured at the
							expiration of thirty (30) days from the date of the breaching party's
							receipt of such written notice; or (b) if the other party becomes the
							subject of a petition in bankruptcy or any other proceeding relating to
							insolvency, receivership, liquidation or assignment for the bene&#64257;t
							of creditors. If these Terms are terminated by you in accordance with this
							clause, we will refund you any prepaid fees in respect of the unused
							remainder of the Subscription Term.
						</p>
						<p>
							<strong className="heading">2.4 Export of Your Data.</strong>
							For a limited period of thirty (30) days after the effective date of
							termination or expiration of these Terms, upon Your request, we will make
							Your Data available to you for export or download as provided in the
							Documentation. Thereafter, we will have no obligation to maintain or
							provide any of Your Data, and, as provided in the Documentation, we will,
							unless prohibited by law or legal order, delete Your Data from our relevant
							Subscription Service in accordance with our internal policies.
						</p>
						<p>
							<strong className="heading">3. Subscription Fees and billing</strong>
						</p>
						<p>
							<strong className="heading">3.1 Processing your payment.</strong>
							As part of the subscription process, you will need to provide us or our
							third party payment processor with valid, up-to-date and complete payment
							details and any other relevant valid, up-to-date and complete contact and
							billing details and, you hereby authorise us or our third party payment
							processor to process such payment: (a) for the subscription fees payable in
							respect of the relevant initial period; and (b) where renewing, at the end
							of each Subscription Term. If you fail to pay your subscription fees within
							five (5) business days of our notice to you that a payment is delinquent,
							we may suspend or terminate access to and use of such Subscription
							Services. You acknowledge that where payments are processed by <b>Stripe</b>, 
							in submitting your payment information you agree
							to Stripe's <a href="https://stripe.com/au/checkout/legal">terms and conditions</a>
						</p>
						<p>
							<strong className="heading">3.2 Currency and taxes.</strong>
							All subscription fees will be charged in Australian Dollars. You are
							responsible for payment of all applicable taxes.
						</p>
						<p>
							<strong className="heading">3.3 Changes.</strong>
							The subscription fees are subject to change upon 30 days' notice from us,
							given by email or posted to the Website. Such change will not impact the
							subscription fees payable by you in respect of your current pre-paid
							Subscription Term but will apply in respect of any subsequent renewal. If
							you do not wish to continue your subscription at the revised price, you may
							cancel your subscription before the end of your current Subscription Term.
						</p>
						<p>
							<strong className="heading">3.4 No Refunds.</strong>
							Except for your termination rights under clauses 2.3 or 3.3, if you elect
							to terminate your subscription to the Subscription Service prior to the end
							of your then current Subscription Term, no refunds or credits for
							subscription fees or payments will be provided to you. You may be entitled
							to a pro rata refund under applicable law and nothing in these Terms
							affects your rights under applicable law.
						</p>
						<p>
							<strong className="heading">4. Our Intellectual Property</strong>
						</p>
						<p>
							<strong className="heading">4.1 IP Rights in the Subscription Services and Feedback.</strong>
							Subscription Services are made available on a limited access basis, and no
							ownership right is conveyed to you. We and our licensors have and retain
							all right, title and interest, including all Intellectual Property Rights,
							in and to the Subscription Services (and any underlying technology or
							content on our Website). From time to time, you may choose to submit
							Feedback to us. We may in connection with any of our products or services
							freely use, copy, disclose, license, distribute and exploit any Feedback in
							any manner without any obligation, royalty or restriction based on
							Intellectual Property Rights or otherwise and you grant us a perpetual and
							irrevocable licence to use and exploit any such Feedback.
						</p>
						<p>
							<strong className="heading">4.2 Collaboration.</strong>
							If we collaborate with you in relation to the implementing any new
							Interactive (or new functionality within an existing Interactive), any
							contributions you make to such Interactives (if any) will constitute
							Feedback and this clause 4 will apply in respect of the relevant
							Interactive unless you have entered into a separate written development
							contract with us that expressly provides otherwise.
						</p>
						<p>
							<strong className="heading">4.3 IPR Warranty.</strong>
							We warrant that the Subscription Services, as provided by us and used
							strictly in accordance with these Terms and Documentation, do not to the
							best of our knowledge infringe the Intellectual Property Rights of any
							third party. For clarification, this warranty does not apply to any of Your
							Data, including without limitation where it is uploaded to or hosted on the
							Subscription Services as part of any Interactive or otherwise.
						</p>
						<p>
							<strong className="heading">4.4 IPR Indemnity. </strong>
							We will defend you against any claim that your access and use of the
							Subscription Services strictly in accordance with these Terms and
							Documentation infringes any third party's Intellectual Property Rights and
							will indemnify you for any amounts awarded against you in judgment or
							settlement of such claims, provided that: (a) you give us prompt notice of
							any such claim; (b) you provide reasonable co-operation to us in the
							defence and settlement of such claim, at your expense; and (c) we are given
							sole authority to defend or settle the claim. This indemnity will not apply
							if the alleged infringement is based on: (a) Your Data; (b) a modification
							of the Subscription Services by anyone other than us; (c) your use (or your
							Authorised Users use) of the Subscription Services in a manner contrary to
							the Documentation or any instructions given to you by us; or (c) your
							continued use of the Subscription Services after notice of the alleged or
							actual infringement was provided to you.
						</p>
						<p>
							<strong className="heading">4.5 Sole remedy.</strong>
							In the defence or settlement of any claim, we may procure the right for you
							to continue using the Subscription Services, replace or modify the
							Subscription Services so that they become non-infringing or, if such
							remedies are not reasonably available, immediately terminate your
							subscription on notice and provide you with a pro rata refund of any
							pre-paid subscription fees for any unused period of your subscription. This
							clause states your sole and exclusive rights and remedies, and our
							(including our employees, agents and sub-contractors) entire obligations
							and liability, for infringement of any Intellectual Property Rights.
						</p>
						<p>
							<strong className="heading">6. Your Data</strong>
						</p>
						<p>
							<strong className="heading">6.1 Hosting of Your Data.</strong>
							Title to, and all Intellectual Property Rights in, Your Data remain your
							property. However, your continued access to Your Data as hosted on the
							Subscription Service is contingent on full payment of the applicable
							subscription fees and an active subscription plan. You must maintain copies
							of all of Your Data that is uploaded. We implement our own policies and
							procedures to prevent data loss, but we do not make any guarantees that
							there will be no loss of Your Data or any other data in relation to the
							Subscription Services. We expressly exclude liability for any loss of Your
							Data (and any other data) no matter how caused.
						</p>
						<p>
							<strong className="heading">6.2 Your content obligations.</strong>
							You acknowledge that you will not: (a) publish, post, upload, distribute or
							disseminate any inappropriate, profane, defamatory, obscene, indecent or
							unlawful topic, name, material or information; or (b) upload, or otherwise
							make available, files that contain images, photographs, software or other
							material protected by Intellectual Property Rights, including, by way of
							example, and not as limitation, copyright or trademark laws (or by rights
							of privacy or publicity) unless you own or control the rights thereto or
							have received all necessary consent to do the same.
						</p>
						<p>
							<strong className="heading">6.3 IPR indemnity. </strong>
							You indemnify us against any claim, action, proceeding or demand by a third
							party arising from or in connection with our hosting and use of Your Data
							in connection with the Subscription Services, including but not limited to
							any claim alleging that Your Data or our handling or processing of Your
							Data infringes the Intellectual Property Rights of a third party, including
							without limitation all liability, damages, losses, costs (including without
							limitation court costs and legal fees), charges, outgoings, payments and
							expenses suffered or incurred in connection with any such claim.
						</p>
						<p>
							<strong className="heading">7. Changes to Subscription Services</strong>
						</p>
						<p>
							You acknowledge that the Subscription Services are on-line and
							subscription-based in nature, and that in order to provide improved
							customer experience we may from time to time make changes to the
							Subscription Services, and we may update the applicable Documentation
							accordingly. We can discontinue any part or feature of any Subscription
							Services for any reason at any time without liability to you. Where
							possible will provide you with commercially reasonable advance notice of
							any deprecation of any material feature or functionality. If we provide you
							with this type of notice, you may either cancel auto-renewal to end your
							subscription at the end of the current Subscription Term or elect to
							terminate your subscription earlier by providing us with written notice.
							Where you elect to terminate your subscription earlier than the end of your
							Subscription Term pursuant to this clause you will be entitled to a
							pro-rata refund of your pre-paid subscription fees for any unused period of
							the Subscription Term in which we provided you with such notice for period
							where the material deprecation will apply. This limited right to terminate
							will cease at the end of the then current Subscription Term.
						</p>
						<p>
							<strong className="heading">8. Disclaimer and liability</strong>
						</p>
						<p>
							<strong className="heading">8.1 ACKNOWLEDGEMENT. </strong>
							YOU ACKNOWLEDGE THAT YOUR USE OF THE SUBSCRIPTION SERVICES IS AT YOUR SOLE
							RISK. THE SUBSCRIPTION SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
							AVAILABLE" BASIS. WE DO NOT WARRANT THAT THE USE OF THE SUBSCRIPTION
							SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, ACCURATE OR
							VIRUS FREE. WE ARE NOT RESPONSIBLE FOR DELAYS, DISRUPTIONS OR OTHER FAULTS
							IN THE SUBSCRIPTION SERVICES CAUSED BY FACTORS BEYOND OUR REASONABLE
							CONTROL, INCLUDING BUT NOT LIMITED TO PROBLEMS WITH THE PUBLIC INTERNET OR
							YOUR COMPUTER SYSTEMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO ANY OF YOUR
							EQUIPMENT OR SOFTWARE RESULTING FROM YOUR USE OF THE SUBSCRIPTION SERVICES.
							IT IS YOUR SOLE RESPONSIBILITY TO DETERMINE THAT THE SUBSCRIPTION SERVICES
							AND ANY INTERACTIVES (AS APPLICABLE) MEET THE NEEDS OF YOU OR YOUR
							ORGANISATION.
						</p>
						<p>
							<strong className="heading">8.2 NO IMPLIED WARRANTIES.</strong>
							SUBJECT TO THE TERMS OF THIS CLAUSE 8, WE EXCLUDE ALL RIGHTS,
							REPRESENTATIONS, GUARANTEES, CONDITIONS, WARRANTIES, UNDERTAKINGS, REMEDIES
							OR OTHER TERMS IN RELATION TO THE SUBSCRIPTION SERVICES THAT ARE NO
							EXPRESSLY SET OUT IN THESE TERMS TO THE MAXIMUM EXTENT PERMITTED BY LAW. IF
							WE ARE LIABLE TO YOU UNDER AUSTRALIAN CONSUMER LAW IN RELATION TO A FAILURE
							TO COMPLY WITH A GUARANTEE THAT CANNOT BE EXCLUDED, WE LIMIT OUR LIABILITY
							TO THE RESUPPLY OF THE SERVICES OR THE PAYMENT OF THE COST OF RESUPPLY.
						</p>
						<p>
							<strong className="heading">8.3 MAXIMUM LIABILITY. </strong>
							SUBJECT TO THIS CLAUSE 8, OUR MAXIMUM AGGREGATE LIABILITY FOR ALL CLAIMS
							UNDER OR IN CONNECTION WITH THE SUBSCRIPTION SERVICES, WHETHER IN CONTRACT,
							TORT (INCLUDING NEGLIGENCE), IN EQUITY, UNDER STATUTE OR ON ANY OTHER
							BASIS, IS LIMITED TO AN AMOUNT EQUAL TO THE AMOUNTS PAID BY YOU UNDER THESE
							TERMS. WE ARE NOT LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL LOSSES
							OR FOR ANY LOSS OF PROFIT, LOSS OF REVENUE OR LOSS/CORRUPTION OF DATA OR
							SOFTWARE.
						</p>
						<p>
							<strong className="heading">8.4 LIABILITY NOT EXCLUDED.</strong>
							NOTHING IN THESE TERMS EXCLUDES OUR LIABILITY FOR: (A) DEATH OR PERSONAL
							INJURY CAUSED BY OUR NEGLIGENCE; (B) FRAUD OR FRAUDULENT MISREPRESENTATION;
							OR (C) LIABILITY THAT CANNOT BE EXCLUDED BY APPLICABLE LAW.
						</p>
						<p>
							<strong className="heading">9. General</strong>
						</p>
						<p>
							<strong className="heading">9.1 Notices.</strong>
							Any notice given under these Terms by either party to the other must be in
							writing by email and will be deemed to have been given on transmission.
							Notices to us must be sent to <a href="mailto:contact@catem.be">contact@catem.be</a> or
							to any other email address notified by email to you by us. Notices to you
							will be sent to the email address which you provided when subscribing to
							the relevant Subscription Service.
						</p>
						<p>
							<strong className="heading">9.2 Variation.</strong>
							No variation of these Terms between you and us will be effective unless it
							is in writing and signed by the parties (or their authorised
							representatives).
						</p>
						<p>
							<strong className="heading">9.3 No assignment.</strong>
							You may not assign or transfer any rights to any other person without our
							prior written consent.
						</p>
						<p>
							<strong className="heading">9.4 No waiver.</strong>
							If either party waives any breach of these Terms, this will not constitute
							a waiver of any other breach. No waiver will be effective unless made in
							writing.
						</p>
						<p>
							<strong className="heading">9.5 Force majeure.</strong>
							Neither party will be liable for any delay or failure in performance of its
							obligations under these Terms if the delay or failure is due to any cause
							outside its reasonable control. This clause does not apply to any
							obligation to pay money.
						</p>
						<p>
							<strong className="heading">9.6 Severability.</strong>
							If the whole or any part of a provision of these Terms is or becomes
							invalid or unenforceable, it is severed only to the extent that it is
							invalid or unenforceable and the remainder of these Terms remain binding on
							the parties.<strong> </strong>
						</p>
						<p>
							<strong className="heading">9.7 Survival and accrued rights.</strong>
							Clauses 1.4, 2.4, 3.4, 4, 6, 8 and 9 survive termination or expiry of these
							Terms. Termination of these Terms is without prejudice to the rights and
							obligations of the parties accrued up to and including the date of
							termination. On termination you will remain liable for any accrued charges
							and amounts which become due for payment before or after termination.
						</p>
						<p>
							<strong className="heading">9.8 Governing law and jurisdiction.</strong>
							These Terms will be governed by the laws of Victoria, Australia. Each party
							submits to the exclusive jurisdiction of the courts of Victoria, Australia
							in the event of a dispute arising out of, or in connection with, these
							Terms.
						</p>
						<p>
							<strong className="heading">9.9 Entire agreement.</strong>
							These Terms, and the terms of any other notices or instructions given to
							you under these terms, supersede and extinguish all prior agreements,
							representations (whether oral or written), and understandings and
							constitute the entire agreement between you and us relating to the
							Subscription Services and matters dealt with in these Terms.
						</p>
						<p>
							<strong className="heading">10. Definitions</strong>
						</p>
						<p>
							Capitalised terms not otherwise defined within these Terms have the
							following meanings:
						</p>
						<p>
							<em>"Authorised User"</em>
							&nbsp;means you and each of your student end users that are provided with access
							to your instance of the Subscription Services pursuant to your subscription
							plan as part of an educational program that you are responsible for.
						</p>
						<p>
							<em> "Documentation"</em>
							&nbsp;means our standard published documentation for the Subscription Services,
							including the documentation located at <a href="https://www.catem.be">catem.be</a>.
						</p>
						<p>
							<em>"Feedback"</em>
							&nbsp;means comments, questions, ideas, suggestions or other feedback relating to
							the Subscription Services, Interactives or support.
						</p>
						<p>
							<em>"Intellectual Property Right"</em>
							&nbsp;means any patent, trade mark, service mark, copyright, moral right, right
							in a design, know-how and any other intellectual or industrial property
							rights, anywhere in the world whether or not registered.
						</p>
						<p>
							<em>"Interactive"</em>
							&nbsp;means a module installed or otherwise activated within a Subscription
							Service that is accessible to you as part of your subscription plan. For
							the avoidance of doubt, this may include, without limitation, interactive
							modules such as games, simulations, experiments, activities, interactive
							diagram tools or interactive explanation tools.
						</p>
						<p>
							<em>"Subscription Services"</em>
							&nbsp;means our cloud-based infrastructure, platform and software solutions,
							including any accessible Interactives and any modifications or derivative
							works of the foregoing, including as they may incorporate Feedback.
						</p>
						<p>
							<em>"Your Data"</em>
							&nbsp;means any data, content, code, video, images or other materials of any type
							that you (including any of your Authorised Users) submit to Subscription
							Services, excluding Feedback. In this context, "submit" (and any similar
							term) includes submitting, uploading, transmitting or otherwise making
							available Your Data to or through the Subscription Services. For the
							avoidance of doubt, this may include, without limitation, questions and
							answers, written case studies, scenarios, examples, or student
							instructions, as well as any non-interactive diagrams or exhibits.
						</p>
						<p>
							<em>"Website"</em>
							&nbsp;means the website owned and operated by us at <a href="https://www.catem.be">catem.be</a>.
						</p>
					</div>
				</div>
			</div>
		)
	}
}
export default TOS
