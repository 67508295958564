import React, { Component } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { Icon } from 'react-icons-kit'
import {rocket} from 'react-icons-kit/icomoon/rocket'
import {arrowRight} from 'react-icons-kit/icomoon/arrowRight'

//import { BarLoader } from 'react-spinners'
import BarLoader from "react-spinners/BarLoader";

import '../css/Battleships.css'
import {basicFunctions} from '../include/basic.js'
import {FeedbackBox} from './basic.js'

class BattleShips extends Component {
   constructor(props) { 
		super(props)
		this.state = {launched: false, recentlyLaunched: false, selectedCell: [], launchedCell: []}
	}

	gotFireResult = () => { // Has our API call returned?
		if (this.props.turn && this.props.turn.shots && this.props.turn.shots.length > 0) {
			const lastShot = this.props.turn.shots.slice(-1)[0]
			if (lastShot.coords && lastShot.coords[0] === this.state.launchedCell[0] && lastShot.coords[1] === this.state.launchedCell[1]) {
				return true
			}
		}
		return false
	}

	onFire = () => {
		if (this.state.selectedCell.length == 2) {

			for (var sIdx in this.props.turn.shots) {
				const shot = this.props.turn.shots[sIdx]
				if (this.state.selectedCell[0] === shot.coords[0] && this.state.selectedCell[1] === shot.coords[1]) {
					this.setState({selectedCell: []})
					return
				}
			}

			this.setState(function(prevState) {
				return {launched: true, recentlyLaunched: true, launchedCell: prevState.selectedCell}
			})
			this.props.onFire(this.state.selectedCell)
			clearTimeout(this.timer_recentlyLaunched) // Don't turn off results if we're launching again while last results still shown

			this.timer = setTimeout(async () => { 
				while (!this.gotFireResult()) {
					await basicFunctions.sleep(100)
				}

				this.setState({launched: false, selectedCell: []}) 

				// Progress the game in 2 seconds if this was a hit
				if(this.props.turn.lastResult === 'hit') {
					//this.timer_recentlyLaunched = setTimeout( () => this.props.next() , 2000)
				}
				else {
					this.timer_recentlyLaunched = setTimeout( () => {
						this.setState((prevState) => {
							if (prevState.launched === false) { // Only turn off results if there hasn't been another launch in the meantime
								return {recentlyLaunched: false}
							}
						})
					}, 2000)

				}

				// We want to show the result message for the same couple of seconds
				await basicFunctions.sleep(2000)

			}, 1000)
		}
	}
	onSelectCell = (x, y) => {
		if (this.props.onSelectCell) {
			this.props.onSelectCell([x, y])
		}
		this.setState({selectedCell: [x, y]})
	}
	componentDidUpdate(prevProps, prevState) {
		// We're changing to a new company (i.e. lecturer controlled in live mode), reset selection
		if (prevProps.turn && this.props.turn && prevProps.turn.turnIdx !== this.props.turn.turnIdx) {
			this.setState((prevState) => 
				{ // Return everything to default. In live mode it's easy to change to next company while launch is in progress
					return {launched: false, recentlyLaunched: false, selectedCell: []}
				})
		}
	}
   render() {
		const isDisabled = this.state.launched | this.state.selectedCell.length !== 2 | !this.props.active | (this.props.turn && this.props.turn.lastResult === 'hit') | (this.props.correctAnswer !== undefined & this.props.correctAnswer !== null)
		//<motion.div className={"rocketIcon"} animate={this.state.recentlyLaunched ? 'launched' : 'ready'} variants={{ launched: { opacity: 1, y: "-100px" }, ready: {opacity: 1, y: 0}, }} transition={{ duration: [1] }} >


		var button = <div className="col-12 fireButtonContainer">
				{isDisabled ? <div className="_overlay"/> : <span/>}
				<motion.button type="button" 
					whileTap={{ scale: 0.9 }}
					transition={{ duration: 0.1 }}
					disabled={isDisabled}
					className="btn actionButton fireButton buttonGradient_blueGreen"
					onClick={this.onFire}>
					<div className={"rocketIcon"} >
						<Icon icon={rocket} size={32}/>
					</div>Fire
				</motion.button>
			</div>

		if (this.props.turn && (
				(this.props.turn.curAnswer && this.props.turn.curAnswer.length === 2) || 
				(this.props.turn && this.props.turn.lastResult === 'hit' && !this.state.launched)
			)) {
			button = <div className="col-12 fireButtonContainer">
					<motion.button type="button" 
						whileTap={{ scale: 0.9 }}
						transition={{ duration: 0.1 }}
						disabled={false}
						className="btn actionButton fireButton buttonGradient_blueGreen"
						onClick={this.props.next}>
							Continue
					</motion.button>
				</div>
		}

	    var skip = <span/>
		if (this.props.turn && this.props.turn.shots && this.props.turn.shots.length > 5 && this.props.turn.lastResult === "miss" && !this.state.launched && !this.props.turn.curAnswer) {
			skip = (<div className="col">
						<a className="_skip" onClick={this.props.showAnswer} tabindex="0">Just show me the answer</a>
				   </div>)
		}

		return (
			<div className="container-fluid">
				<div className="row text-center mt-3">
					<div className="col-12">
						<BattleShipsInfoArea 
							{...this.props}
							selectedCell={this.state.selectedCell}
							launched={this.state.launched}
							correctAnswer={this.props.turn ? this.props.turn.curAnswer : undefined}
							recentlyLaunched={this.state.recentlyLaunched}/>
					</div>
				</div>
				<div className="row text-center mt-3">
					<div className="col-12">
						{ !this.props.active ? <GameFinished {...this.props}/> : <span/> }
						<BattleShipsGrid x={this.props.x} y={this.props.y} 
							onSelectCell={this.onSelectCell}
							shots={this.props.turn ? this.props.turn.shots : undefined}
							correctAnswer={this.props.turn ? this.props.turn.curAnswer : undefined}
							{...this.state}/>
					</div>
				</div>
				<div className="row text-center mt-3">
					{button}
					{skip}
				</div>


			</div>
		)
	}
}
class BattleShipsInfoArea extends Component {
   render() {

		var message = ""
	    var explanation = ""
		var spinner = <span/>

		if (this.props.turn && this.props.turn.turnIdx === 0 && this.props.turn.shots.length === 0 && !this.props.launched) {
			message = [ <div>Welcome to Porter's Battleships!</div>,
						<div className="_para">The company above is hidden somewhere in the grid below. Take shots to find it!</div>]
		} 
	    else if (this.props.correctAnswer) {
			message = "Answer shown on grid"
			if (this.props.turn && this.props.turn.explanation) {
				explanation = this.props.turn.company + ": " + this.props.turn.explanation
			}
		}
	    else if (this.props.launched === true) {
			message = " *** MISSILE FIRED! *** "
			spinner = <BarLoader css={{width: '100%', position: 'absolute', bottom: '-10px', left:0}}/>
		}
		else if (this.props.recentlyLaunched === true && this.props.turn.lastResult !== undefined) {
			message = [" Result: ", (this.props.turn.lastResult === "miss" ? 
				<span key='result' className='result miss'>Missed</span> : this.props.turn.lastResult === "hit" ? 
				<span key='result' className='result hit'>Direct hit!</span> : "")]

			if (this.props.turn.hint) {
				message.push(<span key="hint" className="hint">{this.props.turn.hint}</span>)
			}
			if (this.props.turn && this.props.turn.explanation) {
				explanation = this.props.turn.company + ": " + this.props.turn.explanation
			}
		}
		else if (this.props.selectedCell.length === 2) {
			message = "Hit fire when ready!"
		}
		else {
			message = "Choose a cell to target"
		}

		if (this.props.turn) {
			return (
				<div className="bsInfoArea container">
					<div className="row">
						<div className="_target col">
							<div className="_label">Target {this.props.turn.turnIdx + 1} of {this.props.numCompanies}</div>
							<div className="_value">{this.props.turn.company}</div> 
						</div>
						<div className="_score col">
							<div className="_label">Score</div>
							<div className="_value">{this.props.results.score}</div>
						</div>
					</div>
					<div className="row _messages">
						<div className="col-12">
							<div>{message}</div>
							<div className="_para">{explanation}</div>
							{spinner}
						</div>
					</div>
				</div>
			)
		}
		return <span/>
	}
	
}
class BattleShipsGrid extends Component {
   render() {
		
		var gridDataSize = 0
		if (this.props.gridData) {
			var gridDataScale = Math.max(5, Math.max.apply(null, Object.values(this.props.gridData)))
			gridDataSize = Object.values(this.props.gridData).reduce((a,b) => a + b, 0)
		}

		var sc = this.props.selectedCell ? this.props.selectedCell : []
		var ans = this.props.correctAnswer ? this.props.correctAnswer : []
		const answerShown = this.props.correctAnswer === undefined ? false : true

		var grid = []	
		for (var y = 0; y < this.props.y; y++) {
			var row = []
			for (var x = 0; x < this.props.x; x++) {
				var selected = false
				if (sc.length == 2 && sc[0] == x && sc[1] == y) {
					selected = true
				}

				var correctAnswer = false
				if (ans.length == 2 && ans[0] == x && ans[1] == y) {
					correctAnswer = true
				}


				var fired = false
				var turnsSinceFired = -1
				var result = ""
				for (var sIdx in this.props.shots) {
					const shot = this.props.shots[sIdx]
					if (shot.coords[0] === x && shot.coords[1] === y) { // We've fired on the current location
						fired = true
						if (shot.coords[0] === this.props.launchedCell[0] && shot.coords[1] === this.props.launchedCell[1]) { // Latest shot
							if (shot.result && this.props.launched === false) { // Result is available and the 'launch period' is over
								result = shot.result
								turnsSinceFired = this.props.shots.length - sIdx
							}
						} else {
							result = shot.result
							turnsSinceFired = this.props.shots.length - sIdx
						}
					}	
				}

				var style = {width: (100 / this.props.x) + "%"}
				if (this.props.gridData) {
					const cellVal = this.props.gridData[String(x) + "," + String(y)]
					if (cellVal > 0) {
						style['backgroundColor'] = "hsla(1, 89%, 72%, " + (parseFloat(cellVal) / gridDataScale) + ")"
					}
				} 
				
				if (result === 'miss') {
					style['backgroundColor'] = "hsl(0, 0%, " + (90 - (30 / turnsSinceFired)) + "%)"
				}

				//{(result === 'miss' ? "M":"")}
				row.push(<div 
					key={"cell" + String(x) + String(y)}
					className={"_cell " + (selected ? "selected" : "") + (fired ? " fired" : "") + (result === 'hit' ? " hit" : "") + (result === 'miss' ? " miss" : "") + (correctAnswer ? "correct" : "")} 
					onClick={fired === false && this.props.onSelectCell ? this.props.onSelectCell.bind(null, x, y) : () => {} }
					style={style}>
						{(result === 'hit' ? "H":"")}
						{(result === 'miss' ? "M":"")}
				</div>)
			}
			grid.push(<div className='_row'
					style={{height: (100 / this.props.y) + "%"}}
					key={"row" + String(y)}
				>{row}</div>)
		}

		return ( 
			<div>
				<div className={"bsGridContainer " + (this.props.controlView ? "controlView" : "")}>
					<div className="_left">
						<div className="axisLabel main y">Market scope</div>
						<div className="axisLabel small y left">Narrow</div>
						<div className="axisLabel small y right">Broad</div>
					</div>
					<div className="bsGrid">
						{grid}
					</div>
					<div className="_bottomContainer">
						<div className="_left"/>
						<div className="_bottom">
							<div className="axisLabel main x">Uniqueness</div>
							<div className="axisLabel small x left">Cost leadership</div>
							<div className="axisLabel small x right">Differentiation</div>
						</div>
					</div>
					{ this.props.controlView && gridDataSize === 0 ? <button className="btn actionButton buttonGradient_blueGreen bsShowResults" onClick={() => this.props.sendControlAction('showPendingResults')}>Show hidden results</button> : <span/> }
					{ this.props.controlView && gridDataSize > 0 ? <button className="btn actionButton buttonGradient_blueGreen bsShowResults" onClick={() => this.props.sendControlAction('showPendingResults')}>Update results</button> : <span/> }
				</div>
			</div>
		)
	}
}

					/*<div className="_left"/>
					<div className="_bottom">
						<div className="axisLabel main x">Uniqueness</div>
						<div className="axisLabel small x left">Cost leadership</div>
						<div className="axisLabel small x right">Differentiation</div>
					</div>*/

class GameFinished extends Component {
	render() {
		const r = this.props.results
		if (this.props.results === undefined) {
			return <span/>
		}
		return (
			<div className="gameFinished">
				<div className="container gameCompleteBox">
					<div className="row justify-content-center">
						<div className="col">
							<div className="smallCapsHeading text-center _gameOver mb-4">GAME OVER</div>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col">
							<div className="finalScore container">
								<div className="row mb-3">
									<div className="col _label text-left">Final score</div>
									<div className="col _value text-right">{r.score} points</div>
								</div>
									{ this.props.liveMode ? <span/> : <div className="row mb-3">
										<div className="col _label text-left">Your high score</div>
										<div className="col _value text-right">{r.highScore} points</div>
									</div> }
							</div>
						</div>
					</div>
					{ this.props.liveMode ? <span/> :
						<div className="row justify-content-center">
							<div className="col">
								<motion.button
									type="button" 
									whileTap={{ scale: 0.9 }}
									transition={{ duration: 0.1 }}
									className="btn actionButton playAgainButton buttonGradient_redYellow"
									onClick={() => {this.props.onReset()}}>Play Again
								</motion.button>
							</div>
						</div> }
					{ this.props.liveMode ?  <FeedbackBox onChange={this.props.sendFeedback}/> : <span/> }
				</div>
			</div>

		)
	}
}

export { BattleShips, BattleShipsGrid }
