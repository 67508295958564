import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import { Icon } from 'react-icons-kit'

import ReactGA from "react-ga"

//import {question} from 'react-icons-kit/fa/question'
//import {ic_list} from 'react-icons-kit/md/ic_list'
//import {checkSquareO} from 'react-icons-kit/fa/checkSquareO'
//import {gavel} from 'react-icons-kit/fa/gavel'

//import {list} from 'react-icons-kit/icomoon/list'
//import {ic_grid_on} from 'react-icons-kit/md/ic_grid_on'
//import {dollar} from 'react-icons-kit/fa/dollar'
//import {clockO} from 'react-icons-kit/fa/clockO'
//import {flask} from 'react-icons-kit/fa/flask'
//import {users} from 'react-icons-kit/icomoon/users'

import {checkmark} from 'react-icons-kit/icomoon/checkmark'

import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare'
import {facebook} from 'react-icons-kit/fa/facebook'
import {twitter} from 'react-icons-kit/fa/twitter'

import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down'

import { motion } from "framer-motion"
import { basicFunctions } from './include/basic.js'

import {CatembeInteractiveApp, EmailSubscribeForm, ContactForm, ImageSection, FooterRow} from './components/frontpage.js'

import './css/FrontPage.css'
import './css/Talbot.css'

import { talbot } from './apps/talbot'
import { TalbotHeroDemo } from './components/talbot'
const app = talbot

class Talbot extends Component {
   constructor(props) {
      super(props)

      this.state = { 
			showContactForm: false,
			showHelpContactForm: false,
			messageSent: false,
			emailSubscribed: false,
		}
   }

   componentWillMount(){
   }

	componentDidMount() {
		setTimeout( () => {
				ReactGA.event({
					category: 'conversion',
					action: '1minOnFrontPage'
				})
			}, 60*1000)
	}
   
	getStarted = () => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({ category: 'conversion', action: 'showContactForm' })
		this.setState({ showContactForm: true })
	}

	helpUs = () => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({ category: 'conversion', action: 'showHelpContactForm' })
		this.setState({ showHelpContactForm: true })
	}

	apiSubmit = (data) => {
		//ga('send', 'event', 'conversion', 'sendContactForm');
		if (data.action === 'subscribe') { ReactGA.event({ category: 'conversion', action: 'submitEmailSubscribeForm' }) }
		else if (data.action === 'contact') { ReactGA.event({ category: 'conversion', action: 'submitContactForm' }) }
		const callback = (data) => {
			if (data.success === true) {
				if (data.action === 'subscribe') { this.setState({emailSubscribed: true})
				} else { this.setState({messageSent: true}) }
			}
		}
		basicFunctions.apiCall(data, callback)
	}

	render() {

		var features
		if (app && app.gameDetails && app.gameDetails.features) {
			features = app.gameDetails.features.map( feature => (
				<div className="col-md-6 col-sm-12 _feature">
					<img src={feature.imageUrl}/>
					<h3>{feature.heading}</h3>
					<p>{feature.description}</p>
				</div>)
			)
		}

		return (
			<div style={{backgroundColor: '#fff'}}>
				<Helmet> <title>Talbot | Interactive business case studies</title> </Helmet>
				<div className="container-fluid headerSection darkGreyGradient" style={{backgroundColor: '#f3f1f3', minHeight: '900px'}}>
					<div className="scrollIndicator">
						<Icon icon={ic_keyboard_arrow_down} size={48}/>
					</div>
					<div className="container-fluid" style={{maxWidth: '1200px'}}>
						<div className="row">
							<div className="col-lg-6 col-md-12 text-left" style={{minHeight: '400px'}}>
								<div className="jumboContainer" style={{backgroundColor: 'inherit', bottom: '0', maxWidth: '450px'}}>
									<div className='logoContainer mb-4'>
										<div className="talbotLogo">talbot_</div>
									</div>
									<h2 className="talbotLead mb-3">Use AI to create interactive business case studies</h2>
									<h2 className="lead text-left">Talbot is an AI-powered game engine for creating living business scenarios for students to explore.</h2>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 heroImage">
								<TalbotHeroDemo />
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid">

					<div className="row">
						<div className="col-12">
							<div className="container bodySection fatBottom appFeatures">
								<div className="row text-center">
									{features}
								</div>
							</div>
						</div>
					</div>

					{/*<div className="row">
						<div className="col-12">
							<div className="container bodySection topicsCovered fatBottom">
								<div className="row text-center _sectionHeader">
									<h1 className="mainTagline mb-4">Featured Simulations</h1>
								</div>
							</div>
						</div>
					</div>*/}
				</div>

				<div className="container-fluid footerSection" style={{backgroundColor: '#f6f6f6'}}>
					<div className="row mb-5 justify-content-center">
						<div className="col-sm-12 col-md-6 text-center">
							<div className="smallCapsHeading text-center">Educators</div>
							<h2 className="footerText display-5 mb-4">Get in touch to find out more.</h2>
							{ this.state.showContactForm ?
							<div className="container" id="enquireForm">
								<div className="row justify-content-center">
									<div className="col-md-10 col-lg-8"> 
										<ContactForm
											type="enquire"
											apiSubmit={this.apiSubmit}
											messageSent={this.state.messageSent}
											product="Talbot"
											/> 
									</div>
								</div>
							</div>
							:
							<button id="getStartedButton" className="btn actionButton buttonGradient_redYellow" onClick={this.getStarted}>Get started</button>
							}
						</div>
					</div>
					<FooterRow/>
				</div>

			</div>
		)
	}
}
export default (Talbot)
