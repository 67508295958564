import React, {PureComponent} from 'react'
//import ReactDOM from 'react-dom';
import '../css/Swipe.css'
import ReactSwipe from 'react-swipe';

class SwipeCarousel extends PureComponent {
	render() {
		let reactSwipeEl
		var slides = []
		for (const cIdx in this.props.children) {
			slides.push(<div className="carouselSlide">{this.props.children[cIdx]}</div>)
		}

		return (
				<div className="carouselContainer">
					<ReactSwipe
						className="carousel"
						swipeOptions={{ continuous: false }}
						ref={el => (reactSwipeEl = el)} >
						{slides}
					</ReactSwipe>
					<div className="carouselButtons">
						<div className="container">
							<div className="row">
								<div className="col-6">
									<button className='btn actionButton _previous' onClick={() => reactSwipeEl.prev()}>{"<"}</button>
								</div>
								<div className="col-6">
									<button className='btn actionButton _next' onClick={() => reactSwipeEl.next()}>{">"}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
		)
	}
}
export { SwipeCarousel }
