import React, {Component} from 'react'
import ReactSlider from 'react-slider'
import { Icon } from 'react-icons-kit'
import {checkCircle} from 'react-icons-kit/fa/checkCircle'
import ReactGA from "react-ga"
import '../css/Economics.css'

class SupplyDemandCaptcha extends Component { 
   constructor(props) { 
		super(props)
		this.state = {
			price: 0,
			eqPrice: 2.5,
			touched: false,
		}

		// Set starting price randomly, but make sure it's not the correct one
		if (Math.random() < 0.5) {
			this.state.price = Math.round(Math.random() * 2.3 * 10) / 10
		} else {
			this.state.price = Math.round((2.7 + (Math.random() * 2.3)) * 10) / 10
		}

	}
	updatePrice = (p) => {
		this.setState((prevState) => {
			if (prevState.touched === false) {
				ReactGA.event({
					category: 'conversion',
					action: 'playWithCaptcha'
				})

				return {touched: true, price: p}
			
			}
			return {price: p}
		})
	}
   render() {
		return (
			<div className="container supplyDemandCaptcha">
				<div className="row">
					<div className="col-6">
						<div className="_chart">
							<div className="_axisLabel _y">P</div>
							<div className="_axisLabel _x">Q</div>
							<div className="_chartInner">
								<div className="_demand _curve"/>
								<div className="_supply _curve"/>
								<div className="_price_curve" style={{left: String(Math.min(99, (this.state.price / 5) * 100)) + "%"}}/>
								
								{ this.state.price < this.state.eqPrice ? 
									<div className="_outcomeLabelContainer">
										<div className="_outcomeLabel _exDemand">Excess demand</div>
									</div> : 
									(this.state.price == this.state.eqPrice ? 
										<div className="_outcomeLabelContainer"> 
											<Icon icon={checkCircle} style={{color: 'var(--color-green)'}} size={32}/>
										</div>
										:
										<div className="_outcomeLabelContainer">
											<div className="_outcomeLabel _exDemand">Excess supply</div>
										</div> )
								}
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="_question">What is the equilibirum price?</div>
						<div className="_price">
							<div className="_num bigNumber">${this.state.price.toFixed(2)}</div>
							<div className="_slider">
								<ReactSlider
									value={this.state.price}
									min={0}
									max={5}
									step={0.1}
									onChange={ (x) => this.updatePrice(x) }
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export { SupplyDemandCaptcha }
