import React from 'react'
import {Title, Description, StudentHeader, StudentAccessInstructions, StudentAccessHeader, WaitingForStart, IntroPage, ButtonFooter, GameFinished} from '../components/basic.js'
import {BasicControlView, ControlButton, DemoControlView} from '../components/control.js'
import {ChallengeAsyncPrompt, ChallengeHeader, Challenge_ControlPanel, Challenge_DemoControlPanel} from '../components/challenge.js'
import {JournalEntryChallengeInput, JournalEntries_LiveDisplayView} from '../components/accounting.js'
import {challenge} from '../include/challenges.js'
import update from 'immutability-helper'

import { FrontendAppSettings } from './FrontendAppSettings'

const gameDetails = {
	gameId: 'lectInt-JE', // Unique identifier for this game
	appRoute: 'je', // Determines url of app
	title: "Journal Entries Challenge",
	description: "Interactive tool to create journal entries",

	discipline: "Accounting", // Shown on frontpage tile and product page
	veryShortTitle: "Journal Entries", // Used in string 'Use {veryShortTitle} in your course'
	shortTitle: "Journal Entries Challenge", // Used as interactive name on FrontPage
	shortDescription: "Flexible framework for tasks based on journal entries", // Description on FrontPage

	videoUrl: "https://d3tx1wevfc318r.cloudfront.net/Video/iPhone_JE.mp4", // Video on frontPage and Product page
	//asyncDemoUrl: "/app/public/je/async_demo/je_demo/student",

	liveMode: {
		studentVideoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/JE_LiveModeDemo_Student.mp4',
		displayVideoUrl: 'https://d3tx1wevfc318r.cloudfront.net/Video/JE_LiveModeDemo_Display.mp4',
		longDescription: <p>In <span className="liveMode">live mode</span>, students solve the challenges in real time. Student answers are grouped together -- into economically equivalent transactions -- and displayed on the projector.</p>,
		//demo: { appConfig: 'live_demo', },
	},

	longDescription: [
		<p>This interactive provides a flexible framework for assigning quizzes and tasks based on journal entries. Assign a variety of tasks: you can ask students to create, complete, or correct transactions.</p>,
		<p>Available in single-player or live/multi-player modes.</p>
	],
	topicsCovered: ['Journal entries', 'Accounting transactions', 'Debits and credits']
}

var je = new FrontendAppSettings(gameDetails)

je.setInitialState(challenge.initialState) // Use generic challenge initialState
je.config.studentWidescreenView = true

je.appConfigs = { // Contents of matching appConfig gets thrown into app.state.content
	async_demo: {
		initialState: {
         accounts: [
            {name: "Cash"},
            {name: "Inventory"},
            {name: "Sales"},
            {name: "Accounts Payable"},
            {name: "Accounts Receivable"},
            {name: "GST Paid"},
            {name: "GST Received"},
         ],
			options: {
				allowAddTransaction: false,
				allowAddLine: false,
				allowRemoveLine: false,
			}
		}
	},
	live_demo: {
		initialState: {
         accounts: [
            {name: "Cash"},
            {name: "Inventory"},
            {name: "Sales"},
            {name: "Accounts Payable"},
            {name: "Accounts Receivable"},
            {name: "GST Paid"},
            {name: "GST Received"},
         ],
			options: {
				allowAddTransaction: false,
				allowAddLine: false,
				allowRemoveLine: false,
			}
		}
	}
}

je.lifecycle = {
	STUDENT: {
		onAuthentication: function() {
			this.sendInput({action: 'enrol', trigger: 'auth'})
		},
		componentDidMount: function() {
			// Load initial state from appConfig if provided
			if(this.content.initialState) {
				this.setState({data: this.content.initialState})
			}
		}
	}
}

je.stages = {
	DEMO: function() {
		return (
			<DemoControlView
				stage = {this.state.stage}
				active = {this.state.active}
				numClients = {this.state.numClients}
				clients = {this.state.clients}
				refreshSession = {this.refreshSession}
				sendAction = {this.sendAction}>
					{ this.state.liveMode ?
						<Challenge_DemoControlPanel
							currentQn = {this.state.currentQn}
							challengeActive = {this.state.challengeActive}
							sendAction = {(item) => { this.sendAction(item) }}
						/> : <span/> }
					<div className="col" id="tour-endSession">
						<ControlButton onClick={() => { if(window.confirm("This will end the game and show students a GAME OVER screen. Are you sure?")) { this.sendAction('endLiveGame') }
							}} dangerous={true}> End live session </ControlButton>
					</div>
			</DemoControlView>
		)
	},
	CONTROL: function() { // Control only has one 'stage'
		var challengeControlPanel = ( <Challenge_ControlPanel
					currentQn = {this.state.currentQn}
					challengeActive = {this.state.challengeActive}
					sendAction = {(item) => { this.sendAction(item) }}/>)
		return (
			<div>
				<BasicControlView
					appState = {this.state}
					sendAction = {this.sendAction}
					stages = {this.gameDetails.stageNames}
					appView = {this.state.appView}
				>{challengeControlPanel}</BasicControlView>
			</div>)
	},
	STUDENT: { // render() function for each stage
		'0': function() {
			if ( this.state.liveMode === true && (!this.state.challenge || !this.state.challengeActive )) {
				return (<WaitingForStart/>)
			}
			else if ( this.state.liveMode === true && this.state.gameActive === false ) {
				return (<GameFinished />)
			}

			const updateData = (item, value) => {
				//this.updateStateItem(this, item, value)

				this.setState((prevState) => {
					var newData = update(prevState.data, {[item]: {$set: value}})
					return {data: newData}
				}, () => { // After state has been updated, send input
					this.sendInput_debounced({action: 'submitInput', data: this.state.data})
				})
			}

			// If not loaded, load current Qn state
			if (this.state.challengeCurrentQn !== this.state.currentQn) {
				if (this.state.challenge && this.state.challenge.state) {
					this.setState( {
						data: this.state.challenge.state,
						challengeCurrentQn: this.state.currentQn,
					})
				}
			}

			if (this.state.complete) {
				var q_completed = 0
				var avg_time = "0s"
				try {
					q_completed = Object.keys(this.state.challengeTimes).length
					avg_time = (Object.values(this.state.challengeTimes).reduce((a, b) => a + b) / q_completed).toFixed(2) + "s per question"
				} catch(e) {
					console.log("Error calculating results")
					console.log(e)
				}

			}

			return (
				<div className="jeStudentViewContainer">
					{ this.state.complete === true ?
						<GameFinished
							results={[
								{label: 'Questions completed', value: q_completed},
								{label: 'Average completion time', value: avg_time},
							]}
							playAgain={true}
							onPlayAgain={() => this.sendAction('startAsyncChallenge') }
						/> : <span/> }
					{ this.state.complete !== true && this.state.challengeActive !== true && this.state.liveMode === false ?
						<ChallengeAsyncPrompt
							sendAction = {(item) => { this.sendAction(item) }}
							description = {this.state.introDescription}
						/> : <span/> }
					{ this.state.challenge && this.state.challengeActive ?
						<ChallengeHeader
							{...this.state.challenge}
							liveMode={this.state.liveMode}
							currentQn={this.state.currentQn}
							challengeStarted={this.state.challengeStarted}
							result={this.state.challengeResults[this.state.currentQn]}
							onTimeout={() => this.sendAction('onChallengeTimeout') }
							sendAction = {this.sendAction}
						/> : <span/> }
					{ this.state.challenge && this.state.challengeActive ?
						<JournalEntryChallengeInput
							transactions={ this.state.data.transactions ? this.state.data.transactions : []}
							accounts = { this.state.data.accounts }
							options = { this.state.data.options }
							currentQn={this.state.currentQn}
							updateData={updateData}
							/> : <span/> }
				</div>
			)
		},
		'1': function() { // wtf
		},
	},
	DISPLAY: {
		'0': je.getDefaultStage('DISPLAY', '0'),
		'1': function() {
			return ( <JournalEntries_LiveDisplayView
							key={'ldv'+this.state.currentQn}
							challenge={this.state.challenge}
							currentQn={this.state.currentQn}
							results={this.state.results}
							challengeActive = {this.state.challengeActive}
							challengeStarted={this.state.challengeStarted}
							sendControlAction={this.sendControlAction}
						/> )

		},
		'2': function() {
			return ( <JournalEntries_LiveDisplayView
							key={'ldv'+this.state.currentQn}
							challenge={this.state.challenge}
							currentQn={this.state.currentQn}
							results={this.state.results}
							challengeActive = {this.state.challengeActive}
							challengeStarted={this.state.challengeStarted}
							sendControlAction={this.sendControlAction}
						/> )

		},
		'3': function() {
			return ( <JournalEntries_LiveDisplayView
							key={'ldv'+this.state.currentQn}
							challenge={this.state.challenge}
							currentQn={this.state.currentQn}
							results={this.state.results}
							challengeActive = {this.state.challengeActive}
							challengeStarted={this.state.challengeStarted}
							sendControlAction={this.sendControlAction}
						/> )

		},
	},
}

export { je }
