import React, {Component} from 'react'
import { SupplyDemandCaptcha } from './economics'
import { useForm } from 'react-hook-form'
import ReactGA from "react-ga"
import { basicFunctions } from '../include/basic.js'

import { Icon } from 'react-icons-kit'

import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare'
import {facebook} from 'react-icons-kit/fa/facebook'
import {twitter} from 'react-icons-kit/fa/twitter'

var seedrandom = require('seedrandom')

class ImageSection extends Component {
	state = { src: null };

	componentDidMount() {
		const imageLoader = new Image();
		imageLoader.src = this.props.src;
		imageLoader.onload = () => {
			this.setState({ src: this.props.src });
		}
	}

	render() {
		var style = {}
		for (var attr in this.props.style) {
			style[attr] = this.props.style[attr]
		}
		if (this.state.src) {
			style.backgroundImage = "url(" + this.state.src + ")"
		}
		
		return (<div className="col-12 imageSectionBg" style={style}>
			{this.props.children}
			</div>)
	}


}
class CatembeInteractiveApp extends Component {
   //constructor(props) { super(props) this.state = {} }
	render() {
		var game = this.props.app.gameDetails

		var rng_name = seedrandom(game.shortTitle + '1')
		const randomDeg = (rng_name() * 360) - 180
		const randomColor1 = ['--color-red', '--color-darkred', '--color-darkblue', '--color-blue', '--color-green', '--color-yellow'][Math.floor(rng_name() * 5.99)]
		var randomColor2 = randomColor1
		while(randomColor1 == randomColor2) {
			randomColor2 = ['--color-red', '--color-darkred', '--color-darkblue', '--color-blue', '--color-green', '--color-yellow'][Math.floor(rng_name() * 5.99)]
		}

		var onClick = null
		if (this.props.onClick) { onClick = () => this.props.onClick(game.appRoute) }
		if (game.appRoute === 'breakeven') {
			onClick = () => window.location = 'https://www.breakeven.app'
		}

		return (
			<a href={this.props.href}>
				<div className={"catembeInteractiveApp" + (this.props.selectedApp === game.appRoute ? " active" : "")} 
						onMouseEnter={() => {if(this.props.onHover) { this.props.onHover(game.appRoute) }}}
						onClick={onClick}>
					<div className="_img">
						<div className="appDeploymentBall" style={{
							background: 'linear-gradient('+String(randomDeg)+'deg, var('+String(randomColor1)+') 0%,var('+String(randomColor2)+') 100%)',
						}}/>
					</div>
					<div className="_body">
						<div className="container">
							<div className="row">
								<div className="col-sm-auto col-xs-12 _name">{game.shortTitle} {game.isNew ? <div className="_new">new</div> : <span/> }</div>
								<div className="col col-xs-12 _discipline">{game.discipline}</div>
							</div>
							<div className="row">
								<div className="col _description">{game.shortDescription}</div>
							</div>
						</div>
					</div>
				</div>
			</a>
		)
	}
}

function EmailSubscribeForm(props) {
	const { register, handleSubmit, watch, errors } = useForm()

	var messageSent, setMessageSent
	if (props.messageSent) {
		messageSent = props.messageSent
	} else {
		[messageSent, setMessageSent] = React.useState(false)
	}

	const onSubmit = data => { 
		data.action = props.action === undefined ? 'subscribe' : props.action
		props.apiSubmit(data)

		if (setMessageSent) {
			setMessageSent(true)
		}
	}

	var confirmMessage = props.confirmMessage === undefined ? "Thank you for your interest!" : props.confirmMessage
	if (messageSent) {
		return (
			<div className="alert alert-success">{confirmMessage}</div>
		)
	}
	
	var errorMsg = []
	for (var field in errors) {
		errorMsg.push(<li key={field}>Please enter a valid {field}</li>)
	}

	var showCaptcha = props.showCaptcha === undefined ? true : props.showCaptcha

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="contactForm">
			<div className="form-group">
				<input type="text" className="form-control" aria-describedby="Name" placeholder="Your name" name="name" ref={register({required: true, maxLength: 200})} />
			</div>

			<div className="form-group">
				<input type="text" className="form-control" placeholder="Your email address" name="email" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
				<small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
			</div>

			{ showCaptcha ?
				<div className="form-group">
					<div className="container">
						<div className="row">
							<label htmlFor="captcha" className="col col-form-label">Are you a robot?</label>
						</div>
						<div className="row">
							<div className="col">
								<SupplyDemandCaptcha />
							</div>
						</div>
					</div>
				</div> :
				<span/> }

			{ errorMsg.length > 0 ?
				<div className="alert alert-danger"><ul className="text-left">{errorMsg}</ul></div> : <span/>
			}
			<input className="btn buttonGradient_green mt-3 actionButton" type="submit" value="Submit"/>
		</form>
	)
}

function ContactForm({ type, apiSubmit, messageSent, darkBg, product, contactReason }) {
	const { register, handleSubmit, watch, errors } = useForm()
	const onSubmit = data => { 
		data.action = 'contact'
		data.product = product
		data.type = type
		apiSubmit(data)
	}

	if (messageSent) {
		return (
			<div className="alert alert-success">Thank you for your enquiry. We'll be in touch shortly!</div>
		)

	}
	
	var errorMsg = []
	for (var field in errors) {
		errorMsg.push(<li key={field}>Please enter a valid {field}</li>)
	}

	var requireContactReason = contactReason === undefined ? false : contactReason

	//<div className="form-group"> <select className="form-control" ref={register} name="discipline" defaultValue=""> <option value="" disabled>Select discipline</option> <option>Accounting</option> <option>Finance</option> <option>Economics</option> <option>Management/Marketing</option> <option>Other</option> </select> </div>

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="contactForm">
			<div className="form-group">
				<input type="text" className="form-control" aria-describedby="Name" placeholder="Your Name" name="name" ref={register({required: true, maxLength: 200})} />
			</div>

			<div className="form-group">
				<input type="text" className="form-control" placeholder="Your Email Address" name="email" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
				<small id="emailHelp" className={"form-text text-muted" + (darkBg ? " white" : "")}>We'll never share your email with anyone else.</small>
			</div>

			<div className="form-group">
				<input type="text" className="form-control" placeholder="Institution" name="institution" ref={register} />
			</div>

			{ requireContactReason ?
				<div className="form-group"> 
					<select className="form-control" ref={register} name="contactReason" defaultValue=""> 
						<option value="" disabled>I want to...</option>
						<option>Receive more information about Breakeven</option> 
						<option>Arrange a Zoom demo</option> 
						<option>Try it out myself</option> 
					</select> 
				</div> : <span/> }


			{ type === 'query' ? 
				<div className="form-group">
					<textarea name="message" ref={register} className="form-control" placeholder="How can we help?" />
				</div> : <span/> }
			{ errorMsg.length > 0 ?
				<div className="alert alert-danger"><ul className="text-left">{errorMsg}</ul></div> : <span/>
			}

			<input className="btn buttonGradient_green mt-3 actionButton" type="submit"/>
		</form>
	)
}

class ShowcaseCTAFooter extends Component {
   constructor(props) { 
		super(props) 
		this.state = {showContactForm: false, messageSent: false} 
	}
	apiSubmit = (data) => {
		//ga('send', 'event', 'conversion', 'sendContactForm');
		ReactGA.event({ category: 'conversion', action: 'showcaseContactForm' })
		const callback = (data) => {
			if (data.success === true) {
				this.setState({messageSent: true})
			}
		}
		basicFunctions.apiCall(data, callback)
	}
	render() {
		if (this.props.appRoute === undefined) {
			return (<span/>)
		}
		return (
			<div className="container-fluid footerSection blueGradient" style={{backgroundColor: '#f6f6f6'}}>
				<div className="row justify-content-center">
					<div className="col-sm-12 col-md-8 col-lg-6 text-center footerText">
						<h2 className="display-4 mb-5">We make serious games for teaching business.</h2>
						<h3 className="display-5 mb-5 mt-5">You can easily add <a href="/">our interactive content</a> to your course: single- or multi-player, asynchronous or live, in-person or online.</h3>
						<h3 className="display-5 mb-5">Want to find out more?</h3>
						{ this.state.showContactForm ?
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-lg-4 col-md-6 col-sm-12"> 
										<ContactForm
											messageSent={this.state.messageSent}
											type="showcaseContact"
											product={this.props.appRoute}
											darkBg={true}
										/> 
									</div>
								</div>
							</div>
							:
							<button className="btn actionButton buttonGradient_redYellow" onClick={() => this.setState({showContactForm: true})}>Get in touch!</button>
						}
					</div>
				</div>
				<FooterRow darkBg={true}/>
			</div>

		)
	}
}

function FooterRow(props) {
	return (
		<div className="row footerRow">
			<div className="col text-center">
				<div className={"socialLinks" + (props.darkBg ? " darkBg" : "")}>
					<a href="https://www.facebook.com/www.catem.be"><Icon icon={facebook} size={30}/></a>
					<a href="https://www.linkedin.com/company/catembe"><Icon icon={linkedinSquare} size={30}/></a>
					<a href="https://www.twitter.com/catembegames"><Icon icon={twitter} size={30}/></a>
				</div>
				<div className="footerCopyright">© {new Date().getFullYear()}, <a href="/">Catembe</a>. <a href="mailto:contact@catem.be">Email us</a>.</div>
			</div>
		</div>
	)
}


export { CatembeInteractiveApp, ContactForm, ImageSection, EmailSubscribeForm, ShowcaseCTAFooter, FooterRow}
