const uuidv4 = require('uuid/v4')
const update = require('immutability-helper')
var debounce = require('debounce')
module.exports = { // Common to all apps
	getClientId: function() {
		return uuidv4()
	},
	nextStage() {
		//console.log('set state to ' + (this.state.localStage + 1))
		this.setState((prevState) => ({localStage: prevState.localStage += 1}))
	},
	prevStage() {
		//console.log('set state to ' + (this.state.localStage + 1))
		this.setState((prevState) => ({localStage: prevState.localStage -= 1}))
	},
	goToStage(n) {
		this.setState((prevState) => ({localStage: n}))
	},
	updateStateItem(item, value) {
		this.setState((prevState) => {
			var newState = prevState
			newState[item] = update(prevState[item], {$set: value})
		})
	},
	sendFeedback(item, feedback) {
		const input = {
			action: 'sendFeedback',
			item: item,
			feedback: feedback,
		}
		this.sendInput(input)
	},
	sendEnquiry(data) {
		this.apiCall(data, 'contact')
	},
	sendSocketInput(input, overrides, callback) {
		//console.log(this.route)

		var clientId = this.state.clientId
		if (clientId === undefined) {
			if (this.state.user && this.state.user.id) { // Use user id if we have it
				clientId = this.state.user.id
			}
		}

		var payload = {clientId: clientId, 
			gameId: this.gameDetails.gameId, 
			appDeploymentId: this.gameDetails.appDeploymentId, 
			route: this.route, 
			localStage: this.state.localStage, 
			sessionId: this.state.sessionId, 
			input: input}

		if (overrides) { // Allow any request param to be overwritten by passing in object
			for (var oItem in overrides) {
				payload[oItem] = overrides[oItem]
			}
		}

		// If the app has a particular config, use it
		if (this.appConfig) { payload.appConfig = this.appConfig }

		var route = this.gameDetails.appRoute
		if (this.gameDetails.appApiRoute !== undefined) {
			route = this.gameDetails.appApiRoute
		}
		this.socket.emit('app/' + route + '/update', payload, (result) => { console.log('Socket response', result) })
	},
	sendInput(input, overrides, callback) {
		//console.log(this.route)

		var clientId = this.state.clientId
		if (clientId === undefined) {
			if (this.state.user && this.state.user.id) { // Use user id if we have it
				clientId = this.state.user.id
			}
		}

		var payload = {clientId: clientId, 
			gameId: this.gameDetails.gameId, 
			appDeploymentId: this.gameDetails.appDeploymentId, 
			route: this.route, 
			localStage: this.state.localStage, 
			sessionId: this.state.sessionId, 
			input: input}

		if (overrides) { // Allow any request param to be overwritten by passing in object
			for (var oItem in overrides) {
				payload[oItem] = overrides[oItem]
			}
		}

		// If the app has a particular config, use it
		if (this.appConfig) { payload.appConfig = this.appConfig }

		var route = this.gameDetails.appRoute
		if (this.gameDetails.appApiRoute !== undefined) {
			route = this.gameDetails.appApiRoute
		}
		this.apiCall(payload, 'app/' + route + '/update', this.updateStateFromServer,
			(error) => {
				this.setState({lastApiError: new Date(), lastApiErrorText: error}) // Trigger setState so indicators will refresh to show that API is dead
			})
	},
	updateStateFromServer(result) {
		this.setState((prevState) => {
			if (!prevState.user && result.user) {
				if (this.socket) {
					// When sending via socket, no need to specify socket id (it is added by server in appRoutes.js)
					this.sendSocketInput({'action': 'socketEnrol'}, {clientId: result.user.id})
				}

				if (this.lifecycle && this.lifecycle.onAuthentication) {
					this.lifecycle.onAuthentication()
				}
			}
			else if (this.socket && result.socket_id === null) { // The server has lost our socket id. Perhaps it reset the game?
				this.sendSocketInput({'action': 'socketEnrol'}, {clientId: result.user.id})
			}

			/*
			// Turns out this is a bad idea. If you have two sessions open, they'll fight over which socket ID to use!
			else if (this.socket && this.socket.id !== result.socket_id) {
				console.log('my socket id', this.socket.id, 'doesnt match server socket id of', result.socket_id)
				this.sendSocketInput({'action': 'socketEnrol'}, {clientId: result.user.id})
			}*/
			
			var newState = prevState
			for (var item in result) { 
				newState[item] = result[item]
			}
			newState.lastApiCall = new Date()
			return newState
		})
	},
	//sendInput_debounced: debounce(this.sendInput, 500).bind(this),
	getDebounced_sendInput(delay) { // Returns a debounced version of sendInput you can all use in your scripts
		if (delay === undefined) { delay = 500 }
		return debounce(this.sendInput, delay).bind(this) // XXX put this code in FrontendAppSettings instead
	},
	sendControlAction(action) {
		if (typeof(action) == 'string') { action = {action: action} }
		this.sendInput(action, {route: "CONTROL"})
	},
	sendAction(action) {
		if (typeof(action) == 'string') { action = {action: action} }
		this.sendInput(action)
	},
	sendInitialUpdate() {
		this.sendInput({'action': 'enrol'})
	},
	sendUpdate() {
		var clientId = this.state.clientId
		if (clientId === undefined) {
			if (this.state.user && this.state.user.id) { // Use user id if we have it
				clientId = this.state.user.id
			}
		}

		var payload = {
			clientId: clientId,
			gameId: this.gameDetails.gameId,
			appDeploymentId: this.gameDetails.appDeploymentId, 
			route: this.route,
			localStage: this.state.localStage,
		}

		// If the app has a particular config, use it
		if (this.appConfig) { payload.appConfig = this.appConfig }

		var route = this.gameDetails.appRoute
		if (this.gameDetails.appApiRoute !== undefined) {
			route = this.gameDetails.appApiRoute
		}

		this.apiCall(payload, 'app/' + route + '/update', this.updateStateFromServer,
			(error) => {
				this.setState({lastApiError: new Date(), lastApiErrorText: error}) // Trigger setState so indicators will refresh to show that API is dead
			})
	},
	periodicUpdate(first) {

		if (this.route === 'STUDENT' && this.config.noStudentPeriodicUpdate === true) {
			return
		}

		// If coming from catem.be - this.props.apiCall will be passed in (uses authentication)
		// Otherwise we can just use our own this.apiCall.
		if (this.timerObj) { // Disable timer
			//console.log('this.timerObj active')
			clearTimeout(this.timerObj)
		}

		// Only start sending these on the 2nd call (after timeout below)
		if (first === false) { 
			this.sendUpdate()
		}

		// Set timer for next one
		this.timerObj = setTimeout( () => this.periodicUpdate(false), this.config.updateIntervalInSecs * 1000 )
	},
}
