import React, {Component} from 'react'
import '../css/Marketing.css'

class PersonaCard extends Component { 
   //constructor(props) { super(props) }
   render() {

		var namedAttributes = []
		for (var na in this.props.namedAttributes) {
			namedAttributes.push(
				<div className="row _attrRow _naRow">
					<div className="col label">
						{na}
					</div>
					<div className="col value">
						{this.props.namedAttributes[na]}
					</div>
				</div>)
		}

		var listAttributes = []
		for (var lIdx in this.props.listAttributes) {
			listAttributes.push(
				<div className="row _attrRow _laRow">
					<div className="col">
						{this.props.listAttributes[lIdx]}
					</div>
				</div>)
		}

		if (this.props.widescreen) {
			return (
				<div className="personaCard container">
					<div className="row">
						<div className="col">
							<div className="_imgContainer">
								{ this.props.img ? <img className="img-fluid" src={this.props.img}/> : <span/> }
							</div>
						</div>
						<div className="col">
							<div className="_name text-center bigHeading mt-5">
								{ this.props.name }
							</div>
							<div className="_attributesContainer">
								<div className="_namedAttributes">
									<div className="container">
										{namedAttributes}
										{listAttributes}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>)
		}

		return (
			<div className="personaCard">
				<div className="_imgContainer">
					{ this.props.img ? <img className="img-fluid" src={this.props.img}/> : <span/> }
				</div>
				<div className="_name text-center bigHeading">
					{ this.props.name }
				</div>
				<div className="_attributesContainer">
					<div className="_namedAttributes">
						<div className="container">
							{namedAttributes}
							{listAttributes}
						</div>
					</div>
				</div>
			</div>)
   }
}

export { PersonaCard }
