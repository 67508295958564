import React, { Component } from 'react'
import TimeAgo from 'react-timeago'
import './css/UserPage.css'
import withAuth from './withAuth';
import AuthService from './utils/auth'

import { Icon } from 'react-icons-kit'
import { checkCircle } from 'react-icons-kit/fa/checkCircle'
import { externalLink } from 'react-icons-kit/fa/externalLink'

import { CatembeNavBar } from './components/basic.js'

import Dropdown from 'react-bootstrap/Dropdown'

const auth = new AuthService()
var seedrandom = require('seedrandom')

class User extends Component {
   constructor() {
      super()
      this.handleLogout = this.handleLogout.bind(this)
      this.getUserInfo = this.getUserInfo.bind(this)
      this.updateUserInfo = this.updateUserInfo.bind(this)
      this.state = {
         deployments: undefined,
         isLoading: true, 
         points: undefined,
      }
   }

   handleLogout() {
      auth.logout()
      this.props.history.replace('/');
   }
	componentWillMount() {
	}

   componentDidMount() {
      this.getUserInfo()
   }

   getUserInfo() {
      auth.apiCall({}, 'user', this.updateUserInfo)
   }

   updateUserInfo(data) {
		var deplBySubject = {}
      for (var dIdx in data.deployments) {
			if (deplBySubject[data.deployments[dIdx].subject]) {
				deplBySubject[data.deployments[dIdx].subject].push(data.deployments[dIdx])
			} else {
				deplBySubject[data.deployments[dIdx].subject] = [data.deployments[dIdx]]
			}
		}

      this.setState({ isLoading: false, points: data.points, deployments: data.deployments, deplBySubject: deplBySubject})
   }

	render() {
		if (!this.props.user) {
			return <span/>
		}
      var deployments = []
      var loading = <div className="appDeployment text-center">Loading...</div>

		for (var subject in this.state.deplBySubject) {
			deployments.push(<div className="deplSubjectHeading">{subject}</div>)
			for (var dIdx in this.state.deplBySubject[subject]) {
				const d = this.state.deplBySubject[subject][dIdx]

				var adminUser = false
				if (d.url_display && d.url_control) { adminUser = true }

				var completedIcon = ""
				if (d.completed && !adminUser) { // Only show tick for non-admin users
					completedIcon = <Icon icon={checkCircle} size={20} className="completedTick" /> 
				}

				var upcomingInfo = ""
				if (new Date() < new Date(d.startDateUTC)) {
					upcomingInfo = (<div className="row availInfo">
											<div className="col"><b>Available to students</b>: <TimeAgo date={d.startDateUTC}/></div>
										</div>)
				}

				var deploymentName = ""
				if (d.deploymentName) {
					deploymentName = (<div className="row deploymentName">
											<div className="col">{d.deploymentName}</div>
										</div>)
				}
		
				var statusClass = ""
				if (d.active !== null) {
					if (d.active === true) {
						statusClass = " appStatus_active"
					}
				}

				var rng_game = seedrandom(d.url + '1')
				var rng_subject = seedrandom(d.subject + 'r49')

				const randomDeg = (rng_game() * 360) - 180
				const randomColor1 = ['--color-red', '--color-darkred', '--color-darkblue', '--color-blue', '--color-green', '--color-yellow'][Math.floor(rng_subject() * 5.99)]
				var randomColor2 = randomColor1
				while(randomColor1 === randomColor2) {
					randomColor2 = ['--color-red', '--color-darkred', '--color-darkblue', '--color-blue', '--color-green', '--color-yellow'][Math.floor(rng_subject() * 5.99)]
				}
		
				//{ d.url_control ? <button className="btn btn-outline-primary" onClick={() => this.props.history.push(d.url_control)}>Control view</button>  : <span/> }
				var dJsx = ""
				if (adminUser) {
					dJsx = <div className={"appDeployment" + statusClass} key={subject + dIdx + d.url}>
						<div className="appDeploymentBall" style={{
								background: 'linear-gradient('+String(randomDeg)+'deg, var('+String(randomColor1)+') 0%,var('+String(randomColor2)+') 100%)',
							}}/>
						{ deploymentName }
						<div className="row">
							<div className="dApp col-sm-auto col-xs-12">{completedIcon}{d.name}</div>
							<div className="dSubject col col-xs-12">{ d.liveMode ? <span>MULTIPLAYER</span> : <span>SINGLE PLAYER</span>}</div>
						</div>
						{ d.shortDescription ? 
							<div className="row">
								<div className="col dShortDescription">{d.shortDescription}</div>
							</div> : <span/> }
						{ d.topicsCovered ? 
							<ul className="topicsCovered">{d.topicsCovered.map(topic => <li>{topic}</li>)}</ul> : <span/> }
						{ d.public && d.publicUrl ? 
							<div className="studentAccessUrl">
								<span className="_lhs">Student access URL</span>
								<span className="_rhs"><a href={'/' + d.publicUrl} target="_catembe_student_new">
									{'catem.be/' + d.publicUrl} <Icon icon={externalLink} size={12}/></a></span>
							</div> : <span/> }
						<div className="row">
							<div className="dLinkButtons">
								<Dropdown>
									<Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
										Launch single view
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item className="" onSelect={() => this.props.history.push(d.url)}>
											<div className="_label">Student view</div>
											<div className="_subtitle">What students see</div>
										</Dropdown.Item> 
										<Dropdown.Item className="" onSelect={() => window.open(d.url_control, '_controlView')}>
											<div className="_label">Control view</div>
											<div className="_subtitle">Dedicated screen to manage session</div>
										</Dropdown.Item> 
										<div class="dropdown-divider"></div>
										<Dropdown.Item className="" onSelect={() => window.open(d.url_display, '_displayView')} disabled={!d.liveMode}>
											<div className="_label">Projector view</div>
											<div className="_subtitle">Screen to display to all students</div>
											{ !d.liveMode ? <div className="_subtitle">Only available in live/multiplayer mode</div> : <span/> }
										</Dropdown.Item> 
									</Dropdown.Menu>
								</Dropdown>
								<button className="btn buttonGradient_blueGreen" onClick={() => this.props.history.push(d.url_demo)}> Launch </button> 
							</div>
						</div>
						{ upcomingInfo }
					</div>
				}
				else {

					dJsx = <div className="appDeployment" onClick={() => this.props.history.push(d.url)} key={subject + dIdx + d.url}>
						<div className="appDeploymentBall" style={{
								background: 'linear-gradient('+String(randomDeg)+'deg, var('+String(randomColor1)+') 0%,var('+String(randomColor2)+') 100%)',
							}}/>
						<div className="dApp">{completedIcon}{d.name}</div>
						<div className="dSubject">{d.subject}</div>
					</div>
				}
				deployments.push(dJsx)
			}
		}

      
      if (deployments.length === 0) {
         deployments = <div className="appDeployment text-center">
                          <div className="lead mb-2">No apps available.</div>
                          <div>Please contact your subject co-ordinator to ensure your email has been enrolled in a subject.</div>
                       </div>
      }

      return (
			<div>
				<div className="container-fluid">
					<CatembeNavBar>
						{ this.state.points !== undefined ? 
							<div className="userPointsContainer"><span className="_lbl">Points</span> <span className="_pts">{this.state.points}</span></div> 
							: <span/> }
						<button className="btn logoutButton" onClick={this.handleLogout}>Logout</button>
					</CatembeNavBar>
               <div className="container">
                  <div className="row">
                     <div className="userNameContainer">
                        <div className="userNameLhs">
                           <img src={this.props.user.picture} alt="user picture"/>
                        </div>
                        <div className="userNameRhs">
                           <div className="_name">{this.props.user.displayName}</div>
                           <div className="_email">{this.props.user.email}</div>
                        </div>

                     </div>
                  </div>
                  <div className="row">
                     <div className="smallCapsHeading text-center" style={{width: '100%'}}>available apps</div>
                  </div>
                  <div className="row mb-5 justify-content-center">
                     <div className="col-lg-8">
                        { this.state.isLoading ? loading : deployments }
                     </div>
                  </div>

               </div>
            </div>
         </div>)
	}
}
export default withAuth(User)
