
const challenge = {
	initialState: {
		currentQn: 0,
		data: {},
		challenge: {},
		challengeCurrentQn: null,
		challengeActive: false,
		challengeResults: {},
	},
}

export { challenge }
