import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import AuthService from './utils/auth'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { Icon } from 'react-icons-kit'
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward'
import { ic_arrow_upward } from 'react-icons-kit/md/ic_arrow_upward'
import { ic_arrow_downward } from 'react-icons-kit/md/ic_arrow_downward'

import { Link, Element, animateScroll as scroll, scroller } from "react-scroll"

import ReactGA from "react-ga"
import { CatembeInteractiveApp, ContactForm, ImageSection, FooterRow } from './components/frontpage.js'
import { BreakevenNavBar } from './components/basic.js'
import { basicFunctions } from './include/basic.js'

import './css/FrontPage.css'
import { breakeven } from './apps/breakeven'
const app = breakeven

class Breakeven_SD extends Component {
	//constructor(props) { super(props) }
	constructor(props) {
		super(props)

		this.state = { 
			showContactForm: false,
			messageSent: false,
		}
	}

	apiSubmit = (data) => {
		//ga('send', 'event', 'conversion', 'sendContactForm');
		ReactGA.event({ category: 'conversion', action: 'productContactForm' })
		const callback = (data) => {
			if (data.success === true) {
				this.setState({messageSent: true})
			}
		}
		basicFunctions.apiCall(data, callback)
	}

	componentDidMount() {
		setTimeout( () => {
			ReactGA.event({
				category: 'conversion',
				action: '1minOnProductPage'
			})
		}, 60*1000)

		if (this.props.scrollToLiveMode) {
			scroller.scrollTo('liveMode')
		}
		else {
			window.scrollTo(0, 0);
		}
	}

	getStarted = () => {
		//ga('send', 'event', 'conversion', 'showContactForm');
		ReactGA.event({ category: 'conversion', action: 'getStarted' })
		//this.setState({ showContactForm: true })
		window.location = 'https://www.breakeven.app/pricing'
	}


	render() {
		var features
		if (app && app.gameDetails && app.gameDetails.features) {
			features = app.gameDetails.features.map( feature => (
				<div className="col-md-6 col-sm-12 _feature">
					<img src={feature.imageUrl}/>
					<h3>{feature.heading} {feature.comingSoon ? <div className="_comingSoon">coming soon</div> : <span/> } </h3>
					<p>{feature.description}</p>
				</div>)
			)
		}

		return (
			<div style={{backgroundColor: '#f9f9f9'}}>
				<Helmet>
					<title>Supply and Demand Game | Breakeven</title>
				</Helmet>
				<BreakevenNavBar />
				<div className="container bodySection fatBottom px-5">
					<div className="container-fluid" >
						<div className="row productInfoContainer">
							<div className="col-lg-6 col-sm-12 _lhs">
								<div className="mb-3">
									<div className="smallCapsHeading display-block">Topic</div>
								<h1>Supply and Demand Game</h1>
								</div>
								<div className="mb-3">
									<ul className="topicsCovered"><li>Supply and demand</li><li>Shifts in supply and demand</li><li>Equilibrium price</li></ul>
								</div>
								<div className="mb-3">
									<p>In this game, students experience the forces of supply and demand directly by trading emoji on <a href="https://www.breakeven.app">Breakeven</a>.</p>
									<p>Similar to the double-auction supply and demand experiments conducted by Chamberlin (1948) and Smith (1962), students are assigned roles of buyers or sellers, and given price limits. They trade by posting public or private offers, or accepting the offers of others.</p>
									<p>After trading has finished, use interactive charts to explore the trading data and confirm whether equilibrium was achieved, as predicted by supply and demand theory!</p>
								</div>
							</div>
							<div className="col-lg-6 col-sm-12 _rhs">
								<video src="https://d3tx1wevfc318r.cloudfront.net/Breakeven/Breakeven_Activity_Video_sd.mp4" className="img-fluid" autoPlay={true} muted={true} loop={true}/>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid blueGradient">

					<div className="row">
						<div className="col-12">
							<div className="container bodySection fatBottom appFeatures">
								<div className="row text-center">
									<h1 className="mainTagline mb-4">Breakeven is a real-time trading platform designed for economics students</h1>
									{features}
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
						
							<div className="container bodySection fatBottom text-center">
								<div className="row text-center _sectionHeader"><h1 className="mainTagline mb-4">Other games</h1><h2 className="lead"><strong>Supply and Demand</strong> is just one of many games that can be run on Breakeven</h2></div>

								<div className="_activities">
									<a href="https://www.breakeven.app/games/sd/">
										<div className="productInfoMini" style={{"background-color": "var(--color-lightgreen)"}}>
											<div className="_emoji">💎</div>
											<div className="_inner">
											<h2 className="_heading">Supply &amp; Demand</h2>
												<div className="_description">A digital version of the classic double-auction classroom experiment. Split the class into buyers and sellers, and see whether the market reaches equilibrium!</div>
											</div>
										</div>
									</a>
									<a href="https://www.breakeven.app/games/ca/">
										<div className="productInfoMini" style={{"background-color": "var(--color-yellow)"}}>
										<div className="_emoji">🍔</div>
										<div className="_inner">
										<h2 className="_heading">Comparative advantage</h2>
										<div className="_description">Manufacture happy meals to demonstrate the concepts of comparative advantage and the benefits of specialisation and trade.</div></div></div></a>
									<a href="https://www.breakeven.app/games/scarcity/">
										<div className="productInfoMini" style={{"background-color": "var(--color-darkgreen)"}}>
										<div className="_emoji">🔑</div>
										<div className="_inner">
										<h2 className="_heading">Scarcity</h2>
										<div className="_description">A fast-paced trading game demonstrating the concept of scarcity and the benefits of trade.</div></div></div>
									</a>
									<a href="https://www.breakeven.app/games/commons/">
										<div className="productInfoMini" style={{"background-color": "var(--color-red)"}}>
										<div className="_emoji">🐠</div>
										<div className="_inner">
										<h2 className="_heading">Tragedy of the commons</h2>
										<div className="_description">Bring to life the concept of common-pool resources and the tragedy of the commons by catching and selling fish from a shared ocean.</div></div></div>
									</a>
									<a href="https://www.breakeven.app/games/barter/">
										<div className="productInfoMini" style={{"background-color": "var(--color-darkblue)"}}>
										<div className="_emoji">🍋</div>
										<div className="_inner">
										<h2 className="_heading">Barter &amp; Money</h2>
										<div className="_description">Use barter markets to illustrate the the value of money as a unit of exchange.</div></div></div>
									</a>
									<a href="https://www.breakeven.app/games/mfg_ext/">
										<div className="productInfoMini" style={{"background-color": "var(--color-blue)"}}>
										<div className="_emoji">🔋</div>
										<div className="_inner">
										<h2 className="_heading">Externalities</h2>
										<div className="_description">Introduce how negative externalities can make everyone worse off. Find out what happens when manufacturers are forced to pay for their own pollution.</div></div></div>
									</a>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className="container-fluid footerSection" style={{backgroundColor: '#f6f6f6'}}>
					<div className="row justify-content-center" style={{margin: '100px 0 100px 0'}}>
						<div className="col-sm-12 col-md-6 text-center">
							<div className="smallCapsHeading text-center">Educators</div>
							<h2 className="footerText display-5 mb-1">Bring Economics concepts to life!</h2>
							<p className="mb-5">Set up your first classroom game session in minutes.</p>
							<button className="btn actionButton buttonGradient_redYellow" onClick={this.getStarted}>Get started</button>
						</div>
					</div>
					<FooterRow/>
				</div>
			</div>
		)
	}
}

export { Breakeven_SD }
